import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import InfoContainer from 'web/components/public/info-container';
import { Button, ButtonType } from 'web/components/primitives';

import { postAcceptTerms } from 'shared/api/session';

export default function Terms({ match }) {
  return (
    <InfoContainer title="Terms of Service">
      <p>Please read the following and press accept to continue.</p>
      <p>
        <strong>Effective: November 26, 2014</strong>
      </p>
      <p>
        The LifeLoop Solution (as defined herein) is owned by LifeLoop, LLC, a
        Nebraska limited liability company (“LifeLoop,” “we,” “us,” or “our”).
        These Terms of Service (“Terms of Service” or “Agreement”) are an
        agreement between LifeLoop and any person or entity (“you” and “your”)
        who accesses, registers an account for, views, or otherwise uses the
        Site and any related Content (as defined herein). These Terms of Service
        are intended to make you aware of the terms and conditions of your use
        of the LifeLoop Solution, any Content, or any other products or services
        that are offered or provided by LifeLoop (collectively, the “Solution”).
      </p>
      <p>
        The disclaimers, terms, and conditions contained in these Terms of
        Service are of general application and may be supplemented by additional
        policies, procedures, disclaimers, guidelines, rules, terms, or
        conditions of specific application disclosed by LifeLoop, including
        through a registration process or other means. In the event of a
        conflict between these Terms of Service and any additional policies,
        procedures, disclaimers, guidelines, rules, terms or conditions of
        specific application, the additional policies, procedures, disclaimers,
        guidelines, rules, terms or conditions of specific application shall
        control.
      </p>
      <p>
        LifeLoop reserves the right, at its discretion, to change, modify, add
        or remove portions of these Terms of Service at any time. By CLICKING
        THE “I AGREE,” “REGISTER,” OR BY using OR OTHERWISE ACCESSING the
        Solution, posting or downloading Content or any other information to or
        from the Solution, you hereby agree to, and shall be subject to, these
        Terms of Service. If you do not UNEQUIVOCALLY agree, you may not use OR
        OTHERWISE ACCESS the Solution, post or download Content, or any other
        information to or from the Solution.
      </p>
      <p>
        <strong>General Terms of Service and Restrictions on Use</strong>
      </p>
      <p>
        LifeLoop hereby grants you, and you hereby accept, a limited,
        nonexclusive, nonassignable, nontransferable license to access and use
        the Solution solely for your own personal use. All rights not otherwise
        expressly granted by these Terms of Service are reserved by LifeLoop.
        You agree not to reproduce, duplicate, copy, distribute, transmit, sell,
        trade, resell or exploit for any purpose any portion of or any
        information from the Solution. You may not obscure or remove any
        proprietary rights notices contained in or on the Content. LifeLoop may
        discontinue or alter any aspect of the Solution, remove Content from the
        Solution, restrict the time the Solution is available, or restrict the
        amount of use permitted at LifeLoop’s sole discretion and without prior
        notice or liability. You further agree that such measures shall be taken
        in LifeLoop’s sole discretion and without liability to you or any third
        party.
      </p>
      <p>
        Unless otherwise specifically noted in these Terms of Service or on the
        Solution, images, trademarks, service marks, logos and icons displayed
        on the Solution are the property of LifeLoop and/or its licensors and
        may not be used without LifeLoop’s prior written consent. Any
        unauthorized use of any Content, whether owned by LifeLoop or other
        parties, may violate copyright laws, trademark laws, privacy and
        publicity laws and communications regulations and statutes. You will not
        copy, reverse engineer, disassemble, decompile, translate, modify,
        reproduce, republish, transmit, sell, offer for sale, disseminate or
        redistribute the intellectual property found in the Solution or any part
        thereof or grant any other person or entity the right or access to do
        so.
      </p>
      <p>
        <strong>Account Security</strong>
      </p>
      <p>
        You shall be responsible for the security, confidentiality, and
        integrity of all information that you receive or transmit through the
        Solution. You shall be responsible for any authorized or unauthorized
        access or use of your account by any person. You have the affirmative
        responsibility to monitor and control access to your account and account
        information. If at any time you learn or suspect that your account
        information has been disclosed or otherwise made known to any person,
        you shall immediately notify LifeLoop. You shall bear all responsibility
        for the confidentiality of your password and all use or charges incurred
        from use of the Solution through your account.
      </p>
      <p>
        <strong>Content</strong>
      </p>
      <p>
        You acknowledge that the Solution may contain, provide access to, or
        otherwise allow you to access, view, download, upload, publish, send,
        receive, and transmit information, software, photos, video, text,
        real-time messages, graphics, or other material provided by LifeLoop,
        LifeLoop’s authorized agents, other users of the Solution, and/or other
        third parties (collectively, “Content”) that are protected by
        copyrights, patents, trademarks, trade secrets, or other proprietary
        rights, and that these rights are valid and protected in all forms,
        media and technologies existing now or hereafter developed. Content
        posted by users via the Solution (“User Content”) is the intellectual
        property of the specific users of the Solution who post such User
        Content. By posting or delivering User Content, you hereby grant to
        LifeLoop, and LifeLoop hereby accepts, a limited, transferable,
        nonexclusive, worldwide, perpetual, royalty-free license to use,
        reproduce, modify, edit, adapt, publish, translate, display, distribute,
        sell, sublicense, and create derivative works and compilations
        incorporating such User Content.
      </p>
      <p>
        LifeLoop does not generally monitor or otherwise remove User Content
        after it is posted on the Solution except as required or permitted by
        law or otherwise in the sole discretion of LifeLoop, and reserves the
        right to remove any and all material that it feels is actually or
        potentially inappropriate, offensive, illegal, or harmful in any respect
        or which may violate these Terms of Service. LifeLoop does not make any
        warranties or representations regarding any of the User Content.
        LifeLoop does not necessarily approve, endorse, sanction, encourage,
        verify, or agree with any message posted by its users or otherwise
        embodied in the Content. LifeLoop is not and will not be liable for any
        Content or User Content that may be offensive, indecent, vulgar,
        defamatory, or otherwise objectionable.
      </p>
      <p>
        <strong>Links</strong>
      </p>
      <p>
        As a service to our users, we may provide information about other
        resources that may be of interest. However, we are not responsible or
        liable for any content, advertising, products, or other materials on or
        available from such sites or resources, and the presentation of third
        party links or content by LifeLoop is not intended to be an endorsement,
        sponsorship or recommendation by LifeLoop. Please be aware that when you
        exit the Solution, you are subject to the policies of the new third
        party web site. You further acknowledge and agree that LifeLoop shall
        not be responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with use of or
        reliance on any such third party content, goods, or services available
        on or through any third party site or resource.
      </p>
      <p>
        <strong>LifeLoop’s Privacy Policy</strong>
      </p>
      <p>
        LifeLoop collects, stores and uses data collected from you in accordance
        with <Link to="/privacy">LifeLoop’s Privacy Policy</Link>. The terms and
        conditions of the Privacy Policy, as modified from time to time, are
        hereby expressly incorporated into these Terms of Service.
      </p>
      <p>
        <strong>User Representations</strong>
      </p>
      <p>
        You hereby represent and warrant to LifeLoop that you (i) are over the
        age of majority in your jurisdiction or otherwise have the power and
        authority to enter into and perform your obligations under this
        Agreement; (ii) all information provided by you to LifeLoop is truthful,
        accurate and complete; and (iii) you will comply with the terms and
        conditions of these Terms of Service and any other agreement to which
        you are subject that is related to your use of the Solution or any part
        thereof. For any User Content that you post via the Solution, you hereby
        represent and warrant that: (a) you are owner of such User Content or
        otherwise have the right to grant LifeLoop the licenses granted pursuant
        to this Agreement; (b) you have secured any and all consents necessary
        to post the User Content and to grant the foregoing licenses; (c) the
        User Content does not violate the rights of any third party, including,
        without limitation, the intellectual property, privacy, or publicity
        rights of any third party, and such User Content does not contain any
        personally identifiable information about third parties in violation of
        such parties’ rights; and (d) the use of any User Content will not
        result in harm or personal injury to any third party.
      </p>
      <p>
        <strong>Prohibited Uses</strong>
      </p>
      <p>
        You agree not to: (a) use of the Solution to post or transmit any
        Content or other material that is, or to a reasonable person may be,
        unlawful, harmful, tortious, abusive, obscene, pornographic, defamatory,
        libelous, harassing, invasive of another’s privacy, offensive, vulgar,
        threatening, malicious, hateful, racially, ethnically, or otherwise
        morally objectionable; (b) use of the Solution to post content, files,
        graphics, software, or other material or information that actually or
        potentially infringes the rights of any person, including, without
        limitation, the copyright, trademark, patent, trade secret or other
        intellectual property rights, or privacy, moral, or publicity rights, of
        any person; (c) use of the Solution to interfere, disrupt or attempt to
        gain unauthorized access to other accounts on the Solution or any other
        computer network; (d) use of the Solution to post viruses, trojan
        horses, or any other malicious code or programs designed to interrupt,
        destroy or limit the functionality of any computer software, hardware,
        system or telecommunications equipment; (e) use of the Solution to post
        any unsolicited or unauthorized advertising, promotional materials, junk
        mail, spam, chain letters, pyramid schemes or other fraudulent schemes,
        or any other form of solicitation; or (f) use of the Solution to engage
        in any activity that, as determined by LifeLoop, may intentionally or
        unintentionally violate these Terms of Service or violate any applicable
        laws or regulations.
      </p>
      <p>
        <strong>
          PLEASE NOTE THE FOLLOWING IMPORTANT DISCLAIMERS OF WARRANTIES:
        </strong>
      </p>
      <p>
        <strong>EXCLUSION OF WARRANTIES / DISCLAIMER</strong>
      </p>
      <p>
        TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE SOLUTION, AND ANY CONTENT ARE
        PROVIDED “AS IS” AND “AS AVAILABLE,” AND AT YOUR SOLE RISK. LifeLoop
        PROVIDES NO WARRANTY OF ANY KIND REGARDING THE SOLUTION AND/OR any
        CONTENT POSTED or otherwise made available therein. FURTHER, LifeLoop
        DOES NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENCY, OR RELIABILITY OF
        ANY CONTENT OBTAINED FROM THE USE OF THE SOLUTION OR ANY CONTENT WILL BE
        ACCURATE OR RELIABLE, OR THAT THE QUALITY OF THE SOLUTION OR any CONTENT
        WILL MEET YOUR EXPECTATIONS. LifeLoop EXPRESSLY DISCLAIMS ALL
        WARRANTIES, REPRESENTATIONS, CONDITIONS, UNDERTAKINGS OR OTHER
        OBLIGATIONS INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER WARRANTY THAT
        THE SOLUTION OR ANY CONTENT WILL BE ERROR-FREE OR THAT SUCH ERRORS WILL
        BE CORRECTED.
      </p>
      <p>
        ANY CONTENT OR OTHER MATERIALS DOWNLOADED OR OTHERWISE OBTAINED THROUGH
        THE USE OF THE SOLUTION IS DONE AT YOUR SOLE RISK AND EXPENSE. YOU
        ACKNOWLEDGE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
        COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY
        SUCH CONTENT OR MATERIALS.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT LifeLoop SHALL NOT BE LIABLE FOR
        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
        DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
        GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF LifeLoop HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i)
        THE USE OR THE INABILITY TO USE THE SOLUTION; (ii) THE COST OF
        PROCUREMENT OF the applicable SUBSTITUTE PRODUCTS or SERVICEs RESULTING
        FROM the inability to access or utilize any DATA, INFORMATION, MESSAGES
        RECEIVED, OR TRANSACTIONS THROUGH OR FROM THE SOLUTION; (iii)
        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; OR
        (iv) ANY OTHER MATTER RELATING TO THE SOLUTION. IN NO EVENT SHALL
        LifeLoop’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
        ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO,
        NEGLIGENCE), OR OTHERWISE EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR
        ACCESSING THE SOLUTION.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
        THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
        DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
        YOU.
      </p>
      <p>
        <strong>Indemnification</strong>
      </p>
      <p>
        You agree to defend, indemnify and hold harmless LifeLoop, its officers,
        members, directors, employees, and agents from and against any and all
        claims, liabilities, damages, losses or expenses, including attorneys’
        fees and costs and expenses, arising out of or in any way connected
        with: (i) your access to or use of the Solution or any part thereof;
        (ii) any User Content you post via the Solution; (iii) a breach or
        alleged breach by you of any of your representations, warranties,
        covenants, or obligations under this Agreement; (iv) infringement or
        misappropriation of any intellectual property or other rights of
        LifeLoop or third parties by you; or (v) any negligence or willful
        misconduct by you.
      </p>
      <p>
        <strong>Governing Law and Other Miscellaneous Terms</strong>
      </p>
      <p>
        This Agreement shall be governed by and construed, interpreted, and
        enforced in accordance with the laws of the State of Nebraska, without
        reference to its conflicts or choice of law principles. The parties
        agree that the sole and exclusive jurisdiction and venue for any and all
        disputes arising under this Agreement shall be in any trial court
        located in or having jurisdiction over Lancaster County, Nebraska. Each
        of the parties hereby irrevocably submits and consents to the personal
        jurisdiction of the above-named courts. The parties hereby irrevocably
        waive any and all objections which any party may now or hereafter have
        to the exercise of personal and subject matter jurisdiction by such
        federal or state courts and to the laying of venue of any such suit,
        action, or proceeding brought in any such federal or state court.
      </p>
      <p>
        The parties agree that breach of the provisions of this Agreement would
        cause irreparable harm and significant injury to LifeLoop which would be
        both difficult to ascertain and which would not be compensable by
        damages alone. As such, the parties agree that LifeLoop has the right to
        enforce the provisions of this Agreement by injunction (without
        necessity of posting bond), specific performance, or other equitable
        relief without prejudice to any other rights and remedies LifeLoop may
        have. The prevailing party will be entitled to reasonable fees of
        attorneys, accountants and other professionals, and costs and expenses
        in addition to any other relief to which such prevailing party may be
        entitled in any action at law or in equity.
      </p>
      <p>
        <strong>Copyright and Copyright Notices</strong>
      </p>
      <p>
        LifeLoop respects the intellectual property of others, and we ask our
        users to do the same. If you believe that your work has been copied in a
        way that constitutes copyright infringement, please provide LifeLoop’s
        Copyright Agent the following information:
      </p>
      <ul>
        <li>
          an electronic or physical signature of the owner or person authorized
          to act on behalf of the owner of the copyright interest;
        </li>
        <li>
          a description of the copyrighted work that you claim has been
          infringed;
        </li>
        <li>
          a description of where the material that you claim is infringing is
          located on the Solution sufficient to allow us to locate the allegedly
          infringing material;
        </li>
        <li>your address, telephone number, and email address;</li>
        <li>
          a statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
          and
        </li>
        <li>
          a statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner’s behalf.
        </li>
      </ul>
      <p>
        Please contact LifeLoop’s Copyright Agent for Notice of Claims of
        copyright infringement at:{' '}
        <a href="mailto:support@lifeloop.com">support@lifeloop.com</a>.
        Copyright owners and agents acknowledge that failure to comply with all
        of the requirements of the foregoing may result in an invalidity of the
        DMCA notice.
      </p>
      <p>
        <strong>Changes to our Policies</strong>
      </p>
      <p>
        Since offerings and technologies change, we reserve the right to change,
        modify, add, or remove portions of these Terms of Service, in our sole
        discretion, at any time without prior notice to you. You understand that
        you have the affirmative obligation to check these Terms of Service
        periodically for changes, and you hereby agree to periodically review
        these Terms of Service for such changes. Your continued use of this
        Solution following the posting of changes to these Terms of Service
        constitutes your acceptance of those changes. If you have any questions
        or would like further clarification, please e-mail us at{' '}
        <a href="mailto:support@lifeloop.com">support@lifeloop.com</a>. Any
        changes in LifeLoop’s policies will be communicated on this page.
      </p>
      <p>All inquiries may be directed to:</p>
      LifeLoop, LLC
      <br />
      225 N 115th St.
      <br />
      Omaha, Nebraska 68154
      <br />
      <a href="mailto:support@lifeloop.com">support@lifeloop.com</a>
      <p>
        <Button
          id="accept-terms"
          type={ButtonType.required}
          onClick={
            () =>
              postAcceptTerms(window.clientConfig.baseUrl)
                .then(() => {
                  window.location.href = `/${match.params.tenant}`;
                })
                .catch(console.error) // eslint-disable-line
          }
        >
          Accept Terms
        </Button>
      </p>
    </InfoContainer>
  );
}

Terms.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ tenant: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};
