import React from 'react';
import PropTypes from 'prop-types';

import { FlexContainer } from 'web/components/primitives';
import { ArrowLeft } from 'assets/icons';

import styles from './styles.scss';

export default function BackTo({ onBack, backTo }) {
  return (
    <FlexContainer alignItems="center" className={styles.backTo}>
      <FlexContainer
        className={styles.backToInner}
        alignItems="center"
        onClick={onBack}
        data-qa-back-to
      >
        <ArrowLeft className={styles.backToArrow} size={20} />
        <span className={styles.backToText}>
          <b>back to</b>
          {backTo}
        </span>
      </FlexContainer>
    </FlexContainer>
  );
}

BackTo.propTypes = {
  onBack: PropTypes.func.isRequired,
  backTo: PropTypes.string.isRequired,
};
