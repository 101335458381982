import React from 'react';
import {
  Text,
  FlexContainer,
  HeaderBar,
  Button,
} from 'web/components/primitives';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import styles from './styles.scss';

export default function Unsubscribe() {
  const history = useHistory();
  const noHandler = () => {
    history.push(
      `/${window.clientConfig.tenant}/unsubscribe-no/${window.clientConfig.email}?type=${window.clientConfig.typeCode}`,
    );
  };
  const yesURL = `/${window.clientConfig.tenant}/unsubscribe-yes/${window.clientConfig.email}?type=${window.clientConfig.typeCode}`;
  const yesHandler = () => {
    axios.post(yesURL).then(() => {
      history.push(yesURL);
    });
  };
  let title;
  if (window.clientConfig.error) {
    title = `We encountered an error accessing settings for ${window.clientConfig.email}.`;
  } else {
    title = `Are you sure you want to unsubscribe ${window.clientConfig.email}?`;
  }
  return (
    <FlexContainer keepHeight>
      <HeaderBar title="Unsubscribe Confirmation" />
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        growToContainer
        className={styles.body}
      >
        <FlexContainer flexDirection="column" className={styles.body}>
          <Text bold size="1.6rem" className={styles.title}>
            {title}
          </Text>

          <Text mt={12}>
            Before you leave, please let us know if you really want to
            unsubscribe from {window.clientConfig.type} notifications.
            <br /> If you&apos;re overwhelmed by emails, you can adjust your{' '}
            <a
              className={styles.link}
              href="https://lifeloopapp.com/lifeloop/profile"
            >
              notification preferences in My Profile
            </a>
            <br />
            instead of unsubscribing completely.
          </Text>
          <FlexContainer flexDirection="row" className={styles.buttonBox}>
            <Button onClick={noHandler}>No, keep me subscribed</Button>
            <Button onClick={yesHandler} className={styles.yesButton}>
              Yes, unsubscribe me
            </Button>
          </FlexContainer>
          <Text mt={40}>
            Need help?{' '}
            <a
              className={styles.link}
              href="https://training.lifeloop.com/support"
            >
              Contact our support team.
            </a>
          </Text>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}
