import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useResize } from 'shared/utils/effects';

import Table from '../table';
import styles from './styles.scss';

const CONTROLLER = {
  STATIC: 'STATIC',
  TABLE: 'TABLE',
};

let controllingScroll;

export default function StickyTable({ className, fixedLeftColumns, ...props }) {
  const staticTableRef = useRef();
  const scrollTableRef = useRef();
  const wrapperRef = useRef();

  const calculateStaticColumns = () => {
    if (!staticTableRef.current || !scrollTableRef.current) {
      return;
    }

    staticTableRef.current.style.width = scrollTableRef.current.offsetWidth;

    let columnsWidth = 0;
    const headerCells = scrollTableRef.current.querySelectorAll(
      `thead th:nth-child(-n+${fixedLeftColumns})`,
    );

    for (let i = 0; i < headerCells.length; i += 1) {
      columnsWidth += headerCells[i].offsetWidth;
    }

    wrapperRef.current.style.width = columnsWidth + fixedLeftColumns; // For 1px borders
  };

  // Always trigger effect on re-render
  useEffect(calculateStaticColumns, [calculateStaticColumns]);
  useResize(calculateStaticColumns, [calculateStaticColumns]);

  return (
    <div className={styles.relativeContainer}>
      <div ref={wrapperRef} className={styles.staticWrapper}>
        <Table
          ref={staticTableRef}
          className={classNames(className, styles.staticTable)}
          onClick={e => e.stopPropagation()}
          onScroll={e => {
            e.target.scrollLeft = 0;
            if (controllingScroll !== CONTROLLER.TABLE) {
              controllingScroll = CONTROLLER.STATIC;
              scrollTableRef.current.scrollTop = e.target.scrollTop;
            } else {
              controllingScroll = undefined;
            }
          }}
          {...props}
        />
      </div>
      <Table
        ref={scrollTableRef}
        className={classNames(className, styles.scrollTable)}
        onScroll={e => {
          if (controllingScroll !== CONTROLLER.STATIC) {
            controllingScroll = CONTROLLER.TABLE;
            staticTableRef.current.scrollTop = e.target.scrollTop;
          } else {
            controllingScroll = undefined;
          }
        }}
        {...props}
      />
    </div>
  );
}

StickyTable.propTypes = {
  className: PropTypes.string,
  fixedLeftColumns: PropTypes.number.isRequired,
};

StickyTable.defaultProps = {
  className: undefined,
};
