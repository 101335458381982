import React from 'react';
import { Text, FlexContainer, HeaderBar } from 'web/components/primitives';

import styles from './styles.scss';

export default function UnsubscribeYes() {
  let title;
  if (window.clientConfig.error) {
    title = `We encountered an error while trying to unsubscribe ${window.clientConfig.email}.`;
  } else {
    title = `${window.clientConfig.email} has been unsubscribed.`;
  }
  return (
    <FlexContainer keepHeight>
      <HeaderBar title="Unsubscribed" />
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        growToContainer
        className={styles.body}
      >
        <FlexContainer flexDirection="column">
          <Text bold size="1.6rem" className={styles.title}>
            {title}
          </Text>
          <Text mt={60}>
            Need help?{' '}
            <a
              className={styles.link}
              href="https://training.lifeloop.com/support"
            >
              Contact our support team.
            </a>
          </Text>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}
