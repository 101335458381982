import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './style.scss';

const AS_CONFIG = {
  h1: {
    fontSize: '30px',
  },
  h2: {
    fontSize: '24px',
  },
  h3: {
    fontSize: '20px',
  },
  h4: {
    fontSize: '18px',
  },
  h5: {
    fontSize: '16px',
  },
};

function Text({
  forwardedRef,
  className,
  children,
  bold,
  thin,
  meta,
  livid,
  lightGray,
  secondary,
  isError,
  size,
  hoverable,
  inline,
  align,
  ml,
  mr,
  mb,
  mt,
  m,
  onClick,
  style,
  semibold,
  nowrap,
  as,
  ...props
}) {
  const AsTagType = as;
  let inlineStyle = style || {};
  if (size) {
    inlineStyle = { ...inlineStyle, fontSize: size };
  }
  if (m) {
    inlineStyle = {
      ...inlineStyle,
      marginLeft: m,
      marginRight: m,
      marginBottom: m,
      marginTop: m,
    };
  }
  if (ml) {
    inlineStyle = { ...inlineStyle, marginLeft: ml };
  }
  if (mb) {
    inlineStyle = { ...inlineStyle, marginBottom: mb };
  }
  if (mr) {
    inlineStyle = { ...inlineStyle, marginRight: mr };
  }
  if (mt) {
    inlineStyle = { ...inlineStyle, marginTop: mt };
  }
  if (align) {
    inlineStyle = { ...inlineStyle, textAlign: align };
  }
  inlineStyle = { ...inlineStyle, ...(AS_CONFIG[as] || {}) };
  return (
    <AsTagType
      ref={forwardedRef}
      onClick={onClick}
      style={inlineStyle}
      className={classNames(styles.standard, className, {
        [styles.inline]: inline,
        [styles.hoverable]: hoverable,
        [styles.meta]: meta,
        [styles.lightGray]: lightGray,
        [styles.bold]: bold,
        [styles.thin]: thin,
        [styles.secondary]: secondary,
        [styles.error]: isError,
        [styles.semibold]: semibold,
        [styles.nowrap]: nowrap,
        [styles.livid]: livid,
      })}
      {...props}
    >
      {children}
    </AsTagType>
  );
}

Text.propTypes = {
  forwardedRef: PropTypes.shape(),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bold: PropTypes.bool,
  thin: PropTypes.bool,
  meta: PropTypes.bool,
  lightGray: PropTypes.bool,
  secondary: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  inline: PropTypes.bool,
  align: PropTypes.string,
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  style: PropTypes.shape(),
  nowrap: PropTypes.bool,
  as: PropTypes.string,
};

Text.defaultProps = {
  forwardedRef: null,
  size: null,
  bold: false,
  meta: false,
  lightGray: false,
  thin: false,
  children: null,
  secondary: false,
  isError: false,
  className: null,
  hoverable: false,
  inline: false,
  align: null,
  m: null,
  ml: null,
  mr: null,
  mb: null,
  mt: null,
  onClick: () => null,
  style: {},
  nowrap: null,
  as: 'div',
};

export default React.forwardRef((props, ref) => (
  <Text {...props} forwardedRef={ref} />
));
