import React from 'react';
import PropTypes from 'prop-types';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export default function ListHeader({ title, context, actions }) {
  return (
    <FlexContainer
      justifyContent="spaceBetween"
      alignItems="center"
      className={styles.container}
    >
      <FlexContainer
        flexDirection="row"
        justifyContent="flexStart"
        alignItems="center"
        className={styles.contextItems}
      >
        {!!title && <div className={styles.title}>{title}</div>}
        {context}
      </FlexContainer>
      <FlexContainer
        flexDirection="row"
        justifyContent="flexEnd"
        alignItems="center"
        className={styles.actionsArray}
      >
        {actions}
      </FlexContainer>
    </FlexContainer>
  );
}

ListHeader.propTypes = {
  title: PropTypes.string,
  context: PropTypes.node,
  actions: PropTypes.node,
};

ListHeader.defaultProps = {
  title: undefined,
  context: undefined,
  actions: undefined,
};
