import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'web/components/primitives';
import styles from './styles.scss';

export default function AgendaName({ name }) {
  return (
    <Text
      bold
      size="1em"
      className={styles.eventName}
      data-qa-agenda-event-name
    >
      {name}
    </Text>
  );
}

AgendaName.propTypes = {
  name: PropTypes.string.isRequired,
};
