import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconType } from 'stories/icon';
import Button from 'stories/button';

import FlexContainer from '../flex-container';
import PopupDrawer from '../popup.drawer';
import styles from './styles.scss';

export default function FilterPopup({
  filterCount,
  clearFilters,
  className,
  children,
  drawerPopup,
  drawerClassName,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onClear = () => {
    clearFilters();
    setIsOpen(false);
  };

  return (
    <PopupDrawer
      drawerPopup={drawerPopup}
      className={className}
      drawerClassName={drawerClassName}
      isShowing={isOpen}
      hidePopup={() => setIsOpen(false)}
      button={
        <Button
          onClick={() => setIsOpen(!isOpen)}
          symbolIcon={IconType.filter}
          data-qa-filters
        >
          Filters ({filterCount})
        </Button>
      }
    >
      <FlexContainer
        className={styles.bodyContainer}
        flexDirection="column"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <FlexContainer flexDirection="column" className={styles.innerContainer}>
          {children}
        </FlexContainer>
        <FlexContainer className={styles.footer}>
          <FlexContainer
            className={classNames(styles.clearFilters, styles.btn)}
            onClick={onClear}
            data-qa-clear-filters
          >
            Clear Filters
          </FlexContainer>
          <FlexContainer
            className={classNames(styles.doneBtn, styles.btn)}
            onClick={() => setIsOpen(false)}
            data-qa-select-filters
          >
            Done
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </PopupDrawer>
  );
}

FilterPopup.propTypes = {
  filterCount: PropTypes.number.isRequired,
  clearFilters: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  drawerPopup: PropTypes.string,
  className: PropTypes.string,
  drawerClassName: PropTypes.string,
};

FilterPopup.defaultProps = {
  className: undefined,
  drawerPopup: undefined,
  drawerClassName: undefined,
};
