"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// This is the groundwork for future `useSizer` functionality.
// It is generic by default as the final use cases will almost certainly change this implementation - Levi
var SIZES = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3,
};
exports.default = SIZES;
