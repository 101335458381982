import { chunk, uniqBy } from 'lodash';
import fetch from '../utils/fetch.helper';

export const updateUser = (baseUrl, id, update) =>
  fetch('PUT', `${baseUrl}/rest/users/${id}`, {}, update);

export const getUsernameSuggestions = (baseUrl, firstName, lastName) => {
  if (!firstName || !lastName) {
    return Promise.resolve([]);
  }
  return fetch('GET', `${baseUrl}/rest/users/username-suggestions`, {
    firstName,
    lastName,
  });
};

export const fetchUsersByTypeAndFacilities = (
  baseUrl,
  facilities,
  includeSelf = false,
  select = '',
) => {
  const url = `${baseUrl}/rest/users/types`;
  const usersForFacility = batch =>
    fetch('GET', url, { facilities: batch, includeSelf, select });
  return Promise.all(chunk(facilities, 10).map(usersForFacility)).then(
    results =>
      results.reduce(
        (allUsersByType, usersByType) => ({
          resident: uniqBy(
            [...allUsersByType.resident, ...usersByType.resident],
            '_id',
          ),
          staff: uniqBy([...allUsersByType.staff, ...usersByType.staff], '_id'),
          contact: uniqBy(
            [...allUsersByType.contact, ...usersByType.contact],
            '_id',
          ),
          family: uniqBy(
            [...allUsersByType.family, ...usersByType.family],
            '_id',
          ),
        }),
        {
          resident: [],
          staff: [],
          family: [],
          contact: [],
        },
      ),
  );
};

export const sendFeedback = (baseUrl, form) =>
  fetch('PUT', `${baseUrl}/rest/feedback`, {}, form);

export const isValidResetPasswordToken = (baseDomain, tenant, token, userId) =>
  fetch('GET', `${baseDomain}/${tenant}/reset-password/${token}/validate`, {
    userId,
  });

export const reportMisconduct = (baseUrl, form) =>
  fetch('PUT', `${baseUrl}/rest/misconduct`, {}, form);

export const doPasswordReset = (baseDomain, tenant, token, password) =>
  fetch(
    'POST',
    `${baseDomain}/${tenant}/reset-password/${token}`,
    { token },
    { password },
  );

const sendResetForBatch = (baseUrl, userIds) =>
  fetch('POST', `${baseUrl}/rest/users/reset-password`, {}, { userIds });

export const resetUserPasswords = (baseUrl, userIds) =>
  Promise.all(
    chunk(userIds, 30).map(batch => sendResetForBatch(baseUrl, batch)),
  );

export const buildSendWelcomeMessages = ({
  baseUrl,
  apiFn = () => Promise.resolve(),
  onUpdate = () => {},
  onSuccess = () => {},
  onError = () => {},
  onFail = () => {},
}) => users => {
  const errors = [];
  const success = [];

  return users
    .filter(
      user =>
        !!user.email || !!user.phoneNumber?.length || !!user.textNumber?.length,
    )
    .reduce(
      (promise, user, index, arr) =>
        promise.then(() => {
          onUpdate({
            index: index + 1,
            total: arr.length,
          });
          return apiFn(baseUrl, user._id)
            .then(() => success.push(user))
            .catch(() => errors.push(user));
        }),
      Promise.resolve(),
    )
    .then(() => {
      if (!errors.length) {
        onSuccess(success);
      } else {
        onError(errors);
      }
    })
    .catch(onFail);
};
