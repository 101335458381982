import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlexContainer, Text } from 'web/components/primitives';
import { useTimeout, useDimensions } from 'shared/utils/effects';

import styles from './styles.scss';

export default function MarketingBanner({
  title,
  description,
  imageUrls,
  onClick,
}) {
  const [index, setIndex] = useState(0);
  const { width } = useDimensions();
  const image = imageUrls[index];
  useTimeout(
    6000,
    () => {
      const newIndex = (index + 1) % imageUrls.length;
      if (newIndex !== index) {
        setIndex(newIndex);
      }
    },
    [imageUrls, index, setIndex],
  );
  return (
    <FlexContainer
      className={styles.banner}
      alignItems="center"
      justifyContent="spaceAround"
      onClick={() => onClick(index)}
    >
      <FlexContainer
        className={styles.textColumn}
        flexDirection="column"
        justifyContent="center"
      >
        <Text bold className={styles.whiteText} size="1.8rem">
          {title}
        </Text>
        <hr className={styles.divider} />
        <Text className={styles.whiteText} size="1rem">
          {description}
        </Text>
      </FlexContainer>
      {width > 1200 && (
        <FlexContainer
          className={styles.imageContainer}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {imageUrls.map(url => (
            <div
              className={classNames(styles.imageWrapper, {
                [styles.imageShown]: image === url,
              })}
              key={url}
            >
              <img
                className={styles.image}
                src={url}
                alt={`banner #${index}`}
              />
            </div>
          ))}
        </FlexContainer>
      )}
    </FlexContainer>
  );
}

MarketingBanner.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};
MarketingBanner.defaultProps = {
  onClick: () => null,
};
