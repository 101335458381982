import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

export default function Tag({ color, text, onClick }) {
  return (
    <div
      className={styles.tag}
      style={{ backgroundColor: color }}
      onClick={() => (onClick ? onClick() : null)}
    >
      <span className={styles.arrow} />
      {text}
    </div>
  );
}
Tag.defaultProps = {
  color: 'rgba(233, 243, 248, 1)',
  onClick: null,
};
Tag.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};
