import React, { Fragment, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';
import PropTypes from 'prop-types';
import { take } from 'lodash';

import Input from 'stories/input';
import FlexContainer from '../flex-container';

import styles from './styles.scss';

const DEFAULT_SUGGESTIONS = [
  '#000000',
  '#2A2A2A',
  '#555555',
  '#7F7F7F',
  '#AAAAAA',
  '#D4D4D4',
  '#FFFFFF',
  '#9F0500',
  '#C45100',
  '#FE9200',
  '#808900',
  '#68BC00',
  '#A4DD00',
  '#DBDF00',
  '#0062B1',
  '#009CE0',
  '#16A5A5',
  '#68CCCA',
  '#7B64FF',
  '#AEA1FF',
  '#FA28FF',
];

const buildColor = (value, disableAlpha) =>
  disableAlpha
    ? tinycolor(value)
        .setAlpha(1)
        .toString()
    : value;

export default function ColorInput({
  value,
  onChange,
  useSuggestions,
  suggestions,
  allowEmpty,
  className,
  disableAlpha,
  ...props
}) {
  const container = useRef();
  const [width, setWidth] = useState();
  const [color, setColor] = useState(buildColor(value, disableAlpha));
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const currentColor = tinycolor(color);
  const validColor = currentColor.isValid()
    ? currentColor
    : tinycolor('transparent');

  useEffect(() => {
    const { clientWidth } = container.current || {};
    if (clientWidth && width !== clientWidth) {
      setWidth(Math.min(clientWidth, 300));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current]);

  useEffect(() => {
    const formattedValue = buildColor(value, disableAlpha);
    if (formattedValue !== color) {
      setColor(formattedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, disableAlpha]);

  const onNewColor = (newColor, localChange) => {
    const func = localChange ? setColor : onChange;
    if (allowEmpty && !newColor) {
      func('');
    } else {
      func(
        newColor.getAlpha() !== 1
          ? newColor.toRgbString()
          : newColor.toHexString(),
      );
    }
  };

  let pickerContent;
  if (isOpen) {
    const size = width - 45;
    const sliderSize = size * (1 / 10);
    const margin = width - sliderSize - size - 12;
    const { h, s, v } = validColor.toHsv();
    const backgroundColor = tinycolor({ h, s: 1, v: 1 }).toRgbString();

    const onPickingArea = (e, localChange) => {
      const { left, top } = e.target.getBoundingClientRect();
      const newS = Math.max(0, (e.clientX - left) / size);
      const newV = Math.min(1, 1 - (e.clientY - top) / size);
      const alpha = validColor.getAlpha() || 1;
      onNewColor(
        tinycolor({ h, s: newS, v: newV }).setAlpha(alpha),
        localChange,
      );
    };

    const onHue = (e, localChange) => {
      const { top } = e.target.getBoundingClientRect();
      const newH = ((e.clientY - top + 4) / size) * 360;
      const newS = s === 0 ? 1 : s;
      const newV = v === 0 ? 1 : v;
      const alpha = validColor.getAlpha() || 1;
      onNewColor(
        tinycolor({ h: newH, s: newS, v: newV }).setAlpha(alpha),
        localChange,
      );
    };

    const onAlpha = (e, localChange) => {
      const { left } = e.target.getBoundingClientRect();
      const alpha = (e.clientX - left + 4) / size;
      onNewColor(validColor.setAlpha(alpha), localChange);
    };

    const mouseEvents = changeFunc => ({
      onMouseDown: () => setIsDragging(true),
      onMouseMove: e => {
        if (isDragging) {
          changeFunc(e, true);
        }
      },
      onMouseLeave: e => {
        if (isDragging) {
          setIsDragging(false);
          changeFunc(e);
        }
      },
      onMouseUp: e => {
        if (isDragging) {
          setIsDragging(false);
        }
        changeFunc(e);
      },
    });

    pickerContent = (
      <Fragment>
        <div className={styles.pickerSection}>
          <FlexContainer justifyContent="spaceBetween">
            <div
              {...mouseEvents(onPickingArea)}
              className={styles.pickingArea}
              style={{
                width: size,
                height: size,
                backgroundColor,
              }}
            >
              <div
                className={styles.pickingAreaPoint}
                style={{
                  top: (1 - v) * size - 6,
                  left: s * size - 6,
                }}
              />
            </div>
            <div
              {...mouseEvents(onHue)}
              className={styles.hue}
              style={{ height: size, width: sliderSize }}
            >
              <div
                className={styles.huePoint}
                style={{
                  top: (h / 360) * size - 6,
                  width: sliderSize,
                }}
              />
            </div>
          </FlexContainer>
          {!disableAlpha && (
            <FlexContainer
              style={{ marginTop: margin }}
              justifyContent="spaceBetween"
            >
              <div
                {...mouseEvents(onAlpha)}
                className={styles.alpha}
                style={{ width: size, height: sliderSize, backgroundColor }}
              >
                <div
                  className={styles.alphaPoint}
                  style={{
                    left: validColor.getAlpha() * size - 6,
                    height: sliderSize,
                  }}
                />
              </div>
              <div
                className={styles.transparent}
                style={{ width: sliderSize, height: sliderSize }}
                onClick={() => onNewColor(tinycolor('transparent'))}
              />
            </FlexContainer>
          )}
        </div>
        {(useSuggestions || !!suggestions) && (
          <div className={styles.pickerSection}>
            <FlexContainer wrap className={styles.suggestionContainer}>
              {take(suggestions || DEFAULT_SUGGESTIONS, 21)
                .map(tinycolor)
                .filter(
                  hexColor => hexColor.isValid() && hexColor.getAlpha() > 0,
                )
                .map(hexColor => {
                  const suggestionSize = (width - 38) / 7;

                  return (
                    <span
                      key={hexColor.toRgbString()}
                      className={styles.suggestion}
                      onClick={() => onNewColor(hexColor)}
                      style={{
                        backgroundColor: hexColor.toRgbString(),
                        height: suggestionSize,
                        width: suggestionSize,
                      }}
                    />
                  );
                })}
            </FlexContainer>
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <div className={styles.container} ref={container}>
      <div className={styles.inputContainer}>
        <Input
          {...props}
          className={classNames(styles.input, className)}
          value={color}
          onChange={e => setColor(e.target.value)}
          onBlur={e => {
            if (e.target.value === value) {
              return;
            }
            const newColor = tinycolor(e.target.value);
            if (newColor.isValid()) {
              onNewColor(newColor);
            } else if (allowEmpty && !e.target.value) {
              onNewColor();
            } else {
              e.target.value = value;
            }
          }}
        />
        <span
          className={classNames(styles.swatch, {
            [styles['swatch--transparent']]: validColor.getAlpha() === 0,
          })}
          onClick={() => setIsOpen(!isOpen)}
          style={{ backgroundColor: validColor.toRgbString() }}
        />
      </div>
      {pickerContent}
    </div>
  );
}

ColorInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  useSuggestions: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disableAlpha: PropTypes.bool,
};

ColorInput.defaultProps = {
  useSuggestions: false,
  allowEmpty: false,
  suggestions: undefined,
  className: undefined,
  disableAlpha: false,
};
