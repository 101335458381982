import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

export default function ScrollContainer(props) {
  return <div className={style.scrollContainer}>{props.children}</div>;
}
ScrollContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
