import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleSidebar from 'web/components/primitives/collapsible.sidebar';
import SidebarPanel from 'web/components/primitives/sidebar.panel';

export default function CalendarSidebar({
  isOpen,
  isFloating,
  isHidden,
  onToggle,
  children,
}) {
  if (isHidden) {
    return null;
  }
  if (isFloating) {
    return (
      <SidebarPanel isShowing={isOpen} onClose={onToggle} hasOverlay>
        {children}
      </SidebarPanel>
    );
  }
  return (
    <CollapsibleSidebar isOpen={isOpen} onToggle={onToggle} isHidden={isHidden}>
      {children}
    </CollapsibleSidebar>
  );
}

CalendarSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isFloating: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.node,
};

CalendarSidebar.defaultProps = {
  children: null,
};
