module.exports = {
  keyboard: {
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    p: 80,
    r: 82,
    m: 77,
    escape: 27,
    backspace: 8,
    delete: 46,
    spacebar: 32,
    return: 13,
  },
  amazon: {
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    rewind: 227,
    playPause: 179,
    select: 13,
    menu: 18,
  },
};
