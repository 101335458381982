const SPECIAL_TIME_TYPES = {
  none: 'none',
  allDay: 'allDay',
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
};

const SPECIAL_TYPE_OPTIONS = [
  { value: SPECIAL_TIME_TYPES.none, label: 'Exact Time' },
  { value: SPECIAL_TIME_TYPES.allDay, label: 'All Day' },
  { value: SPECIAL_TIME_TYPES.morning, label: 'Morning' },
  { value: SPECIAL_TIME_TYPES.afternoon, label: 'Afternoon' },
  { value: SPECIAL_TIME_TYPES.evening, label: 'Evening' },
];

module.exports = { SPECIAL_TIME_TYPES, SPECIAL_TYPE_OPTIONS };
