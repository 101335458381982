const PERSONAL = 'personal';
const MEAL = 'meal';
const DINING_MEAL_USER_EVENT = 'dining';
const SNACK = 'snack';
const OTHER = 'other';

const ACTIVITY_TYPES = {
  [PERSONAL]: 'Personal',
  [MEAL]: 'Meal',
  [DINING_MEAL_USER_EVENT]: 'Meal',
  [SNACK]: 'Snack',
  [OTHER]: 'Other',
};

const MEAL_TYPES = [SNACK, MEAL];

module.exports = {
  PERSONAL,
  MEAL,
  DINING_MEAL_USER_EVENT,
  SNACK,
  OTHER,
  ACTIVITY_TYPES,
  MEAL_TYPES,
  MEAL_AND_PERSONAL: [...MEAL_TYPES, PERSONAL],
};
