import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { HeaderBar, FlexContainer } from 'web/components/primitives';
import { stripAwayTrailingChar } from 'shared/utils/misc.util';

import styles from './styles.scss';

export default function TabHeader({
  title,
  tabs,
  selectedValue,
  basePath,
  rightComponent,
  ...rest
}) {
  return (
    <HeaderBar title={title} justifyContent="spaceBetween" {...rest}>
      <FlexContainer>
        {tabs.map(({ label, value, onClick, to }) => {
          if (!to) {
            return (
              <div
                {...rest}
                key={label}
                onClick={onClick}
                {...{ [`data-qa-${value}-tab`]: true }}
                className={classNames(styles.tab, {
                  [styles.activeTab]: value === selectedValue,
                })}
              >
                {label}
              </div>
            );
          }
          return (
            <NavLink
              {...rest}
              key={label}
              {...{ [`data-qa-${value}-tab`]: true }}
              to={`${stripAwayTrailingChar(basePath, '/')}/${to}`}
              className={styles.tab}
              activeClassName={styles.activeTab}
              strict={false}
              replace
            >
              {label}
            </NavLink>
          );
        })}
      </FlexContainer>
      {rightComponent}
    </HeaderBar>
  );
}
TabHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  rightComponent: PropTypes.node,
  selectedValue: PropTypes.string,
  basePath: PropTypes.string,
};
TabHeader.defaultProps = {
  rightComponent: null,
  selectedValue: null,
  title: '',
  basePath: '',
};
