import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import {
  ButtonGroup,
  Text,
  FlexContainer,
  Icon,
  IconType,
} from 'web/components/primitives';
import {
  VIEW_PERIODS,
  validDateForAllToView,
} from 'shared/utils/calendar.util';

import style from './styles.scss';

export default function Menu({
  viewPeriod,
  changeViewPeriod,
  changeSelectedDate,
  selectedDate,
  userIsAnyStaff,
  releaseDay,
}) {
  const canShowDate = date => {
    if (userIsAnyStaff) {
      return true;
    }
    return validDateForAllToView(date, releaseDay);
  };

  const changeDate = (number, period) => () =>
    changeSelectedDate(moment(selectedDate).add(number, period));
  return (
    <FlexContainer alignItems="center">
      <ButtonGroup
        className={style.viewBtnGroup}
        onChange={changeViewPeriod}
        value={viewPeriod}
        options={[
          { value: VIEW_PERIODS.days.key, label: 'Day' },
          { value: VIEW_PERIODS.weeks.key, label: 'Week' },
          { value: VIEW_PERIODS.months.key, label: 'Month' },
        ]}
      />
      <Icon
        type={IconType.chevronsLeft}
        size={30}
        className={classNames(style.pagerIcon, {
          [style.disabledPager]: viewPeriod === VIEW_PERIODS.days.key,
        })}
        onClick={changeDate(-1, viewPeriod)}
        data-qa-previous-month
      />
      <Icon
        type={IconType.chevronLeft}
        size={30}
        className={style.pagerIcon}
        onClick={changeDate(-1, 'day')}
        data-qa-previous-day
      />
      <Text
        className={style.todayBtn}
        onClick={() => changeSelectedDate(moment())}
        data-qa-today
      >
        Today
      </Text>
      <Icon
        type={IconType.chevronRight}
        size={30}
        className={classNames(style.pagerIcon, {
          [style.disabledPager]: !canShowDate(
            moment(selectedDate).add(1, 'day'),
          ),
        })}
        onClick={changeDate(1, 'day')}
        data-qa-next-day
      />
      <Icon
        type={IconType.chevronsRight}
        size={30}
        className={classNames(style.pagerIcon, {
          [style.disabledPager]:
            !canShowDate(moment(selectedDate).add(1, viewPeriod)) ||
            viewPeriod === VIEW_PERIODS.days.key,
        })}
        onClick={changeDate(1, viewPeriod)}
        data-qa-next-month
      />
    </FlexContainer>
  );
}

Menu.propTypes = {
  viewPeriod: PropTypes.string.isRequired,
  selectedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  changeSelectedDate: PropTypes.func.isRequired,
  changeViewPeriod: PropTypes.func.isRequired,
  userIsAnyStaff: PropTypes.bool,
  releaseDay: PropTypes.number,
};

Menu.defaultProps = {
  userIsAnyStaff: false,
  releaseDay: 20,
};
