import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlexContainer, Text } from 'web/components/primitives';

import styles from './styles.scss';

const DEFAULT_WIDTH = '4.8em';

export default function AgendaTime({ event, timeWidth, showEndTime }) {
  let style = {};
  if (event.color) {
    style = { borderColor: event.color, backgroundColor: event.color };
  }
  return (
    <>
      <FlexContainer
        style={{
          minWidth: timeWidth || DEFAULT_WIDTH,
          maxWidth: timeWidth || DEFAULT_WIDTH,
        }}
        className={styles.agendaTimeColumn}
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          bold
          data-qa-agenda-start-time
          className={classNames(styles.eventStartTime, {
            [styles['eventStartTime--cancelled']]: event.cancelledAt,
          })}
          size="1.15em"
        >
          {event.formattedStartTime}
        </Text>
        {showEndTime && !event.timeSlot && !event.cancelledAt && (
          <Text meta size=".95em" data-qa-agenda-end-time>
            {event.endTime.replace('am', '').replace('pm', '')}
          </Text>
        )}
        {!!event.timeSlot && (
          <Text meta size=".95em">
            Time Slots
          </Text>
        )}
        {!!event.cancelledAt && (
          <Text meta size=".95em" data-qa-agenda-cancelled>
            Cancelled
          </Text>
        )}
      </FlexContainer>
      {event.color && (
        <div
          className={styles.agendaItemBorder}
          data-qa-agenda-color
          style={style}
        />
      )}
    </>
  );
}

AgendaTime.propTypes = {
  event: PropTypes.shape({
    endTime: PropTypes.oneOfType([
      PropTypes.shape({}).isRequired,
      PropTypes.string,
    ]),
    color: PropTypes.string.isRequired,
    formattedStartTime: PropTypes.string.isRequired,
  }).isRequired,
  timeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showEndTime: PropTypes.bool,
};

AgendaTime.defaultProps = {
  timeWidth: null,
  showEndTime: true,
};
