import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { without, every, find, includes, isUndefined } from 'lodash';

import {
  Button,
  ButtonType,
  FlexContainer,
  Icon,
  IconType,
  Input,
  Label,
} from 'web/components/primitives';
import RowItem from 'web/components/primitives/multi.row.input/row.item';
import Text from 'web/components/primitives/text';

import style from './style.scss';

const DEFAULT_COLUMN_WIDTH = 120;

const buildDefaultState = columns =>
  columns.reduce(
    (map, { key, defaultValue }) => ({
      ...map,
      [key]: !isUndefined(defaultValue) ? defaultValue : '',
    }),
    {},
  );

const defaultedColumns = columns =>
  columns.map(({ width, ...column }) => ({
    ...column,
    width: width || DEFAULT_COLUMN_WIDTH,
  }));

export default class MultiRowInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    typeName: PropTypes.string.isRequired,
    collapsible: PropTypes.bool,
    action: PropTypes.string,
    requiredColumns: PropTypes.arrayOf(PropTypes.string),
    containerProps: PropTypes.shape(),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        width: PropTypes.number,
        defaultValue: PropTypes.any,
        inputProps: PropTypes.shape(),
        labelProps: PropTypes.shape(),
        validator: PropTypes.func,
      }),
    ).isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
      }),
    ),
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: null,
    action: 'Create',
    containerProps: {},
    requiredColumns: [],
    collapsible: false,
    values: [],
  };

  state = {
    newItem: buildDefaultState(this.props.columns),
    errors: [],
    isOpen: true,
  };

  onCreateNewItem = () => {
    this.props.onCreate(this.state.newItem);
    this.setState({ newItem: buildDefaultState(this.props.columns) });
  };

  onUpdateItem = (itemId, key, value) => {
    const { values, onUpdate } = this.props;
    const updatedValue = { ...find(values, { key: itemId }), [key]: value };
    if (this.rowIsValid(updatedValue)) {
      this.setState(prevState => ({
        errors: without(prevState.errors, itemId),
      }));
      onUpdate(itemId, key, value);
    } else {
      this.setState(prevState => ({ errors: [...prevState.errors, itemId] }));
    }
  };

  onUpdateInput = (key, inputProps) => e => {
    const { value } = e.target || e;
    const finalValue = inputProps && inputProps.isMulti ? e : value;
    this.setState(prevState => ({
      newItem: { ...prevState.newItem, [key]: finalValue },
    }));
  };

  getRowErrors(row, markEmpty) {
    const { requiredColumns, columns } = this.props;
    return columns
      .map(({ key, validator }) => {
        const isEmpty = Array.isArray(row[key]) ? !row[key].length : !row[key];
        if (isEmpty && requiredColumns.includes(key) && markEmpty) {
          return { key, error: `${key} Can't be empty` };
        }
        if (!isEmpty && validator) {
          const error = validator(row[key]);
          if (error) {
            return { key, error };
          }
        }
        return false;
      })
      .filter(Boolean);
  }

  rowIsValid(row) {
    const { requiredColumns, columns } = this.props;
    return every(columns, ({ key, validator }) => {
      const isEmpty = Array.isArray(row[key]) ? !row[key].length : !row[key];
      if (isEmpty && requiredColumns.includes(key)) {
        return false;
      }
      if (!isEmpty && validator) {
        return !validator(row[key]);
      }
      return true;
    });
  }

  renderLabel() {
    let content = this.props.label;
    if (!content) {
      return null;
    }
    if (this.props.collapsible) {
      content = (
        <FlexContainer
          alignItems="center"
          className={style.clickable}
          onClick={() =>
            this.setState(prevState => ({ isOpen: !prevState.isOpen }))
          }
        >
          <Icon
            type={IconType[this.state.isOpen ? 'chevronDown' : 'chevronRight']}
            className={style.chevron}
          />
          {content}
        </FlexContainer>
      );
    }
    return (
      <Text secondary size="1.4rem">
        {content}
      </Text>
    );
  }

  renderContent() {
    if (this.props.collapsible && !this.state.isOpen) {
      return null;
    }
    const columns = defaultedColumns(this.props.columns);
    const qaLabel = (this.props.label || this.props.typeName)
      .toLowerCase()
      .split(' ')
      .join('-');
    const currentErrors = this.getRowErrors(this.state.newItem);

    return (
      <FlexContainer
        {...{ [`data-qa-${qaLabel}`]: true }}
        className={style.content}
        alignSelf="flexStart"
        justifyContent="flexStart"
        flexDirection="column"
        flex="1"
      >
        <FlexContainer alignItems="center">
          {columns.map(({ key, name, width, labelProps }) => (
            <Label
              key={key}
              style={{ width }}
              className={style.item}
              {...labelProps}
            >
              {name}
            </Label>
          ))}
        </FlexContainer>
        <FlexContainer alignItems="center" className={style.row}>
          {columns.map(({ key, type, width, inputProps }) => {
            const value = this.state.newItem[key];
            let options = [];
            if (inputProps.options) {
              if (!inputProps.options.isMulti) {
                options = inputProps.options.filter(
                  option =>
                    !includes(
                      this.props.values.map(v => v.key),
                      option.value,
                    ),
                );
              } else if (Array.isArray(value)) {
                value.forEach(v => options.push({ value: v, label: v }));
              } else {
                options.push({ value, label: value });
              }
            }

            const finalValue =
              inputProps.isMulti && !Array.isArray(value) ? [value] : value;

            const currentError = find(currentErrors, {
              key,
            });
            return (
              <Input
                key={key}
                type={type}
                className={style.item}
                onChange={this.onUpdateInput(key, inputProps)}
                style={{ width }}
                value={finalValue}
                isError={currentError}
                data-new-item-input={key}
                {...inputProps}
                options={options}
              />
            );
          })}
          <Button
            onClick={this.onCreateNewItem}
            type={ButtonType.required}
            disabled={!this.rowIsValid(this.state.newItem)}
            data-create-new-item={this.props.typeName}
          >
            {this.props.action} {this.props.typeName}
          </Button>
        </FlexContainer>
        {currentErrors.map(({ key, error }) => (
          <Text key={key} isError style={{ marginBottom: 16 }}>
            {error}
          </Text>
        ))}
        {this.props.values.map(value => (
          <RowItem
            key={value.key || 'temp'}
            isError={includes(this.state.errors, value.key)}
            value={value}
            columns={columns}
            onChange={this.onUpdateItem}
            onDelete={this.props.onDelete}
          />
        ))}
      </FlexContainer>
    );
  }

  render() {
    return (
      <div {...this.props.containerProps}>
        {this.renderLabel()}
        {this.renderContent()}
      </div>
    );
  }
}
