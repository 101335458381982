import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon, IconType } from 'stories';
import styles from './styles.scss';

export default function Link({
  route,
  name,
  isDisabled,
  isExternal,
  onChange,
  selected,
}) {
  if (onChange) {
    return (
      <div
        key={route}
        data-qa-link={route}
        onClick={() => {
          if (route !== selected && !isDisabled) {
            onChange(route);
          }
        }}
        className={classNames(styles.item, {
          [styles['item--selected']]: route === selected,
          [styles['item--disabled']]: isDisabled,
        })}
      >
        {name}
        <Icon type={IconType.chevronRight} className={styles.icon} size={20} />
      </div>
    );
  }
  if (isExternal) {
    return (
      <a
        key={route}
        href={route}
        data-qa-link={route}
        className={classNames(styles.item, {
          [styles['item--disabled']]: isDisabled,
        })}
        disabled={isDisabled}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
        <Icon type={IconType.chevronRight} className={styles.icon} size={20} />
      </a>
    );
  }
  return (
    <NavLink
      to={route}
      key={route}
      data-qa-link={route}
      activeClassName={styles['item--selected']}
      className={classNames(styles.item, {
        [styles['item--disabled']]: isDisabled,
      })}
      disabled={isDisabled}
      isActive={(match, location) => location.pathname.indexOf(route) > -1}
    >
      <span className={styles.label}>{name}</span>
      <Icon type={IconType.chevronRight} className={styles.icon} size={20} />
    </NavLink>
  );
}

Link.propTypes = {
  route: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool,
};

Link.defaultProps = {
  isDisabled: false,
  isExternal: false,
  onChange: undefined,
};
