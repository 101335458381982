/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { groupBy, map } from 'lodash';

import { getViewPeriodInfo, VIEW_PERIODS } from 'shared/utils/calendar.util';
import FlexContainer from 'web/components/primitives/flex-container';
import { sortByAllDayThenTime } from 'shared/utils/activity.util';
import Text from 'web/components/primitives/text';

import styles from './styles.scss';

export default function CalendarTable({ selectedDate, events }) {
  const { days } = getViewPeriodInfo(VIEW_PERIODS.months.key, selectedDate);
  const weeks = groupBy(days, day => moment(day).week());
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>
        <tbody>
          {map(weeks, daysForWeek => (
            <tr>
              {daysForWeek.map(day => {
                const dayEvents = events[day.dayOfYear()] || [];
                return (
                  <td>
                    <FlexContainer
                      flexDirection="column"
                      justifyContent="flexStart"
                      alignItems="flexStart"
                    >
                      <Text bold size={12} mb={4}>
                        {day.date()}
                      </Text>
                      {dayEvents.sort(sortByAllDayThenTime).map(event => (
                        <Text
                          size={12}
                          className={classNames({
                            [styles.cancelled]: event.cancelledAt,
                          })}
                        >
                          {`${event.formattedStartTime
                            .replace(/am|pm/gi, '')
                            .trim()} ${event.name}\n`}
                        </Text>
                      ))}
                    </FlexContainer>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

CalendarTable.propTypes = {
  events: PropTypes.shape({}),
  selectedDate: PropTypes.instanceOf(moment).isRequired,
};

CalendarTable.defaultProps = {
  events: {},
};
