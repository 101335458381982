const moment = require('moment');
const {
  uniqBy,
  flatten,
  find,
  intersection,
  sortBy,
  isNumber,
  upperFirst,
  mapValues,
  orderBy,
  filter,
  includes,
  pick,
  toNumber,
} = require('lodash');

const COLORS = require('../constants/colors').default;
const { SPECIAL_TIME_TYPES } = require('../constants/special.time.types');
const {
  combineTimeStringAndDate,
  getSpecialSortKeyValue,
} = require('./time.util');

const { isSupport, isFamily, isResident } = require('./security.util');
const { createInstances, recurringEventKey } = require('./recurring.util');
const { NO_UPDATES, EXPOSED_CREATE } = require('../constants/activities');
const {
  PERSONAL,
  MEAL,
  DINING_MEAL_USER_EVENT,
  SNACK,
  OTHER,
} = require('../constants/activity.types');
const { filterByString } = require('./misc.util');
const { REGISTRATION_TYPES, COPY_FIELDS } = require('../constants/activities');

const TIME_FORMAT = 'hh:mm a';
const DATE_FORMAT = 'MM-DD-YYYY';

const getColorsByTypes = types => ({
  ...mapValues(types, ({ color }) => color),
  [PERSONAL]: COLORS.personal,
  [MEAL]: COLORS.meal,
  [DINING_MEAL_USER_EVENT]: COLORS.meal,
  [SNACK]: COLORS.snack,
  [OTHER]: COLORS.other,
});

const editableActivityFieldsForUser = user => {
  if (!user) {
    return NO_UPDATES;
  }

  if (isSupport(user)) {
    return EXPOSED_CREATE;
  }

  if (isFamily(user)) {
    return [
      'connectionRegistrations',
      'connectionRegistrationData',
      'recurring',
      'modified',
    ];
  }

  if (isResident(user)) {
    return ['registrations', 'recurring', 'modified'];
  }

  return null;
};

const getFormActivityTitle = activity =>
  activity.recurring || activity.recurringId
    ? 'Recurring Activity'
    : 'Scheduled Activity';

const getCalendarContentColor = ({ type }, activityTypes) => {
  const colorsByType = getColorsByTypes(activityTypes);
  return colorsByType[type] || colorsByType[OTHER];
};

const getActivityTypeInfo = ({ type }, activityTypes = {}) => {
  const color = getCalendarContentColor({ type }, activityTypes);
  const typeLabel = upperFirst((activityTypes[type] || { name: type }).name);
  return { color, typeLabel };
};

const sortByAllDayThenTime = (a, b) => {
  const aStart = moment(a.start);
  const bStart = moment(b.start);
  if (aStart.isBefore(bStart, 'day')) {
    return -1;
  }
  if (aStart.isAfter(bStart, 'day')) {
    return 1;
  }
  if (a.special === SPECIAL_TIME_TYPES.allDay) {
    return -1;
  }
  if (
    a.special === SPECIAL_TIME_TYPES.morning &&
    b.special !== SPECIAL_TIME_TYPES.allDay
  ) {
    return -1;
  }
  if (
    b.special === SPECIAL_TIME_TYPES.allDay ||
    b.special === SPECIAL_TIME_TYPES.morning
  ) {
    return 1;
  }
  if (
    aStart.format(`${DATE_FORMAT} ${TIME_FORMAT}`) !==
    bStart.format(`${DATE_FORMAT} ${TIME_FORMAT}`)
  ) {
    return aStart.isBefore(bStart) ? -1 : 1;
  }
  if (!a.name) {
    return 1;
  }
  return a.name.localeCompare(b.name);
};

const formattedSpecialTimes = special => {
  if (special === SPECIAL_TIME_TYPES.allDay) {
    return 'All Day';
  }
  if (special === SPECIAL_TIME_TYPES.morning) {
    return 'Morning';
  }
  if (special === SPECIAL_TIME_TYPES.afternoon) {
    return 'Afternoon';
  }
  if (special === SPECIAL_TIME_TYPES.evening) {
    return 'Evening';
  }
  return null;
};

const setFormattedStartTime = (special, startTimestamp, format = 'h:mm a') =>
  formattedSpecialTimes(special) || moment(startTimestamp).format(format);

const combineTimeAndDate = (time, date) => {
  // parse date as local because we don't want utc time to kick date
  // into the next or previous day. Return as local moment object
  // because that is what the end user understands.
  const startDate = moment(date).local();
  return moment(time)
    .local()
    .year(startDate.year())
    .month(startDate.month())
    .date(startDate.date());
};

const normalizeRegistered = event =>
  sortBy(event.registrations, 'created').reduce(
    (mapping, { resident, ...rest }, index) => {
      const isWaitList =
        isNumber(event.maxRegistered) && event.maxRegistered < index + 1;
      const position = isWaitList ? index + 1 - event.maxRegistered : index + 1;
      return {
        ...mapping,
        [resident && resident._id ? resident._id.toString() : resident]: {
          ...rest,
          isWaitList,
          resident,
          position,
        },
      };
    },
    {},
  );

const normalizeRegisteredConnections = event =>
  sortBy(event.connectionRegistrationData, 'created').reduce(
    (mapping, { user, ...rest }, index) => {
      const isWaitList =
        isNumber(event.maxRegisteredConnections) &&
        event.maxRegisteredConnections < index + 1;
      const position = isWaitList
        ? index + 1 - event.maxRegisteredConnections
        : index + 1;
      return {
        ...mapping,
        [user && user._id ? user._id.toString() : user]: {
          ...rest,
          isWaitList,
          user,
          position,
        },
      };
    },
    {},
  );

const getDurationInMinutes = event => {
  const start = combineTimeStringAndDate(
    event.departTime || event.startTime,
    event.startDate,
  );
  const end = combineTimeStringAndDate(event.endTime, event.endDate);
  return end.diff(start, 'minutes');
};

const normalizeActivityTimes = event => {
  const start = combineTimeStringAndDate(
    event.departTime || event.startTime,
    event.startDate,
  );
  const end = combineTimeStringAndDate(event.endTime, event.endDate);
  const formattedEndTime =
    event.special && event.special !== SPECIAL_TIME_TYPES.none
      ? undefined
      : end.clone().format('h:mm a');
  return {
    ...event,
    start,
    end,
    formattedEndTime,
    durationInMinutes: getDurationInMinutes(event),
    formattedStartTime: setFormattedStartTime(event.special, start),
    date: start.toISOString(),
    special: event.special || SPECIAL_TIME_TYPES.none,
    specialSortKeyValue: getSpecialSortKeyValue(event),
    started: moment(start).isBefore(moment()),
    ended: moment(end).isBefore(moment()),
  };
};

const getLengthOrField = value =>
  Array.isArray(value) ? value.length : value || 0;

const normalizeActivity = (event, { calendars, types } = {}) => {
  if (event.normalized) {
    return event;
  }
  let calendarName = event.calendarName;
  let calendarInfo;
  if (calendars && event.calendar) {
    calendarInfo = calendars.filter(cal => cal._id === event.calendar)[0];
    calendarName = (calendarInfo || {}).name;
  }
  calendarName = Array.isArray(calendarName)
    ? calendarName.join('')
    : calendarName;
  const registeredMapping = normalizeRegistered(event);
  const registeredConnectionMapping = normalizeRegisteredConnections(event);
  const key = recurringEventKey(event);
  return {
    ...normalizeActivityTimes(event),
    ...getActivityTypeInfo(event, types),
    calendarInfo,
    calendarName,
    key,
    id: key,
    registeredMapping,
    registeredConnectionMapping,
    staffAssigned: event.staffAssigned || [],
    attended: Array.isArray(event.attended)
      ? uniqBy(event.attended)
      : event.attended,
    declined: Array.isArray(event.declined)
      ? uniqBy(event.declined)
      : event.declined,
    registrations: Array.isArray(event.registrations)
      ? sortBy(event.registrations, 'created')
      : event.registrations,
    noteCount: event.noteCount || 0,
    photoCount: getLengthOrField(event.photos),
    attendCount: getLengthOrField(event.attended),
    declineCount: getLengthOrField(event.declined),
    registeredCount: getLengthOrField(event.registrations),
    connectionRegisteredCount: getLengthOrField(event.connectionRegistrations),
    type: event.type ? event.type.toLowerCase() : '',
    canRegisterForFutureInstances: event.recurring?.pattern
      ? !event.timeSlot
      : !event.timeSlot && !!event.recurringId,
    normalized: true,
    eventType: 'activity',
  };
};

const normalizeActivities = (activities, { calendars, start, end, types }) =>
  flatten(
    activities.map(act => {
      if (act.recurring && act.recurring.pattern && start && end) {
        return createInstances(act, start, end);
      }
      return act;
    }),
  )
    .map(act => normalizeActivity(act, { calendars, types }))
    .filter(
      act =>
        moment(act.start).isSameOrAfter(start) &&
        moment(act.start).isSameOrBefore(end),
    )
    .sort(sortByAllDayThenTime);

const normalizeForTypeAndUser = (activity, types) => ({
  ...activity,
  ...getActivityTypeInfo(activity, types),
});

const getTimesFromSpecial = ({ special, startTime, endTime }) => {
  if (special === SPECIAL_TIME_TYPES.allDay) {
    return {
      startTime: '08:00 am',
      endTime: '05:00 pm',
    };
  }
  if (special === SPECIAL_TIME_TYPES.morning) {
    return {
      startTime: '08:00 am',
      endTime: '10:30 am',
    };
  }
  if (special === SPECIAL_TIME_TYPES.afternoon) {
    return {
      startTime: '12:00 pm',
      endTime: '02:30 pm',
    };
  }
  if (special === SPECIAL_TIME_TYPES.evening) {
    return {
      startTime: '05:00 pm',
      endTime: '07:30 pm',
    };
  }
  return { startTime, endTime };
};

const normalizeNumber = val => {
  const number = val ? toNumber(val) : 0;
  return Number.isNaN(number) ? 0 : number;
};

const willClearTimeSlots = ({
  originalActivity: original,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  durationInMinutes,
  ...current
}) =>
  current.timeSlot
    ? !!original &&
      (normalizeNumber(original.timeSlot?.duration) !==
        normalizeNumber(current.timeSlot.duration) ||
        normalizeNumber(original.timeSlot?.perSlot) >
          normalizeNumber(current.timeSlot.perSlot) ||
        normalizeNumber(original.timeSlot?.connectionsPerSlot) >
          normalizeNumber(current.timeSlot.connectionsPerSlot) ||
        getDurationInMinutes(original) > getDurationInMinutes(current))
    : !!original?.timeSlot;

const prepareActivityForSave = act => {
  let registrations = act.registrations;
  let connectionRegistrations = act.connectionRegistrations;
  let connectionRegistrationData = act.connectionRegistrationData;
  const content = [
    ...(act.content || []),
    ...(act.unsavedContentAttachments || []),
  ];

  if (willClearTimeSlots(act)) {
    registrations = [];
    connectionRegistrations = [];
    connectionRegistrationData = {};
  }
  return {
    ...act,
    location: act.location?.trim(),
    name: act.name?.trim(),
    registrations,
    connectionRegistrations,
    connectionRegistrationData,
    ...getTimesFromSpecial(act),
    endDate: act.startDate,
    attended: Array.isArray(act.attended)
      ? uniqBy(act.attended || [])
      : undefined,
    content,
    declined: Array.isArray(act.declined)
      ? uniqBy(act.declined || [])
      : undefined,
  };
};

const prepareActivityForCopy = (activity, destinationCalendars) => {
  const generalActivity = pick(prepareActivityForSave(activity), COPY_FIELDS);
  const newActivity = {
    ...generalActivity,
    calendars: destinationCalendars,
  };

  const recurringPattern = activity.recurring || activity.parentRecurring;
  if (recurringPattern) {
    newActivity.recurring = {
      ...recurringPattern,
      exceptions: [],
      // Start the recurrence pattern on the copied activity's day
      startDate: activity.startDate,
    };

    newActivity.recurringId = undefined;
  }

  return newActivity;
};

const dayOfWeekSorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const sortByDayOfWeek = (a, b) => {
  if (dayOfWeekSorter[a] < dayOfWeekSorter[b]) {
    return -1;
  }
  return 1;
};

const buildReadablePatternString = (activityPattern, startDate, lastDate) => {
  if (!activityPattern) {
    return '';
  }
  const startMoment = moment(startDate);
  const customPattern = activityPattern.split('/');
  let result = '';
  const pattern = customPattern[0];
  const every = customPattern[1];
  const dayOfWeekForMonth = customPattern[3];
  const isSingular = !every || every === '1';
  if (pattern === 'daily') {
    result = isSingular ? 'Daily' : `Every ${every} days`;
  } else if (pattern === 'weekly') {
    result = isSingular ? 'Weekly ' : `Every ${every} weeks `;
    if (customPattern.length > 2) {
      const days = customPattern[2]
        .split(',')
        .sort(sortByDayOfWeek)
        .map(day => moment(day, 'dddd').format('ddd'))
        .join(', ');
      result += `on ${days}`;
    } else {
      result += `on ${startMoment.format('ddd')}`;
    }
  } else if (!dayOfWeekForMonth && pattern === 'monthly') {
    result = `Monthly on the ${startMoment.format('Do')}`;
  } else if (dayOfWeekForMonth && pattern === 'monthly') {
    result = `${isSingular ? 'Monthly' : `Every ${every} months`} on the ${
      customPattern[2]
    } ${dayOfWeekForMonth}`;
  }
  if (lastDate) {
    result = `${result}\n${startMoment.format('l')} - ${moment(lastDate).format(
      'l',
    )}`;
  }
  return result;
};

const buildActivityPhotoUrl = (
  selectedEvent,
  userCalendars,
  residents,
  userFacilities,
  baseUrl,
) => {
  const selectedCalendar = find(
    userCalendars,
    cal => cal._id === selectedEvent.calendar,
  );
  let facilities = [];
  if (!selectedCalendar) {
    facilities = uniqBy(
      flatten(
        (selectedEvent.attended || [])
          .map(resident => residents[resident])
          .filter(res => res)
          .map(resident => resident.facilities),
      ),
    );
    if (!facilities.length) {
      facilities = userFacilities;
    }
  } else {
    facilities = selectedCalendar.facilities;
  }
  const facilityIdString = facilities
    .map(fac => (fac._id ? fac._id : fac))
    .join(',');
  const url = `${baseUrl}/rest/activities/upload/photo/${facilityIdString}/${selectedEvent._id}`;
  return url;
};

const isInstanceOfRecurring = activity =>
  !!activity &&
  ((activity.attended || []).length > 0 ||
    (activity.declined || []).length > 0 ||
    (activity.photos || []).length > 0 ||
    (activity.registrations || []).length > 0 ||
    (activity.connectionRegistrations || []).length > 0 ||
    activity.noteCount > 0);

const residentsToShowForEvent = (
  residents,
  event,
  group,
  filterBy,
  communities,
  sort = { value: 'fullName', direction: 'asc' },
) => {
  if (!event) {
    return [];
  }
  const calendarFacilities = (
    event.calendarInfo || { facilities: [] }
  ).facilities.map(fac => fac._id);
  const facilitiesForCommunity = flatten(
    filter(
      communities,
      com => intersection(com.facilities, calendarFacilities).length,
    ).map(com => com.facilities),
  );
  return orderBy(
    uniqBy(
      residents.filter(resident => {
        const residentFacilityIds = resident.facilities.map(fac => fac._id);
        const groupIsAll = group === 'all';
        let passesCommunityFilter = false;
        if (groupIsAll) {
          passesCommunityFilter = intersection(
            facilitiesForCommunity,
            residentFacilityIds,
          ).length;
        }
        const isHostedAndMatches =
          (group === 'hosted' || group === 'suggested') &&
          intersection(residentFacilityIds, calendarFacilities).length;
        const isRegisteredAndResidentIsRegistered =
          group === 'registered' &&
          event.registeredMapping &&
          event.registeredMapping[resident._id] &&
          !event.registeredMapping[resident._id].isWaitList;
        const matchesFilter = filterByString(filterBy, [
          'fullName',
          'roomNumber',
          'nickname',
        ])(resident);
        return (
          (passesCommunityFilter ||
            isHostedAndMatches ||
            isRegisteredAndResidentIsRegistered) &&
          matchesFilter
        );
      }),
      '_id',
    ),
    sort.value,
    sort.direction,
  );
};

const formatActivityTime = (time = '') =>
  time[0] === '0' ? time.slice(1) : time;

const getTimeSlotInfo = activity => {
  const start = combineTimeStringAndDate(
    activity.startTime,
    activity.startDate,
  );
  const end = combineTimeStringAndDate(activity.endTime, activity.startDate);
  const activityDuration = end.diff(start, 'minutes');
  const info = {
    startTime: formatActivityTime(activity.startTime),
    endTime: formatActivityTime(activity.endTime),
    activityDuration,
    label: undefined,
  };

  const { duration, perSlot, connectionsPerSlot } = activity.timeSlot || {};
  const durationNum = Number.parseInt(duration, 10);
  if (Number.isNaN(durationNum)) {
    return info;
  }

  const perSlotNum = Number.parseInt(perSlot, 10);
  const connectionsPerSlotNum = Number.parseInt(connectionsPerSlot, 10);

  const segments = Math.floor(activityDuration / durationNum);
  info.label = `${segments}, ${durationNum} minute Time Slots${
    connectionsPerSlotNum > 0 ? ' with connections' : ''
  }`;
  info.duration = durationNum;
  info.segments = segments;

  if (Number.isNaN(perSlotNum)) {
    return info;
  }

  info.perSlot = perSlotNum;

  if (Number.isNaN(connectionsPerSlotNum)) {
    return info;
  }

  info.connectionsPerSlot = connectionsPerSlotNum;

  return info;
};

const generateTimeSlots = (
  event,
  residents = {},
  connections = {},
  currentUser = {},
) => {
  const { duration, perSlot, connectionsPerSlot, segments } = getTimeSlotInfo(
    event,
  );

  if (!segments) {
    return [];
  }
  const start = combineTimeStringAndDate(event.startTime, event.startDate);
  return Array.from(Array(segments)).map((_, index) => {
    if (index) {
      start.add(duration, 'minutes');
    }

    const registeredResidentIds = event.registrations
      .filter(({ timeSlot }) => timeSlot === index)
      .map(({ resident }) => resident);
    const registeredResidents = registeredResidentIds
      .map(resident =>
        currentUser._id === resident ? currentUser : residents[resident],
      )
      .filter(Boolean);

    const registeredConnectionIds = filter(
      event.connectionRegistrationData,
      ({ timeSlot }) => timeSlot === index,
    ).map(({ user }) => user);
    const registeredConnections = registeredConnectionIds
      .map(connection =>
        currentUser._id === connection ? currentUser : connections[connection],
      )
      .filter(Boolean);
    return {
      time: start.format('h:mm a'),
      endTime: start
        .clone()
        .add(duration, 'minutes')
        .format('h:mm a'),
      perSlot,
      connectionsPerSlot,
      registered: [...registeredResidents, ...registeredConnections],
      numRegistered: registeredResidentIds.length,
      connectionsNumRegistered: registeredConnectionIds.length,
      registeredResidentIds,
      registeredConnectionIds,
    };
  });
};

const getRegisteredText = (event, userId, verbose) => {
  const isConnectionRegistration =
    userId &&
    includes(
      event.connectionRegistrations?.map(cr => cr._id || cr),
      userId,
    );

  let registrationData;
  let maxRegistrations;
  if (isConnectionRegistration) {
    registrationData = event.registeredConnectionMapping[userId];
    maxRegistrations = event.maxRegisteredConnections;
  } else {
    registrationData = event.registeredMapping[userId];
    maxRegistrations = event.maxRegistered;
  }

  // Supports old activities which don't have connectionRegistrationData
  if (!registrationData) {
    return 'Registered';
  }

  if (isNumber(maxRegistrations)) {
    return registrationData.isWaitList
      ? `Waitlist #${registrationData.position}`
      : `Registered #${registrationData.position}`;
  }

  const timeSlots = generateTimeSlots(event);
  if (
    event &&
    event.timeSlot &&
    timeSlots &&
    registrationData &&
    timeSlots[registrationData.timeSlot]
  ) {
    return verbose
      ? `Registered for ${timeSlots[registrationData.timeSlot].time} time slot`
      : timeSlots[registrationData.timeSlot].time;
  }

  return 'Registered';
};

const getRegistrationType = activity => {
  if (activity.timeSlot) {
    return REGISTRATION_TYPES.TIME_SLOTS.value;
  }
  if (activity.maxRegistered === 0 && !activity.maxRegisteredConnections) {
    return REGISTRATION_TYPES.DISABLED.value;
  }
  return REGISTRATION_TYPES.OPEN.value;
};

module.exports = {
  normalizeActivity,
  normalizeActivityTimes,
  combineTimeAndDate,
  editableActivityFieldsForUser,
  getTimesFromSpecial,
  willClearTimeSlots,
  prepareActivityForSave,
  prepareActivityForCopy,
  buildReadablePatternString,
  buildActivityPhotoUrl,
  sortByAllDayThenTime,
  formattedSpecialTimes,
  setFormattedStartTime,
  isInstanceOfRecurring,
  TIME_FORMAT,
  DATE_FORMAT,
  normalizeActivities,
  normalizeForTypeAndUser,
  normalizeRegistered,
  normalizeRegisteredConnections,
  createInstances,
  getColorsByTypes,
  getFormActivityTitle,
  getCalendarContentColor,
  getActivityTypeInfo,
  residentsToShowForEvent,
  getLengthOrField,
  getTimeSlotInfo,
  getRegisteredText,
  generateTimeSlots,
  getRegistrationType,
};
