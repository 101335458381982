import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.scss';

const PHOTO_ALLOW =
  'image/png, image/jpeg, image/jpg, image/svg, image/heic, image/heif';

export default function FileInputButton({
  forwardedRef,
  className,
  onChange,
  multiple,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  accept,
  photosOnly,
  ...props
}) {
  return (
    <input
      ref={forwardedRef}
      type="file"
      name={multiple ? 'files' : 'file'}
      className={classNames(style.fileInput, className)}
      accept={photosOnly ? PHOTO_ALLOW : accept}
      multiple={multiple}
      onChange={onChange}
      {...props}
    />
  );
}

FileInputButton.propTypes = {
  forwardedRef: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

FileInputButton.defaultProps = {
  className: null,
  multiple: false,
  accept: '*',
};
