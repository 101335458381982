import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from '../flex-container';
import styles from './styles.scss';

export default function Container({ children }) {
  return (
    <FlexContainer
      keepHeight
      flexDirection="column"
      justifyContent="spaceBetween"
      className={styles.container}
    >
      {children}
    </FlexContainer>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
