import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon, IconType } from 'stories';
import styles from './styles.scss';

export default function Badge({
  children,
  className,
  isAlert,
  isAction,
  isSuccess,
  isWarning,
  isComplete,
  isSecondary,
  ...props
}) {
  return (
    <label
      className={classNames(styles.badge, className, {
        [styles.hoverable]: !!props.onClick,
        [styles.alert]: isAlert,
        [styles.action]: isAction,
        [styles.success]: isSuccess,
        [styles.warning]: isWarning,
        [styles.complete]: isComplete,
        [styles.secondary]: isSecondary,
      })}
      {...props}
    >
      {children}
      {props.onClick && isAction && (
        <Icon
          type={IconType.chevronRight}
          size={16}
          className={styles.chevron}
        />
      )}
    </label>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isAlert: PropTypes.bool,
  isAction: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isSecondary: PropTypes.bool,
};

Badge.defaultProps = {
  className: undefined,
  isAlert: false,
  isAction: false,
  isSuccess: false,
  isSecondary: false,
};
