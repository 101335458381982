import React from 'react';
import PropTypes from 'prop-types';

import ListHeader from 'stories/list.header';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';
import { EmptyLoadingPlaceholder } from '../../client/web/components/primitives';

export default function ListArea({
  title,
  headerContext,
  headerActions,
  hiddenPrevious,
  completedQuantity,
  moreToShow,
  extraQuantity,
  contentDescriptor,
  rows,
  emptyText,
}) {
  return (
    <FlexContainer
      alignItems="center"
      flexDirection="column"
      className={styles.container}
    >
      <ListHeader
        title={title}
        context={headerContext}
        actions={headerActions}
      />
      {/* HIDDEN PREVIOUS MORE */}
      {hiddenPrevious && (
        <FlexContainer
          alignItems="center"
          flexDirection="column"
          className={styles.previousWrapper}
        >
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            className={styles.expandButton}
          >
            {completedQuantity} items completed
          </FlexContainer>
        </FlexContainer>
      )}
      {/* LIST */}
      <div className={styles.listWrapper}>{rows}</div>
      {/* LOAD MORE BAR */}
      {(moreToShow || extraQuantity > 0) && (
        <FlexContainer
          alignItems="center"
          flexDirection="column"
          className={styles.moreWrapper}
        >
          <div className={styles.shadow} />
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            className={styles.moreButton}
          >
            show {extraQuantity} more {contentDescriptor}
          </FlexContainer>
        </FlexContainer>
      )}
      <EmptyLoadingPlaceholder isEmpty={!rows?.length} emptyText={emptyText} />
    </FlexContainer>
  );
}

ListArea.propTypes = {
  title: PropTypes.string,
  headerContext: PropTypes.node,
  headerActions: PropTypes.node,
  hiddenPrevious: PropTypes.bool,
  completedQuantity: PropTypes.number,
  moreToShow: PropTypes.bool,
  extraQuantity: PropTypes.number,
  contentDescriptor: PropTypes.string,
  rows: PropTypes.node,
  emptyText: PropTypes.string,
};

ListArea.defaultProps = {
  title: undefined,
  headerContext: undefined,
  headerActions: undefined,
  hiddenPrevious: false,
  completedQuantity: undefined,
  moreToShow: false,
  extraQuantity: undefined,
  contentDescriptor: undefined,
  rows: undefined,
  emptyText: 'Nothing to Show',
};
