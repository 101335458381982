import React from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from 'web/components/primitives/dropdown.select';

import styles from './styles.scss';

const OptionValue = key => ({
  children,
  innerProps,
  innerRef,
  getStyles,
  ...props
}) => {
  const singleValue = key === 'singleValue';
  return (
    <div
      data-qa={!singleValue && props.data.label.toString().trim()}
      ref={innerRef}
      style={getStyles(key, props)}
      {...innerProps}
    >
      <span
        className={styles.circle}
        style={{ backgroundColor: props.data.color }}
      />
      {children}
    </div>
  );
};

export default function ColoredSelect({ options, onChange, ...rest }) {
  return (
    <DropdownSelect
      options={options}
      onChange={option => onChange((option || {}).value)}
      components={{
        SingleValue: OptionValue('singleValue'),
        Option: OptionValue('option'),
      }}
      {...rest}
    />
  );
}

ColoredSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
