import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import classNames from 'classnames';

import styles from './styles.scss';

export const LinkType = {
  default: 'additional',
  additional: 'additional',
  primary: 'primary',
  attention: 'attention',
};

export const CursorType = {
  default: 'default',
  wait: 'wait',
  modal: 'modal',
  navigate: 'navigate',
};

export default function InlineLink({
  children,
  type,
  cursor,
  size,
  onClick,
  className,
}) {
  return (
    <div className={classNames(styles.wrapper, className)} onClick={onClick}>
      <div
        style={{ fontSize: size }}
        className={classNames(styles.linkText, styles[type], styles[cursor])}
      >
        {children}
      </div>
    </div>
  );
}

InlineLink.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(values(LinkType)),
  cursor: PropTypes.oneOf(values(CursorType)),
  size: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

InlineLink.defaultProps = {
  children: undefined,
  type: LinkType.additional,
  cursor: CursorType.default,
  size: undefined,
  className: undefined,
};
