import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlexContainer, Text } from 'web/components/primitives';

import styles from './styles.scss';

export default function InfoContainer({ title, mini, children }) {
  return (
    <FlexContainer className={styles.container}>
      <FlexContainer
        flexDirection="column"
        className={classNames(styles.infoContainer, {
          [styles['infoContainer--mini']]: mini,
        })}
      >
        <Text bold size="1.6rem" className={styles.title}>
          {title}
        </Text>
        {children}
      </FlexContainer>
    </FlexContainer>
  );
}

InfoContainer.propTypes = {
  title: PropTypes.string.isRequired,
  mini: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

InfoContainer.defaultProps = {
  mini: false,
};
