import PropTypes from 'prop-types';

import { resolveComponentWithProps } from './router.utils';

export default function FormRoute({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  route,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  exact,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  strict,
  form,
  onUpdateForm,
  setActiveRoute,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activeRoute,
  component,
  ...rest
}) {
  return resolveComponentWithProps(component, {
    ...rest,
    form,
    onUpdateForm,
    toFormRoute: newRoute => {
      if (newRoute !== route) {
        setActiveRoute(newRoute);
      }
    },
  });
}

FormRoute.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  form: PropTypes.shape(),
  activeRoute: PropTypes.string,
  setActiveRoute: PropTypes.func,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape(),
  ]).isRequired,
};

FormRoute.defaultProps = {
  title: '',
  route: '/',
  exact: false,
  strict: false,
  form: {},
  activeRoute: '/',
  setActiveRoute: () => {},
};
