import React from 'react';
import PropTypes from 'prop-types';

import StickyTable from './sticky.table';
import Table from './table';

export default function TableSwitch({ fixedLeftColumns, ...props }) {
  if (fixedLeftColumns > 0) {
    return <StickyTable fixedLeftColumns={fixedLeftColumns} {...props} />;
  }
  return <Table {...props} />;
}

TableSwitch.propTypes = {
  fixedLeftColumns: PropTypes.number,
};

TableSwitch.defaultProps = {
  fixedLeftColumns: 0,
};
