import React from 'react';
import PropTypes from 'prop-types';
import { parseForUrls } from 'shared/utils/misc.util';

export default function Linkify({ text, className }) {
  const messageArray = parseForUrls(text);
  return messageArray.map(wordOrUrl => {
    if (typeof wordOrUrl === 'string') {
      return wordOrUrl;
    }
    return (
      <a
        className={className}
        href={wordOrUrl.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {wordOrUrl.value}
      </a>
    );
  });
}

Linkify.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

Linkify.defaultProps = {
  text: '',
  className: '',
};
