import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UserPlaceholder from 'assets/images/global/user-placeholder.png';
import styles from './styles.scss';

export default function Avatar({ url, className, circular, size }) {
  return (
    <div
      style={{
        backgroundImage: `url(${url || UserPlaceholder})`,
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      }}
      alt="user avatar"
      className={classNames(className, {
        [styles.circular]: circular,
      })}
    />
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.number,
  circular: PropTypes.bool,
};

Avatar.defaultProps = {
  className: null,
  url: null,
  size: 40,
  circular: true,
};
