"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validPassword = exports.validatePasswordRequirements = exports.PasswordRequirement = exports.MIN_PASSWORD_LENGTH = void 0;
exports.MIN_PASSWORD_LENGTH = 12;
var PasswordRequirement;
(function (PasswordRequirement) {
    PasswordRequirement[PasswordRequirement["Length"] = 0] = "Length";
    PasswordRequirement[PasswordRequirement["MixedCase"] = 1] = "MixedCase";
    PasswordRequirement[PasswordRequirement["LettersAndNumbers"] = 2] = "LettersAndNumbers";
    PasswordRequirement[PasswordRequirement["SpecialCharacters"] = 3] = "SpecialCharacters";
})(PasswordRequirement || (exports.PasswordRequirement = PasswordRequirement = {}));
var validatePasswordRequirements = function (password, userIsAnyStaff) {
    var _a;
    if (userIsAnyStaff === void 0) { userIsAnyStaff = false; }
    return (_a = {},
        _a[PasswordRequirement.Length] = password.length >= exports.MIN_PASSWORD_LENGTH,
        _a[PasswordRequirement.MixedCase] = !userIsAnyStaff || !!(password.match(/[a-z]/) && password.match(/[A-Z]/)),
        _a[PasswordRequirement.LettersAndNumbers] = !userIsAnyStaff ||
            !!(password.match(/[a-zA-Z]/) && password.match(/[0-9]/)),
        _a[PasswordRequirement.SpecialCharacters] = !userIsAnyStaff || !!password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/),
        _a);
};
exports.validatePasswordRequirements = validatePasswordRequirements;
var validPassword = function (_a) {
    var password = _a.password, passwordConfirm = _a.passwordConfirm, _b = _a.userIsAnyStaff, userIsAnyStaff = _b === void 0 ? false : _b;
    return (passwordConfirm === undefined || password === passwordConfirm) &&
        Object.values((0, exports.validatePasswordRequirements)(password, userIsAnyStaff)).reduce(function (isValid, validRequirement) { return isValid && validRequirement; }, true);
};
exports.validPassword = validPassword;
