import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonType } from 'stories/button';
import ActionModal from '../action.modal';
import Text from '../text';

import styles from './styles.scss';

export default function SimpleActionModal({
  onCancel,
  onCancelText,
  onApprove,
  onApproveText,
  children,
  title,
  isWarning,
  isLoading,
  disableSubmit,
  ...rest
}) {
  return (
    <ActionModal
      {...rest}
      title={title}
      contentLabel={title}
      data-simple-modal
      onCancel={() => onCancel()}
      onCancelText={onCancelText}
      buttons={[
        <Button
          key="approve"
          className={styles.approveButton}
          type={isWarning ? ButtonType.permanent : ButtonType.required}
          onClick={onApprove}
          loading={isLoading}
          disabled={disableSubmit}
          data-action-modal-approve
        >
          {onApproveText}
        </Button>,
      ]}
    >
      <Text align="center">{children}</Text>
    </ActionModal>
  );
}

SimpleActionModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onApproveText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCancelText: PropTypes.string,
  isWarning: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableSubmit: PropTypes.bool,
};

SimpleActionModal.defaultProps = {
  isWarning: false,
  isLoading: false,
  disableSubmit: false,
  onApproveText: 'Continue',
  onCancelText: 'Cancel',
};
