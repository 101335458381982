import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonType,
  FlexContainer,
  IconType,
} from 'web/components/primitives';

import styles from './styles.scss';

export default function LeftRightPager({ onLeft, onRight }) {
  return (
    <FlexContainer alignItems="center">
      <Button
        disabled={!onLeft}
        type={ButtonType.caution}
        className={styles.pagerBtn}
        symbolIcon={IconType.chevronLeft}
        onClick={onLeft}
      />
      <Button
        disabled={!onRight}
        type={ButtonType.caution}
        className={styles.pagerBtn}
        symbolIcon={IconType.chevronRight}
        onClick={onRight}
      />
    </FlexContainer>
  );
}

LeftRightPager.propTypes = {
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
};
LeftRightPager.defaultProps = {
  onLeft: undefined,
  onRight: undefined,
};
