import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export default function ButtonSelect({
  children,
  toggleable,
  selected,
  onChange,
  className,
  ...rest
}) {
  return (
    <button
      type="button"
      className={classNames(styles.filter, {
        [styles.selected]: toggleable && selected,
        [className]: !!className,
      })}
      onClick={() => onChange(!selected)}
      {...rest}
    >
      <FlexContainer justifyContent="center" alignItems="center">
        {children}
      </FlexContainer>
    </button>
  );
}

ButtonSelect.propTypes = {
  children: PropTypes.string.isRequired,
  toggleable: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

ButtonSelect.defaultProps = {
  toggleable: true,
  selected: false,
  className: undefined,
  onChange: undefined,
};
