import React, { useRef, useState, useEffect } from 'react';
import { find, map, merge, omit, size } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Icon, IconType } from 'stories';
import FileInputButton from 'web/components/primitives/file.input.button';
import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export default function ImageSelect({ onChange, imageSize, images }) {
  const [internalImages, setInternalImages] = useState({});
  const fileRef = useRef();

  useEffect(() => {
    const savedImages = images.filter(img => img._id);
    if (savedImages.length) {
      setInternalImages(
        savedImages.reduce(
          (obj, img) => ({
            ...obj,
            [img._id]: { thumbnail: img.thumbUrl, name: img.name },
          }),
          {},
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPhotos = e => {
    if (!(e.target.files || []).length) {
      return;
    }
    let fileObj = {};
    const thumbnailPromises = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      const file = e.target.files[i];
      const fileKey = `${file.name}-${file.size}`;
      fileObj = { ...fileObj, [fileKey]: { file } };
      thumbnailPromises.push(
        // eslint-disable-next-line no-loop-func
        new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve({ [fileKey]: { thumbnail: reader.result } });
        }),
      );
    }
    Promise.all(thumbnailPromises).then(thumbnails => {
      const thumbnailObj = thumbnails.reduce(
        (obj, image) => ({ ...obj, ...image }),
        {},
      );

      const newImages = { ...internalImages, ...merge(fileObj, thumbnailObj) };
      setInternalImages(newImages);
      onChange(map(newImages, (img, _id) => img.file || find(images, { _id })));
    });
  };

  const onRemovePhoto = key => () => {
    const newImages = omit(internalImages, key);
    setInternalImages(newImages);
    onChange(map(newImages, (img, _id) => img.file || find(images, { _id })));
  };

  return (
    <div>
      {!!size(internalImages) && (
        <FlexContainer wrap className={styles.container}>
          {map(internalImages, ({ thumbnail }, key) => (
            <FlexContainer
              key={key}
              alignItems="center"
              justifyContent="center"
              className={styles.imageContainer}
              style={{
                width: imageSize,
                height: imageSize,
                backgroundImage: `url(${thumbnail})`,
              }}
            >
              <div
                className={styles.removeContainer}
                onClick={onRemovePhoto(key)}
              >
                <Icon size={16} type={IconType.x} className={styles.remove} />
              </div>
            </FlexContainer>
          ))}
        </FlexContainer>
      )}
      <FileInputButton
        ref={fileRef}
        onChange={onAddPhotos}
        photosOnly
        multiple
      />
      <Button
        symbolIcon={IconType.camera}
        onClick={() => fileRef.current.click()}
      >
        Add Images
      </Button>
    </div>
  );
}

ImageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  imageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      thumbUrl: PropTypes.string,
    }),
  ),
};

ImageSelect.defaultProps = {
  imageSize: '8rem',
  images: [],
};
