import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FlexContainer,
  Input,
  SvgIcon,
  SvgIconType,
  SvgIconSize,
} from 'web/components/primitives';

import style from './style.scss';

export default function RowItem({
  isError,
  columns,
  value,
  onChange,
  onDelete,
}) {
  const [state, setState] = useState({});

  const onSubmit = itemId => {
    if (state.key && state.value[state.key] !== state.value) {
      onChange(itemId, state.key, state.value);
    }
    setState({});
  };

  return (
    <FlexContainer alignItems="center" className={style.row}>
      {columns.map(({ type, key, width, inputProps }) => {
        let additional = {};
        let options = [];
        const baseValue =
          state.key === key && state.value !== undefined
            ? state.value
            : value[key];

        if (type === 'dropdown') {
          additional = { isDisabled: true };
          if (Array.isArray(baseValue) && inputProps.isMulti) {
            options = baseValue.map(v => ({ value: v, label: v }));
          } else {
            options = inputProps.options;
          }
        }

        return (
          <Input
            key={key}
            type={type}
            isError={isError}
            className={style.item}
            onChange={e =>
              setState({
                key,
                value: e.target ? e.target.value : e.value,
              })
            }
            onBlur={() => onSubmit(value.key)}
            value={baseValue}
            style={{ width }}
            {...inputProps}
            {...additional}
            options={options}
          />
        );
      })}
      <SvgIcon
        data-delete-row-item={value.name || true}
        type={SvgIconType.close}
        size={SvgIconSize.small}
        onClick={() => onDelete(value.key)}
        selectable
      />
    </FlexContainer>
  );
}

RowItem.propTypes = {
  isError: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      width: PropTypes.number,
      inputProps: PropTypes.shape(),
    }),
  ).isRequired,
  value: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    abbreviation: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
