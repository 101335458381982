import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style.scss';

import HeaderBar from '../header.bar';

export default function FooterBar({ className, ...rest }) {
  return (
    <HeaderBar className={classNames(style.footer, className)} {...rest} />
  );
}

FooterBar.propTypes = {
  className: PropTypes.string,
};

FooterBar.defaultProps = {
  className: null,
};
