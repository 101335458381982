import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexContainer from 'web/components/primitives/flex-container';
import styles from './styles.scss';

export default function Card({
  children,
  className,
  hoverable,
  selected,
  ...rest
}) {
  return (
    <FlexContainer
      alignItems="center"
      className={classNames(styles.card, className, {
        [styles['card--hoverable']]: hoverable,
        [styles['card--selected']]: selected,
      })}
      {...rest}
    >
      {children}
    </FlexContainer>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  hoverable: false,
  selected: false,
  className: null,
};
