const { includes, uniqBy } = require('lodash');
const moment = require('moment');
require('moment-recur');

const {
  normalizeDateTimestamp,
  combineTimeStringAndDate,
  RECUR_DATE,
} = require('./time.util');
const {
  MAP_WEEK_OF_MONTH,
  INTERVALS,
  MONTH_YEAR_FORMAT,
  MIN_RECURRING_DATE,
} = require('../constants/recurring');

const LAST = 'last';
const MAX_LAST_DATE = moment().add(3, 'years');

const recurringEventKey = event => {
  if (event.recurring) {
    return `${event._id}*${event.startDate}`;
  }
  return event._id;
};

const createInstanceDates = ({
  pattern,
  lastDate,
  startDate,
  exceptions = [],
}) => {
  try {
    const end = moment(lastDate);
    const start = moment(startDate);

    if (!pattern || !lastDate || !startDate || start.isAfter(end)) {
      return [];
    }

    const normalizedStart = MIN_RECURRING_DATE.isAfter(start)
      ? MIN_RECURRING_DATE.clone()
      : start;
    const normalizedEnd = MAX_LAST_DATE.isBefore(end)
      ? MAX_LAST_DATE.clone()
      : end;
    const except = exceptions.map(date =>
      moment(date.replace(/\//g, '-'), 'MM-DD-YYYY').format('L'),
    );

    const patternArray = pattern.split('/');
    const interval = patternArray[0];
    let refilterMonths = false;
    let every = patternArray.length > 1 ? patternArray[1] : 1;
    if (every === '0') {
      every = 1;
    }

    const recurrence = moment
      .recur()
      .startDate(normalizedStart)
      .endDate(normalizedEnd)
      .every(every);

    if (interval === INTERVALS.weekly) {
      recurrence.weeks();
      if (patternArray.length > 2) {
        recurrence.every(patternArray[2].split(',')).daysOfWeek();
      }
    } else if (interval === INTERVALS.monthly && patternArray.length < 3) {
      recurrence.months();
    } else if (interval === INTERVALS.monthly) {
      // A weekOfMonthByDay interval is available for combining with
      // the daysOfWeek to achieve "nth weekday of month" recurrences.
      // The following matches every 1st and 3rd Thursday of the month.
      // (Note this cannot be combined at the moment with every(x).months() expression)

      refilterMonths = true;
      const dayOfWeek = patternArray[3];
      const skipInterval = MAP_WEEK_OF_MONTH[patternArray[2]];
      recurrence
        .every(dayOfWeek)
        .daysOfWeek()
        .every(skipInterval)
        .weeksOfMonthByDay();
    } else if (interval === INTERVALS.annual) {
      recurrence.every(1).years();
    } else {
      recurrence.days();
    }

    let dates = recurrence.all('L');
    // possible moment-recur bug on server where startDate is not generated
    if (interval === INTERVALS.daily) {
      dates = uniqBy([recurrence.startDate().format('L'), ...dates]);
    }

    if (refilterMonths) {
      const acceptableMonths = moment
        .recur({
          start: normalizedStart.startOf('month'),
          end: normalizedEnd.endOf('month'),
        })
        .every(every)
        .months()
        .all('L')
        .map(date => moment(date, RECUR_DATE).format(MONTH_YEAR_FORMAT));
      dates = dates.filter(date => {
        const dateMoment = moment(date, RECUR_DATE);
        const monthYear = dateMoment.format(MONTH_YEAR_FORMAT);
        if (!includes(acceptableMonths, monthYear)) {
          return false;
        }
        if (patternArray[2] === LAST) {
          const oneWeekLater = dateMoment.clone().add(1, 'week');
          return monthYear !== oneWeekLater.format(MONTH_YEAR_FORMAT);
        }
        return true;
      });
    }

    return dates.filter(date => !includes(except, date));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, pattern, lastDate, startDate);
    return [];
  }
};

const createInstances = (model, start, end) => {
  const { _id, recurring, deletedAt, startTime, endTime } = model;
  const lastDate = moment.min(
    [
      end,
      deletedAt && moment(deletedAt, moment.ISO_8601),
      moment(recurring.lastDate, moment.ISO_8601),
    ].filter(date => !!date),
  );
  return createInstanceDates({ ...recurring, lastDate })
    .filter(date => moment(date, 'L').isBetween(start, end, 'day', '[]'))
    .map(instanceDate => {
      const normalizedDate = normalizeDateTimestamp(
        moment.utc(instanceDate, 'L'),
      );
      const key = `${_id}*${normalizedDate}`;
      const instance = {
        ...model,
        key,
        startDate: normalizedDate,
        endDate: normalizedDate,
      };
      if (!startTime || !endTime) {
        return instance;
      }
      return {
        ...instance,
        start: combineTimeStringAndDate(startTime, normalizedDate),
        end: combineTimeStringAndDate(endTime, normalizedDate),
      };
    });
};

const buildPattern = ({
  pattern,
  every,
  weeklyDayOfWeek,
  monthlyPattern,
  dayOfWeek,
}) => {
  let newPattern = `${pattern}/${every}`;
  if (pattern === INTERVALS.weekly) {
    newPattern += `/${weeklyDayOfWeek.join(',')}`;
  } else if (pattern === INTERVALS.monthly) {
    newPattern += `/${monthlyPattern}/${dayOfWeek}`;
  }
  return newPattern;
};

const generateOccurrences = patternObject => {
  const { startDate, lastDate, pattern } = patternObject;
  let occurrences = [];
  const yearOut = moment(startDate).add(1, 'year');
  const lastDateMoment = moment(lastDate);
  if (pattern !== INTERVALS.none) {
    occurrences = createInstanceDates({
      pattern: buildPattern(patternObject),
      startDate,
      lastDate: lastDateMoment.isBefore(yearOut) ? lastDateMoment : yearOut,
    }).map(date => new Date(date));
  }
  return occurrences;
};

const createCustomPatternObject = (
  recurring,
  selectedDate,
  maxDate = moment().add(25, 'years'),
) => {
  const selectedMoment = moment(selectedDate);
  let today = selectedMoment.format('dddd').toLowerCase();
  if (!recurring) {
    return {
      pattern: INTERVALS.none,
      every: '1',
      weeklyDayOfWeek: [today],
      dayOfWeek: today,
      monthlyPattern: 'first',
      startDate: selectedMoment.toDate(),
      lastDate: selectedMoment
        .clone()
        .add(2, 'years')
        .toDate(),
    };
  }
  const { pattern, startDate, lastDate } = recurring;
  const startMoment = moment(startDate || selectedMoment);
  today = startMoment.format('dddd').toLowerCase();
  let lastMoment = moment(lastDate || startMoment.clone().add(2, 'years'));
  if (lastMoment.isAfter(maxDate)) {
    lastMoment = moment(maxDate);
  }
  const result = {
    pattern: pattern || INTERVALS.none,
    every: '1',
    weeklyDayOfWeek: [today],
    dayOfWeek: today,
    monthlyPattern: 'first',
    startDate: startMoment.toDate(),
    lastDate: lastMoment.toDate(),
  };
  if (pattern && pattern.length > 0) {
    const customPattern = pattern.split('/');
    result.pattern = customPattern[0];
    if (customPattern.length > 1) {
      result.every = customPattern[1];
    }
    if (customPattern.length > 2 && result.pattern === INTERVALS.weekly) {
      result.weeklyDayOfWeek = customPattern[2].split(',');
    } else if (
      customPattern.length > 2 &&
      result.pattern === INTERVALS.monthly
    ) {
      result.monthlyPattern = customPattern[2];
      if (customPattern.length > 3) {
        result.dayOfWeek = customPattern[3];
      }
    }
  }
  return result;
};

const hasInstanceOnDate = (model, compareDate = moment()) =>
  !!generateOccurrences(model.recurring)
    .map(date => moment(date))
    .filter(date => date.isSame(compareDate, 'day')).length;

module.exports = {
  recurringEventKey,
  createInstanceDates,
  createInstances,
  buildPattern,
  generateOccurrences,
  createCustomPatternObject,
  hasInstanceOnDate,
};
