import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FlexContainer from '../flex-container';
import styles from './styles.scss';

export default function SectionHeader({
  title,
  children,
  className,
  divider,
  subtle,
  joined,
}) {
  let content = children;
  if (title) {
    content = (
      <FlexContainer flex={1} alignItems="center" justifyContent="flexEnd">
        {children}
      </FlexContainer>
    );
  }
  return (
    <FlexContainer
      className={classNames(styles.container, className, {
        [styles.divider]: divider,
        [styles.joined]: joined,
      })}
      alignItems="center"
      justifyContent="spaceBetween"
    >
      {title && (
        <div className={classNames(styles.title, { [styles.subtle]: subtle })}>
          {title}
        </div>
      )}
      {content}
    </FlexContainer>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  divider: PropTypes.bool,
  subtle: PropTypes.bool,
  joined: PropTypes.bool,
};
SectionHeader.defaultProps = {
  title: '',
  children: null,
  className: null,
  divider: false,
  subtle: false,
  joined: false,
};
