import React from 'react';
import { toNumber } from 'lodash';
import PropTypes from 'prop-types';
import Input from 'stories/input';
import FlexContainer from '../flex-container';
import Text from '../text';
import styles from './styles.scss';

export default function DurationInput({ duration, onChange }) {
  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;
  return (
    <FlexContainer alignItems="center">
      <FlexContainer
        className={styles.column}
        flexDirection="column"
        alignItems="flexStart"
      >
        <Text meta size="0.9rem">
          Minutes
        </Text>
        <Input
          min={0}
          step={1}
          type="number"
          value={minutes}
          onChange={e =>
            onChange(Math.round(toNumber(e.target.value) * 60 + seconds))
          }
        />
      </FlexContainer>
      <FlexContainer
        className={styles.column}
        flexDirection="column"
        alignItems="flexStart"
      >
        <Text meta size="0.9rem">
          Seconds
        </Text>
        <Input
          min={0}
          max={59}
          step={1}
          type="number"
          value={seconds}
          onChange={e =>
            onChange(Math.round(minutes * 60 + toNumber(e.target.value)))
          }
        />
      </FlexContainer>
    </FlexContainer>
  );
}
DurationInput.propTypes = {
  duration: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
