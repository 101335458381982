import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSpacer } from 'shared/utils/effects';
import classStyle from './style.scss';

function FlexContainer({
  forwardedRef,
  alignItems,
  className,
  justifyContent,
  flexDirection,
  keepHeight,
  noShrink,
  growToContainer,
  wrap,
  scroll,
  flex,
  style,
  title,
  ...rest
}) {
  const spacing = useSpacer(rest);
  const styleObj = { ...spacing, ...style };
  if (flex) {
    styleObj.flex = flex;
  }
  const direction = keepHeight ? flexDirection || 'column' : flexDirection;
  const cleanedTitle = typeof title === 'boolean' ? undefined : title;
  return (
    <div
      {...rest}
      title={cleanedTitle}
      style={styleObj}
      ref={forwardedRef}
      className={classNames(classStyle.flexContainer, className, {
        [classStyle[`alignItems--${alignItems}`]]: alignItems,
        [classStyle[`justifyContent--${justifyContent}`]]: justifyContent,
        [classStyle[`flexDirection--${direction}`]]: direction,
        [classStyle.keepHeight]: keepHeight,
        [classStyle.noShrink]: noShrink,
        [classStyle.growToContainer]: growToContainer,
        [classStyle.wrap]: wrap,
        [classStyle.scroll]: scroll,
      })}
    />
  );
}

FlexContainer.propTypes = {
  forwardedRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  className: PropTypes.string,
  alignItems: PropTypes.oneOf([
    'stretch',
    'center',
    'flexStart',
    'flexEnd',
    'baseline',
    'initial',
    'inherit',
  ]),
  justifyContent: PropTypes.oneOf([
    'flexStart',
    'flexEnd',
    'center',
    'spaceBetween',
    'spaceAround',
    'initial',
    'inherit',
  ]),
  flexDirection: PropTypes.oneOf(['row', 'column']),
  keepHeight: PropTypes.bool,
  noShrink: PropTypes.bool,
  growToContainer: PropTypes.bool,
  wrap: PropTypes.bool,
  scroll: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
};

FlexContainer.defaultProps = {
  forwardedRef: null,
  className: null,
  alignItems: null,
  justifyContent: null,
  flexDirection: null,
  keepHeight: false,
  noShrink: false,
  growToContainer: false,
  wrap: false,
  scroll: false,
  flex: null,
  style: {},
};

export default React.forwardRef((props, ref) => (
  <FlexContainer {...props} forwardedRef={ref} />
));
