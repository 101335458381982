import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'stories/icon';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export default function TransportItinerary({
  departureTime,
  appointmentTime,
  returnTime,
}) {
  return (
    <FlexContainer noShrink alignItems="center">
      <FlexContainer
        noShrink
        alignItems="center"
        flexDirection="column"
        className={styles.group}
      >
        <div className={styles.labelText}>Departure</div>
        <div className={classNames(styles.time, { [styles.finished]: true })}>
          {departureTime || '--'}
        </div>
      </FlexContainer>
      <Icon type="ArrowRight" className={styles.arrow} />
      <FlexContainer
        noShrink
        alignItems="center"
        flexDirection="column"
        className={styles.group}
      >
        <div className={styles.labelText}>Appointment</div>
        <div className={styles.time}>{appointmentTime || '--'}</div>
      </FlexContainer>
      <Icon type="ArrowRight" className={styles.arrow} />
      <FlexContainer
        noShrink
        alignItems="center"
        flexDirection="column"
        className={styles.group}
      >
        <div className={styles.labelText}>Return</div>
        <div className={styles.time}>{returnTime || '--'}</div>
      </FlexContainer>
    </FlexContainer>
  );
}

TransportItinerary.propTypes = {
  departureTime: PropTypes.string,
  appointmentTime: PropTypes.string,
  returnTime: PropTypes.string,
};

TransportItinerary.defaultProps = {
  departureTime: undefined,
  appointmentTime: undefined,
  returnTime: undefined,
};
