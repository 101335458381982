import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { keys } from 'lodash';

import { Icon, IconType } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';
import Text from 'web/components/primitives/text';
import SIZES from 'shared/constants/sizes';

import styles from './styles.scss';

export const MessageAlertType = {
  success: 'success',
  important: 'important',
  info: 'info',
  caution: 'caution',
  warning: 'warning',
  error: 'error',
};

const getSizes = size => {
  const mediumSize = {
    iconSize: 24,
    fontSize: 14,
  };

  switch (size) {
    case SIZES.SMALL:
      return {
        iconSize: 12,
        fontSize: 12,
      };
    case SIZES.MEDIUM:
      return mediumSize;
    case SIZES.LARGE:
      return {
        iconSize: 36,
        fontSize: 20,
      };
    default:
      return mediumSize;
  }
};

export default function MessageAlert({
  className,
  children,
  type,
  iconType,
  noPadding,
  size,
  customIcon,
  ...rest
}) {
  const { iconSize, fontSize } = getSizes(size);
  const NewIcon = customIcon;
  return (
    <FlexContainer
      noShrink
      className={classNames(styles.messageAlert, className, {
        [styles['messageAlert--noPadding']]: noPadding,
      })}
      {...rest}
    >
      <FlexContainer
        alignItems="center"
        className={classNames(
          styles.iconContainer,
          styles[`iconContainer--${type}`],
        )}
      >
        {!customIcon && <Icon type={iconType} size={iconSize} />}
        {customIcon && <NewIcon size={iconSize} />}
      </FlexContainer>
      <Text size={fontSize} className={styles.childrenContainer}>
        {children}
      </Text>
    </FlexContainer>
  );
}

MessageAlert.propTypes = {
  className: PropTypes.string,
  customIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(
    keys(MessageAlertType).map(key => MessageAlertType[key]),
  ),
  noPadding: PropTypes.bool,
  iconType: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

MessageAlert.defaultProps = {
  className: undefined,
  type: MessageAlertType.info,
  noPadding: false,
  iconType: IconType.alertCircle,
  size: SIZES.MEDIUM,
};
