import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

ReactModal.setAppElement('body');
const modalStyle = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: 0,
    position: 'static',
    overflow: 'visible',
    border: 'none',
    borderRadius: 4,
  },
};

export default function Modal(props) {
  const { width, height, children, ...rest } = props;
  return (
    <ReactModal
      closeTimeoutMS={150}
      {...rest}
      style={{
        ...modalStyle,
        content: {
          ...modalStyle.content,
          width,
          height,
        },
      }}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Modal.defaultProps = {
  width: 450,
  height: null,
};
