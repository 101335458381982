/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { VIEW_PERIODS, getViewPeriodInfo } from 'shared/utils/calendar.util';
import Text from 'web/components/primitives/text';

import styles from './styles.scss';

export default function CalendarHeader({
  viewPeriod,
  selectedDate,
  changeSelectedDate,
  headerFormat,
}) {
  if (viewPeriod === VIEW_PERIODS.days.key) {
    return (
      <div className={styles.dayGridHeader}>
        {moment(selectedDate).format('dddd, MMMM Do')}
      </div>
    );
  }
  const { days } = getViewPeriodInfo(VIEW_PERIODS.weeks.key, selectedDate);
  return days.map(day => {
    if (viewPeriod === VIEW_PERIODS.months.key) {
      return (
        <div className={styles.headerLabel} key={day.format('L')}>
          {day.format(headerFormat)}
        </div>
      );
    }
    if (viewPeriod === VIEW_PERIODS.weeks.key) {
      return (
        <div className={styles.weekHeaderItem} key={day.format('L')}>
          <div
            className={classNames(styles.weekday, {
              [styles.isSelected]: day.format('L') === selectedDate.format('L'),
            })}
            onClick={() => changeSelectedDate(day)}
          >
            {day.format('D')}
          </div>
          <Text bold>{day.format(headerFormat)}</Text>
        </div>
      );
    }
    return null;
  });
}

CalendarHeader.propTypes = {
  viewPeriod: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  changeSelectedDate: PropTypes.func.isRequired,
  headerFormat: PropTypes.string,
};

CalendarHeader.defaultProps = {
  headerFormat: 'dddd',
};
