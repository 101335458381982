import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoContainer from 'web/components/public/info-container';
import {
  Button,
  ButtonType,
  FlexContainer,
  FormRow,
  Input,
  PasswordRequirements,
  Text,
} from 'web/components/primitives';

import { postAcceptMasterServiceAgreementTerms } from 'shared/api/session';
import USER_TYPES from 'shared/constants/user.types';
import style from 'web/components/admin/manage.admin.users/form/styles.scss';
import { validPassword } from 'shared/utils/password.util';
import styles from './styles.scss';

export default function MasterServiceAgreementTerms({ match }) {
  const {
    communitiesRequiringMasterServiceAgreements,
    tenantForTerms,
    userType,
  } = window.clientConfig;
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const updateForm = changes => setForm({ ...form, ...changes });

  const isValid =
    form.firstName &&
    form.lastName &&
    form.email &&
    validPassword({
      password: form.newPassword,
      passwordConfirm: form.confirmPassword,
    });
  const userCanAcceptTerms = [USER_TYPES.staff, USER_TYPES.enterprise].includes(
    userType,
  );
  if (!userCanAcceptTerms) {
    return (
      <InfoContainer title="LifeLoop Unavailable">
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Text mb={16}>LifeLoop is not yet available for your community.</Text>
          <Text mb={16}>
            Please ask your community&apos;s administrator to complete the
            LifeLoop setup process.
          </Text>
          <Button
            onClick={() => {
              window.location = `/${tenantForTerms}/logout`;
            }}
          >
            Log Out
          </Button>
        </FlexContainer>
      </InfoContainer>
    );
  }
  return (
    <InfoContainer title="Master Services Agreement">
      <Text mb={16}>
        Please read the following and press accept to continue.
      </Text>
      <Text mb={16}>
        If you are not authorized to accept these terms on behalf of the
        campuses below, you should not continue. Please log out and ask the
        appropriate party to complete this step and accept the terms of the
        LifeLoop Master Services Agreement.
      </Text>
      <FlexContainer justifyContent="center" mb={4}>
        <Button
          onClick={() => {
            window.location = `/${tenantForTerms}/logout`;
          }}
        >
          Log Out
        </Button>
      </FlexContainer>
      <a href="https://lifeloop.com/msa" target="_blank" rel="noreferrer">
        Open LifeLoop Master Services agreement
      </a>
      <div className={styles.campuses}>
        <Text>Accepting MSA for the following campuses:</Text>
        <ol>
          {communitiesRequiringMasterServiceAgreements.map(community => (
            <li key={community._id}>
              <Text>{community.name}</Text>
            </li>
          ))}
        </ol>
      </div>
      <FlexContainer
        flexDirection="column"
        alignItems="stretch"
        className={styles.form}
      >
        <Text>
          The following information will be used to update the default user:
        </Text>
        <FormRow label="First Name" isRequired>
          <Input
            maxLength={50}
            value={form.firstName}
            onChange={e => updateForm({ firstName: e.target.value })}
            placeholder="First Name"
          />
        </FormRow>
        <FormRow label="Last Name" isRequired>
          <Input
            maxLength={50}
            value={form.lastName}
            onChange={e => updateForm({ lastName: e.target.value })}
            placeholder="Last Name"
          />
        </FormRow>
        <FormRow label="Email" isRequired>
          <Input
            maxLength={50}
            value={form.email}
            onChange={e => updateForm({ email: e.target.value })}
            placeholder="Email"
          />
        </FormRow>
        <FormRow label="New Password" isRequired>
          <Input
            type="password"
            maxLength={50}
            value={form.newPassword}
            onChange={e => updateForm({ newPassword: e.target.value })}
            placeholder="New Password"
          />
        </FormRow>
        <FormRow label="Confirm Password" isRequired>
          <Input
            type="password"
            maxLength={50}
            value={form.confirmPassword}
            onChange={e => updateForm({ confirmPassword: e.target.value })}
            placeholder="New Password"
          />
        </FormRow>
        <PasswordRequirements
          userIsAnyStaff
          className={style.requirements}
          password={form.newPassword}
          passwordConfirm={form.confirmPassword}
        />
      </FlexContainer>
      <p>All inquiries may be directed to:</p>
      LifeLoop
      <br />
      5889 Greenwood Plaza Blvd, Suite 210
      <br />
      Greenwood Village, CO 80111
      <br />
      <a href="mailto:support@lifeloop.com">customerservices@lifeloop.com</a>
      <p>
        <Button
          id="accept-terms"
          disabled={!isValid}
          type={ButtonType.required}
          onClick={
            () =>
              postAcceptMasterServiceAgreementTerms({
                baseUrl: window.clientConfig.baseUrl,
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                password: form.newPassword,
              })
                .then(() => {
                  window.location.href = `/${match.params.tenant}`;
                })
                .catch(console.error) // eslint-disable-line
          }
        >
          Accept MSA Terms
        </Button>
      </p>
    </InfoContainer>
  );
}

MasterServiceAgreementTerms.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ tenant: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};
