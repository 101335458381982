import React from 'react';
import PropTypes from 'prop-types';

import Button from 'stories/button';

import FlexContainer from '../flex-container';
import SidebarContainer from './container';
import Link from './link';
import styles from './styles.scss';

export default function Sidebar({
  links,
  onChange,
  selected,
  actions,
  renderActions,
}) {
  return (
    <SidebarContainer>
      <FlexContainer
        data-qa-links-container
        className={styles.linksContainer}
        flexDirection="column"
      >
        {links.map(link => (
          <Link
            key={link.route}
            {...link}
            onChange={onChange}
            selected={selected}
          />
        ))}
      </FlexContainer>
      {!!renderActions && renderActions()}
      <FlexContainer flexDirection="column" justifyContent="flexEnd">
        {actions.map(({ name, onClick, type }) => (
          <Button
            key={name}
            onClick={onClick}
            className={styles.actionBtn}
            type={type}
          >
            {name}
          </Button>
        ))}
      </FlexContainer>
    </SidebarContainer>
  );
}

Sidebar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      name: PropTypes.string,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      type: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  selected: PropTypes.string,
  renderActions: PropTypes.func,
};

Sidebar.defaultProps = {
  selected: undefined,
  onChange: undefined,
  renderActions: undefined,
  actions: [],
};
