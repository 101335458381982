import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { camelCase, isString } from 'lodash';

import FlexContainer from 'web/components/primitives/flex-container';
import Label from 'web/components/primitives/label';

import style from './style.scss';

export default function FormRow({
  label,
  children,
  className,
  isError,
  isRequired,
  isDisabled,
  noMargin,
  icon,
  ...props
}) {
  let labelComponent = label;
  if (isString(label)) {
    labelComponent = (
      <Label isError={isError} isDisabled={isDisabled} isRequired={isRequired}>
        {label}
      </Label>
    );
  }
  return (
    <FlexContainer
      flexDirection="column"
      className={classNames(style.container, className, {
        [style['container--noMargin']]: noMargin,
      })}
      noShrink
      {...props}
      data-qa-form={`${camelCase(label)}-${isError}`}
    >
      <div className={style.labelContainer}>
        {labelComponent}
        {icon}
      </div>
      <div className={style.fullWidth}>{children}</div>
    </FlexContainer>
  );
}

FormRow.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  icon: PropTypes.node,
  children: PropTypes.node,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  noMargin: PropTypes.bool,
};

FormRow.defaultProps = {
  className: null,
  label: '',
  children: null,
  isError: false,
  isDisabled: false,
  isRequired: false,
  noMargin: false,
};
