import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonSelect from 'stories/button.select';
import Icon, { IconType } from 'stories/icon';

import FlexContainer from 'web/components/primitives/flex-container';

import classnames from 'classnames';
import styles from './styles.scss';

export default function InlineQuickFilters({
  options,
  selectedFilters,
  onChange,
  className,
  collapsed,
}) {
  const [showAllOptions, setShowAllOptions] = useState(!selectedFilters.length);

  useEffect(() => {
    setShowAllOptions(!selectedFilters.length);
  }, [selectedFilters]);

  const updateSelectedFilters = option => {
    if (selectedFilters.includes(option.value)) {
      return onChange(selectedFilters.filter(value => value !== option.value));
    }
    setShowAllOptions(false);
    return onChange([...selectedFilters, option.value]);
  };

  const composeFilterButton = option => (
    <ButtonSelect
      className={classnames({
        [styles.collapsedBtn]: collapsed,
      })}
      onClick={() => updateSelectedFilters(option)}
      toggleable
      selected={selectedFilters.includes(option.value)}
      key={option.value}
    >
      {option.label}
    </ButtonSelect>
  );

  const selectedFilterButtons = options
    .filter(option => selectedFilters.includes(option.value))
    .map(composeFilterButton);

  const unselectedFilterButtons = options
    .filter(
      option =>
        !selectedFilters.length ||
        (!selectedFilters.includes(option.value) &&
          (showAllOptions || selectedFilters.length + 1 === options.length)),
    )
    .map(composeFilterButton);

  const isShowingAddFiltersButton =
    !showAllOptions &&
    !!selectedFilters.length &&
    selectedFilters.length + 1 < options.length;

  return (
    <FlexContainer
      flexDirection="row"
      className={classnames(styles.inlineQuickFilters, className, {
        [styles.noScroll]:
          !selectedFilterButtons.length && !unselectedFilterButtons.length,
        [styles.collapsed]: collapsed,
      })}
    >
      {selectedFilters.length > 0 && (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          className={styles.removeAll}
          onClick={() => onChange([])}
        >
          <Icon type={IconType.x} />
        </FlexContainer>
      )}
      <FlexContainer flexDirection="row" alignItems="center">
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          className={styles.filterGroup}
        >
          {selectedFilterButtons}
          {unselectedFilterButtons}
          {isShowingAddFiltersButton && (
            <ButtonSelect
              toggleable={false}
              onClick={() => setShowAllOptions(true)}
              className={classnames({
                [styles.collapsedBtn]: collapsed,
              })}
            >
              Add Filter(s)
            </ButtonSelect>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}

InlineQuickFilters.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
