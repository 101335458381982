import React from 'react';
import styles from './styles.scss';

export default function EndedLabel({ activityName }) {
  return (
    <div className={styles.ended} data-qa-ended-label={activityName}>
      ENDED
    </div>
  );
}
