import React, { useEffect, useRef, useState } from 'react';
import { includes, values } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon, { IconType } from 'stories/icon';
import Button from 'stories/button';

import FlexContainer from '../flex-container';
import PopupDrawer from '../popup.drawer';
import SvgIcon from '../svg.icon';
import Text from '../text';

import styles from './styles.scss';

export default function ButtonDropdown({
  links,
  children,
  loading,
  name,
  actionIcon,
  symbolIcon,
  isOpen,
  onClick,
  mini,
  top,
  right,
  onToggle,
  ...rest
}) {
  const attribute = `data-${name}`;
  const [isVisible, setIsVisible] = useState(isOpen);

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
        onToggle(false);
        event.stopPropagation();
      }
    }
    // The popupDrawer component stops propagation of mousedown,
    //  So if we trigger on mousedown then this event doesn't trigger when a user clicks on a popupDrawer
    //  Triggering on mouseup avoids this issue
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [onToggle, isVisible]);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  let button = null;
  const onClickTarget = e => {
    onClick();
    e.stopPropagation();
    setIsVisible(!isVisible);
    onToggle(!isVisible);
  };
  if (children) {
    button = (
      <span ref={ref}>
        <Button
          loading={loading}
          symbolIcon={symbolIcon}
          actionIcon={
            actionIcon || (children ? IconType.chevronDown : undefined)
          }
          mini={mini}
          onClick={onClickTarget}
          {...{ [attribute]: true }}
          {...rest}
        >
          {children}
        </Button>
      </span>
    );
  } else if (symbolIcon || actionIcon) {
    const iconType = symbolIcon || actionIcon;
    button = (
      <span ref={ref}>
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          onClick={onClickTarget}
          className={styles.targetIcon}
          px={1}
        >
          <Icon type={iconType} size={22} {...{ [attribute]: true }} />
        </FlexContainer>
      </span>
    );
  }

  return (
    <PopupDrawer
      useDarkShadow
      isShowing={isVisible && !!links?.length}
      hidePopup={() => setIsVisible(false)}
      showPopup={() => setIsVisible(true)}
      top={mini ? '-10px' : top}
      right={right}
      button={button}
    >
      <div
        className={classNames(styles.dropdownContent, {
          [styles['dropdownContent--loading']]: loading,
        })}
      >
        <FlexContainer flexDirection="column">
          {links?.map(link => {
            if (React.isValidElement(link)) {
              return link;
            }

            let icon;
            if (link.icon) {
              icon = includes(values(IconType), link.icon) ? (
                <Icon
                  type={link.icon}
                  size={18}
                  className={styles.actionIcon}
                />
              ) : (
                <SvgIcon
                  type={link.icon}
                  className={styles.actionIcon}
                  svgClassName={styles.svgIcon}
                />
              );
            }

            return (
              <FlexContainer
                className={styles.actionOption}
                alignItems="center"
                onClick={event => {
                  event.stopPropagation();
                  link.onClick({ event });
                  setIsVisible(false);
                }}
                key={link.text}
              >
                {icon}
                <Text size="1rem" className={styles.linkText}>
                  {link.text}
                </Text>
              </FlexContainer>
            );
          })}
        </FlexContainer>
      </div>
    </PopupDrawer>
  );
}

ButtonDropdown.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.string,
      }),
      PropTypes.node,
    ]),
  ).isRequired,
  actionIcon: PropTypes.string,
  symbolIcon: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  right: PropTypes.string,
  top: PropTypes.string,
  name: PropTypes.string,
};

ButtonDropdown.defaultProps = {
  children: null,
  loading: false,
  actionIcon: undefined,
  symbolIcon: undefined,
  isOpen: false,
  onClick: () => undefined,
  onToggle: () => undefined,
  right: undefined,
  top: '2px',
  name: '',
};
