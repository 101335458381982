import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactHintFactory from 'react-hint';

import { VIEW_PERIODS, getViewPeriodInfo } from 'shared/utils/calendar.util';
import FlexContainer from 'web/components/primitives/flex-container';
import { useDimensions } from 'shared/utils/effects';

import HolidayCalendarDay from 'web/components/common/calendar/day.item/holiday.calendar.day';
import CalendarSidebar from '../calendar.sidebar';
import CalendarHeader from '../calendar.header';
import CalendarDay from '../calendar.day';
import styles from './styles.scss';

const ReactHint = ReactHintFactory(React);

export default function Calendar({
  DayItem,
  WeekMonthItem,
  highlightSelectedDate,
  className,
  events,
  holidays,
  highlightedEvents,
  viewPeriod,
  selectedDate,
  changeSelectedDate,
  isFetching,
  onClickDay,
  sidebarIsShowing,
  sidebarIsFloating,
  allowSidebar,
  toggleSidebar,
  sidebar,
  showAllInMonthGrid,
  hideFutureDates,
  releaseDay,
}) {
  const isDayView = viewPeriod === VIEW_PERIODS.days.key;
  const isMonthView = viewPeriod === VIEW_PERIODS.months.key;
  const { width, height } = useDimensions();

  const minimizeBySidebar = sidebarIsShowing && !sidebarIsFloating;

  let activitiesPerDay = 1;
  let headerFormat = 'ddd';
  if (!minimizeBySidebar && width > 766) {
    activitiesPerDay = 2;
  }
  if (width > 1100) {
    activitiesPerDay = 2;
    headerFormat = minimizeBySidebar ? 'ddd' : 'dddd';
  }
  if (width > 1300) {
    headerFormat = 'dddd';
    activitiesPerDay = 3;
  }
  if (width > 1500 && height > 900) {
    activitiesPerDay = 4;
  }
  if (width > 1700 && height > 950) {
    activitiesPerDay = 5;
  }

  const { start, end, days } = getViewPeriodInfo(viewPeriod, selectedDate);

  let body = null;

  if (isDayView) {
    const holidaysForDay = holidays[selectedDate.dayOfYear()] || [];
    const eventsForDay = events[selectedDate.dayOfYear()] || [];
    body = holidaysForDay.map(holiday => (
      <HolidayCalendarDay holiday={holiday} key={holiday.key || holiday._id} />
    ));
    body = [
      ...body,
      eventsForDay.map(event => (
        <DayItem event={event} key={event.key || event._id} />
      )),
    ];
  } else {
    body = days.map((day, index) => (
      <CalendarDay
        WeekMonthItem={WeekMonthItem}
        key={day.format()}
        viewPeriod={viewPeriod}
        isFetching={isFetching}
        day={day}
        selectedDate={selectedDate}
        highlightSelectedDate={highlightSelectedDate}
        showRightBorder={(index + 1) % 7 !== 0}
        showAllInMonthGrid={showAllInMonthGrid}
        isInViewPeriod={
          day.isSameOrBefore(end, 'day') && day.isSameOrAfter(start, 'day')
        }
        events={events[day.dayOfYear()]}
        holidays={holidays[day.dayOfYear()]}
        highlightedEvents={highlightedEvents}
        onClickDay={onClickDay || (newDay => changeSelectedDate(newDay))}
        hideFutureDates={hideFutureDates}
        activitiesPerDay={activitiesPerDay}
        releaseDay={releaseDay}
      />
    ));
  }

  return (
    <>
      <FlexContainer keepHeight>
        <FlexContainer keepHeight flexDirection="row">
          <FlexContainer
            keepHeight
            className={classNames({
              [styles.dayGridContainer]: isDayView,
            })}
          >
            <FlexContainer className={styles.headerContainer} noShrink>
              <CalendarHeader
                headerFormat={headerFormat}
                viewPeriod={viewPeriod}
                selectedDate={selectedDate}
                changeSelectedDate={changeSelectedDate}
              />
            </FlexContainer>
            <div
              className={classNames(className, {
                [styles.grid]: !isDayView,
                [styles.dayGrid]: isDayView,
                [styles.maxHeightGrid]: activitiesPerDay === 1 && isMonthView,
              })}
            >
              {body}
            </div>
          </FlexContainer>
          <CalendarSidebar
            isOpen={sidebarIsShowing}
            isFloating={sidebarIsFloating}
            isHidden={!allowSidebar}
            onToggle={toggleSidebar}
          >
            {sidebar}
          </CalendarSidebar>
        </FlexContainer>
      </FlexContainer>
      <ReactHint
        className="react-hint"
        attribute="data-rh-calendar"
        position="bottom"
        events
      />
    </>
  );
}

Calendar.propTypes = {
  DayItem: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  WeekMonthItem: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
    .isRequired,
  highlightSelectedDate: PropTypes.bool,
  className: PropTypes.string,
  events: PropTypes.shape({}),
  highlightedEvents: PropTypes.arrayOf(PropTypes.string),
  viewPeriod: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  changeSelectedDate: PropTypes.func,
  isFetching: PropTypes.bool,
  onClickDay: PropTypes.func,
  sidebarIsShowing: PropTypes.bool,
  sidebarIsFloating: PropTypes.bool,
  allowSidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  sidebar: PropTypes.node,
  showAllInMonthGrid: PropTypes.bool,
  hideFutureDates: PropTypes.bool,
  releaseDay: PropTypes.number,
};

Calendar.defaultProps = {
  events: {},
  holidays: {},
  onClickDay: null,
  highlightSelectedDate: true,
  isFetching: false,
  changeSelectedDate: () => null,
  className: '',
  sidebarIsShowing: false,
  sidebarIsFloating: false,
  allowSidebar: false,
  toggleSidebar: () => null,
  sidebar: null,
  highlightedEvents: [],
  showAllInMonthGrid: false,
  hideFutureDates: false,
  releaseDay: 20,
};
