import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import WORK_REQUEST_STATUS from 'shared/constants/work.request.status';
import FlexContainer from '../flex-container';
import styles from './style.scss';

export const CIRCLE_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const CIRCLE_COLORS = {
  open: WORK_REQUEST_STATUS.open,
  cancelled: WORK_REQUEST_STATUS.cancelled,
  completed: WORK_REQUEST_STATUS.completed,
  inProgress: WORK_REQUEST_STATUS.inProgress,
};

export default function CircleLabel({
  size,
  color,
  styleColor,
  className,
  ...rest
}) {
  const style = {};
  if (styleColor) {
    style.backgroundColor = styleColor;
  }
  return (
    <FlexContainer alignItems="center" className={className} {...rest}>
      <div
        className={classNames(styles.circle, {
          [styles[size]]: size,
          [styles[color]]: color,
        })}
        style={{
          ...style,
          width: size,
          height: size,
        }}
      />
    </FlexContainer>
  );
}

CircleLabel.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  styleColor: PropTypes.string,
  className: PropTypes.string,
};

CircleLabel.defaultProps = {
  size: 16,
  color: undefined,
  styleColor: undefined,
  className: undefined,
};
