import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { FlexContainer, Icon, IconType, Text } from 'web/components/primitives';

import styles from './styles.scss';

const validateFileType = (type, file) => {
  const fileArray = file.name.split('.');
  const fileType = fileArray[fileArray.length - 1];
  return fileType.toLowerCase() === type.toLowerCase();
};

const stopEvent = e => {
  e.preventDefault();
  e.stopPropagation();
};
const addDraggingClass = e => {
  stopEvent(e);
  e.target.classList.add(styles.dragover);
  e.target.classList.remove(styles.fileTypeError);
};
const removeDraggingClass = e => {
  stopEvent(e);
  e.target.classList.remove(styles.dragover);
  e.target.classList.remove(styles.fileTypeError);
};

export default function Dropzone({ onChange, fileType, accept, children }) {
  const inputRef = useRef();

  const content = children || (
    <FlexContainer className={styles.actionText} alignItems="center">
      <Icon type={IconType.paperclip} className={styles.icon} size={20} />
      <Text className={styles.linkText} mr={4}>
        Add{fileType ? ` ${fileType}` : ''} file
      </Text>

      <Text>or drop file here</Text>
    </FlexContainer>
  );
  return (
    <div
      className={styles.ddContainer}
      onDrag={stopEvent}
      onDragStart={stopEvent}
      onDragEnd={removeDraggingClass}
      onDragOver={addDraggingClass}
      onDragEnter={addDraggingClass}
      onDragLeave={removeDraggingClass}
      onClick={() => inputRef.current.click()}
      onDrop={e => {
        removeDraggingClass(e);
        const file = e.dataTransfer.files[0];
        if (validateFileType(fileType, file)) {
          onChange(file);
        } else {
          e.target.classList.add(styles.fileTypeError);
        }
      }}
    >
      <FlexContainer
        className={styles.innerDDContainer}
        justifyContent="center"
      >
        <input
          type="file"
          accept={accept}
          className={styles.fileInput}
          onChange={e => {
            onChange(e.target.files[0]);
            e.target.value = '';
          }}
          ref={inputRef}
          data-qa-drop-input
        />
        {content}
      </FlexContainer>
    </div>
  );
}

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  fileType: PropTypes.string,
  accept: PropTypes.string,
  children: PropTypes.node,
};

Dropzone.defaultProps = {
  fileType: undefined,
  accept: '*',
  children: undefined,
};
