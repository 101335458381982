import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EmptyImage from 'assets/images/global/empty-image.png';

import styles from './styles.scss';

export default function InlinePhotoPreview({ url, attention }) {
  return (
    <div
      style={{
        backgroundImage: `url(${url || EmptyImage})`,
      }}
      className={classNames(styles.wrapper, {
        [styles.attention]: attention,
      })}
    />
  );
}

InlinePhotoPreview.propTypes = {
  url: PropTypes.string,
  attention: PropTypes.bool,
};

InlinePhotoPreview.defaultProps = {
  url: null,
  attention: false,
};
