"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBrandingDetailsFromWhiteLabel = exports.getBrandingDetails = void 0;
var whiteLabelToTenantUrlMap = new Map([
    ['bridge', 'bridgeseniorliving'],
]);
var getBrandingDetails = function (tenantUrl) {
    return tenantUrl === 'bridgeseniorliving'
        ? {
            logoUrl: 'https://mcusercontent.com/0240bf6de56fbb8b7972db44e/images/61c94b01-e7ff-d40f-69e4-597afa48e51e.png',
            darkLogoUrl: 'https://mcusercontent.com/0240bf6de56fbb8b7972db44e/images/0ded9126-95fb-8135-f982-53231db6694e.png',
            formImageUrl: 'https://mcusercontent.com/0240bf6de56fbb8b7972db44e/images/473c24f5-433e-dec2-db18-e5bde58e324c.png',
            brandedName: 'Bridge Senior Living',
            shortName: 'Bridge Seniors',
            iosUrl: 'https://apps.apple.com/us/app/bridge-seniors-by-lifeloop/id1569642687',
            playStoreUrl: 'https://play.google.com/store/apps/details?id=com.lifeloop.bridge',
        }
        : undefined;
};
exports.getBrandingDetails = getBrandingDetails;
var getBrandingDetailsFromWhiteLabel = function (whiteLabel) {
    if (whiteLabelToTenantUrlMap.has(whiteLabel)) {
        return (0, exports.getBrandingDetails)(whiteLabelToTenantUrlMap.get(whiteLabel) || '');
    }
    else {
        return undefined;
    }
};
exports.getBrandingDetailsFromWhiteLabel = getBrandingDetailsFromWhiteLabel;
