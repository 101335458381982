import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlexContainer } from 'web/components/primitives';
import { usePageClick } from 'shared/utils/effects';

import style from './style.scss';

export default function PopupDrawer({
  isShowing,
  children,
  className,
  button,
  hidePopup,
  drawerClassName,
  top,
  right,
  useDarkShadow,
}) {
  usePageClick(() => {
    if (isShowing) {
      hidePopup();
    }
  });

  return (
    <div
      className={classNames(style.container, className)}
      onMouseDown={e => e.stopPropagation()}
    >
      {button}
      <div
        className={classNames(style.drawerWrapper, {
          [style.drawerWrapperShowing]: isShowing,
        })}
        style={{ top, right }}
      >
        <FlexContainer
          className={classNames(
            style.drawer,
            useDarkShadow && style.darkShadow,
            drawerClassName,
          )}
          flexDirection="column"
          data-qa-drawer
        >
          {children}
        </FlexContainer>
      </div>
    </div>
  );
}

PopupDrawer.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node.isRequired,
  isShowing: PropTypes.bool.isRequired,
  hidePopup: PropTypes.func.isRequired,
  drawerClassName: PropTypes.string,
  className: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  useDarkShadow: PropTypes.bool,
};

PopupDrawer.defaultProps = {
  className: undefined,
  drawerClassName: undefined,
  top: '2px',
  right: undefined,
  button: null,
  useDarkShadow: false,
};
