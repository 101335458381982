import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FlexContainer from '../flex-container';

import style from './style.scss';

export default function RowGroup({
  children,
  fullWidth,
  className,
  fixedWidth,
  ...rest
}) {
  const btnStyle = {};
  if (fixedWidth) {
    btnStyle.width = fixedWidth;
  }
  return (
    <FlexContainer className={classNames(style.group, className)} {...rest}>
      {React.Children.map(
        children.filter(child => !!child),
        child =>
          React.cloneElement(child, {
            style: btnStyle,
            className: classNames(child.props.className, style.groupChild, {
              [style.fullWidth]: fullWidth,
            }),
          }),
      )}
    </FlexContainer>
  );
}

RowGroup.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  fixedWidth: PropTypes.string,
};

RowGroup.defaultProps = {
  fullWidth: false,
  className: null,
  fixedWidth: null,
};
