import fetch from '../utils/fetch.helper';

export const resendInvite = (baseUrl, contacts) =>
  fetch('POST', `${baseUrl}/rest/contacts/invite`, {}, { contacts });

export const postGroupAdd = (baseUrl, contacts, groups) =>
  fetch('POST', `${baseUrl}/rest/contacts/groups`, {}, { contacts, groups });

export const fetchContactsForResident = (baseUrl, residentId) =>
  fetch('GET', `${baseUrl}/rest/contacts/resident/${residentId}`);

export const deleteFamilyAccount = (baseUrl, familyId) =>
  fetch('DELETE', `${baseUrl}/rest/contacts/account/${familyId}`);

export const validateInviteToken = (baseDomain, tenant, token) =>
  fetch('GET', `${baseDomain}/${tenant}/invitation/${token}/validate`);

export const postInviteTokenRegistration = (baseDomain, tenant, token, form) =>
  fetch('POST', `${baseDomain}/${tenant}/invitation/${token}`, {}, form);
