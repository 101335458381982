import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style.scss';

export default function SidebarPanel({
  isShowing,
  children,
  className,
  hasOverlay,
  onClose,
  width,
  useFixed,
}) {
  return (
    <>
      {hasOverlay && isShowing && onClose && (
        <div className={style.overlay} onClick={onClose} />
      )}
      <div
        style={{ width, right: isShowing ? 0 : width * -1 - 10 }}
        className={classNames(style.container, className, {
          [style.fixed]: useFixed,
        })}
        data-qa-sidebar={isShowing}
      >
        {isShowing ? children : null}
      </div>
    </>
  );
}

SidebarPanel.propTypes = {
  isShowing: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  hasOverlay: PropTypes.bool,
  useFixed: PropTypes.bool,
  width: PropTypes.number,
};

SidebarPanel.defaultProps = {
  useFixed: false,
  children: null,
  className: null,
  isShowing: false,
  onClose: null,
  hasOverlay: false,
  width: 460,
};
