import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { values } from 'lodash';

import Icon, { IconType } from 'stories/icon';
import Checkbox from 'stories/checkbox';
import Avatar from 'stories/avatar';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export const DotType = {
  green: 'green',
  blue: 'blue',
  red: 'red',
  yellow: 'yellow',
  gray: 'gray',
};

export default function ListRow({
  onClick,
  isSelectable,
  selected,
  isMoveable,
  orderNumber,
  hasAvatar,
  avatarUrl,
  preTitle,
  labelText,
  connectionIcon,
  colorDotType,
  isCompleted,
  mainContext,
  metaNodes,
  metaText1,
  metaText2,
  quickView,
  actions,
}) {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <FlexContainer
      justifyContent="spaceBetween"
      onClick={onClick}
      className={classNames(styles.listRow, {
        [styles.clickable]: onClick,
        [styles.moveable]: isMoveable,
        [styles.selected]: isSelected || selected,
      })}
    >
      <FlexContainer
        flexDirection="row"
        alignItems="center"
        className={styles.leftContent}
      >
        {isSelectable && (
          <FlexContainer
            noShrink
            justifyContent="center"
            align-items="center"
            className={styles.includeRowSelection}
          >
            <Checkbox
              value={isSelected}
              onChange={incomingIsSelected => {
                setIsSelected(incomingIsSelected);
              }}
            />
          </FlexContainer>
        )}
        {!!orderNumber && (
          <div className={styles.rowNumber}>{orderNumber}.</div>
        )}
        {(!!avatarUrl || hasAvatar) && (
          <div className={styles.avatarWrapper}>
            <Avatar classname={styles.avatar} url={avatarUrl} size={40} />
          </div>
        )}
        {!!preTitle && (
          <FlexContainer
            alignItems="center"
            noShrink
            className={styles.preTitleWrapper}
          >
            <div className={styles.preTitleCustom}>{preTitle.custom}</div>
            {(preTitle.mainContext || preTitle.metaText) && (
              <FlexContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
                className={styles.preTitleText}
              >
                <span className={styles.preTitleMainText}>
                  {!!preTitle.mainContext && preTitle.mainContext}
                </span>
                <span className={styles.preTitleMetaText}>
                  {!!preTitle.metaText && preTitle.metaText}
                </span>
              </FlexContainer>
            )}
            <div
              className={styles.preTitleColorBar}
              styles={{
                backgroundColor: preTitle.colorBar,
              }}
            />
          </FlexContainer>
        )}
        <div className={styles.textWrapper}>
          {!!labelText && <div className={styles.labelText}>{labelText}</div>}
          <FlexContainer
            className={classNames(styles.mainText, {
              [styles.completed]: isCompleted,
            })}
          >
            <FlexContainer>
              {!!connectionIcon && (
                <div className={styles.connectionIcon}>
                  <Icon size={18} type={IconType.flag} />
                </div>
              )}
              {!!colorDotType && (
                <FlexContainer
                  noShrink
                  className={classNames(styles.colorDot, styles[colorDotType])}
                />
              )}
            </FlexContainer>
            <div className={styles.titleText}>{mainContext}</div>
          </FlexContainer>
          {metaNodes?.map(text => (
            <div key={text} className={styles.metaText}>
              {text}
            </div>
          ))}
          {!!metaText1 && <div className={styles.metaText}>{metaText1}</div>}
          {!!metaText2 && <div className={styles.metaText}>{metaText2}</div>}
        </div>
      </FlexContainer>
      <FlexContainer className={styles.rightContent}>
        {!!quickView && (
          <FlexContainer alignItems="center" className={styles.quickView}>
            {quickView}
          </FlexContainer>
        )}
        {!!actions && (
          <FlexContainer alignItems="center" className={styles.actions}>
            {actions}
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
}

ListRow.propTypes = {
  onClick: PropTypes.func,
  isMoveable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  selected: PropTypes.bool,
  orderNumber: PropTypes.number,
  hasAvatar: PropTypes.bool,
  preTitle: PropTypes.shape({
    mainContext: PropTypes.string,
    metaText: PropTypes.string,
    colorBar: PropTypes.string,
    custom: PropTypes.node,
  }),
  avatarUrl: PropTypes.string,
  labelText: PropTypes.node,
  connectionIcon: PropTypes.bool,
  colorDotType: PropTypes.oneOf(values(DotType)),
  isCompleted: PropTypes.bool,
  mainContext: PropTypes.node.isRequired,
  metaNodes: PropTypes.arrayOf(PropTypes.node),
  metaText1: PropTypes.node,
  metaText2: PropTypes.node,
  quickView: PropTypes.node,
  actions: PropTypes.node,
};

ListRow.defaultProps = {
  onClick: undefined,
  isMoveable: false,
  isSelectable: false,
  selected: false,
  orderNumber: undefined,
  hasAvatar: false,
  avatarUrl: undefined,
  preTitle: undefined,
  labelText: undefined,
  connectionIcon: false,
  colorDotType: undefined,
  isCompleted: false,
  metaNodes: undefined,
  metaText1: undefined,
  metaText2: undefined,
  quickView: undefined,
  actions: undefined,
};
