import React, { useEffect, useMemo, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import { mapValues } from 'lodash';
import tinycolor from 'tinycolor2';

import FlexContainer from '../flex-container';
import Text from '../text';

const CHART_MARGIN = {
  top: 5,
  left: 0,
  bottom: 20,
  right: 50,
};

const initialLineOpacity = lines =>
  lines.reduce((obj, line) => ({ ...obj, [line.dataKey]: 1 }), {});

export default function LineGraph({
  title,
  subText,
  data,
  lines,
  height,
  tooltip,
  ...rest
}) {
  const [lineOpacity, setLineOpacity] = useState(initialLineOpacity(lines));

  useEffect(() => {
    setLineOpacity(initialLineOpacity(lines));
  }, [setLineOpacity, lines]);

  const handleMouseEnter = ({ dataKey }) =>
    setLineOpacity(
      mapValues(lineOpacity, (_, key) => (key !== dataKey ? 0.3 : 1)),
    );

  const handleMouseLeave = () =>
    setLineOpacity(mapValues(lineOpacity, () => 1));

  const transformedLines = useMemo(
    () =>
      lines
        .map(line => {
          if (!line.stroke) {
            return line;
          }
          let newColor = tinycolor(line.stroke);
          if (newColor.getBrightness() > 130) {
            newColor = newColor.darken(25);
          }
          return { ...line, stroke: newColor.toHexString() };
        })
        .map(line => (
          <Line
            type="monotone"
            key={line.dataKey}
            stroke={line.stroke}
            strokeOpacity={lineOpacity[line.dataKey]}
            {...line}
          />
        )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lines],
  );

  return (
    <FlexContainer flexDirection="column" {...rest}>
      <Text bold size="1.3rem" ml={45} mr={CHART_MARGIN.right} mb={2}>
        {title}
      </Text>
      <Text meta size="1rem" ml={45} mr={CHART_MARGIN.right} mb={10}>
        {subText}
      </Text>
      <ResponsiveContainer height={height} width="100%">
        <LineChart data={data} width={500} height={300} margin={CHART_MARGIN}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={tooltip} />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {transformedLines}
        </LineChart>
      </ResponsiveContainer>
    </FlexContainer>
  );
}

LineGraph.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
  height: PropTypes.number,
  tooltip: PropTypes.node,
};

LineGraph.defaultProps = {
  subText: undefined,
  height: 500,
  tooltip: undefined,
};
