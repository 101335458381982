"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_STATUSES = void 0;
var UserStatus;
(function (UserStatus) {
    UserStatus["active"] = "active";
    UserStatus["inactive"] = "inactive";
    UserStatus["deleted"] = "deleted";
    UserStatus["onLeave"] = "onLeave";
})(UserStatus || (UserStatus = {}));
exports.ALL_STATUSES = Object.values(UserStatus);
exports.default = UserStatus;
