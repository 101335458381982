import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { values, isDate } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import style from './style.scss';

export const POPPER_PLACEMENT = {
  bottom: 'bottom',
  bottomEnd: 'bottom-end',
  bottomStart: 'bottom-start',
  left: 'left',
  leftEnd: 'left-end',
  leftStart: 'left-start',
  right: 'right',
  rightEnd: 'right-end',
  rightStart: 'right-start',
  top: 'top',
  topEnd: 'top-end',
  topStart: 'top-start',
};

export default function DateSelector({
  date,
  onDateChange,
  highlightDates,
  className,
  ...rest
}) {
  const highlighted = (highlightDates || []).length ? highlightDates : [];
  let selected = date;
  if (selected && !isDate(selected)) {
    selected = new Date(selected);
  }
  return (
    <span className={classNames(style.picker, className)}>
      <DatePicker
        fixedHeight
        selected={selected}
        onChange={onDateChange}
        highlightDates={highlighted}
        {...rest}
      />
    </span>
  );
}

DateSelector.propTypes = {
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onDateChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  popperPlacement: PropTypes.oneOf(values(POPPER_PLACEMENT)),
  highlightDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  placeholderText: PropTypes.string,
};

DateSelector.defaultProps = {
  maxDate: undefined,
  minDate: undefined,
  date: undefined,
  className: undefined,
  popperPlacement: POPPER_PLACEMENT.bottom,
  highlightDates: undefined,
  placeholderText: 'mm/dd/yyyy',
};
