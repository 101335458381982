import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { Icon, IconType } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';
import Text from 'web/components/primitives/text';
import ButtonDropdown from 'web/components/primitives/button.dropdown';

import styles from './styles.scss';

export const SORT_TYPE = { ASC: 'asc', DESC: 'desc' };
export const CONTROL_TYPE = { FILTER: 'filter', SORT: 'sort' };
const directionLabel = option =>
  option.direction === SORT_TYPE.ASC ? '(A-Z)' : '(Z-A)';

export default function ListControl({
  className,
  type,
  options,
  value,
  onChange,
  cycleOptions,
  asButton,
}) {
  const [isOpen, setIsOpen] = useState(undefined);

  const [openTimeout, setOpenTimeout] = useState(null);

  let onClickDropdown;
  let onDoubleClickDropdown;
  if (cycleOptions) {
    onClickDropdown = () => {
      if (!openTimeout) {
        setOpenTimeout(setTimeout(() => setIsOpen(true), 200));
      } else {
        clearTimeout(openTimeout);
        setOpenTimeout(null);
        setIsOpen(false);
      }
    };

    onDoubleClickDropdown = () => {
      if (openTimeout) {
        clearTimeout(openTimeout);
        setOpenTimeout(null);
      }

      setIsOpen(false);
      cycleOptions();
    };
  }

  const links = options.map(option => ({
    text: `${option.label}${
      type === CONTROL_TYPE.SORT ? ` ${directionLabel(option)}` : ''
    }`,
    onClick: () => onChange(option),
  }));

  if (!asButton) {
    return (
      <div
        className={classNames(styles.container, className)}
        onClick={onClickDropdown}
        onDoubleClick={onDoubleClickDropdown}
      >
        <FlexContainer
          data-list-control
          alignItems="center"
          className={styles.controlContainer}
        >
          <Icon
            type={
              type === CONTROL_TYPE.SORT ? IconType.sliders : IconType.filter
            }
            className={styles.blue}
            size={16}
          />
          <Text size={14} ml={4} bold className={styles.blue}>
            {type === CONTROL_TYPE.SORT ? 'Sort' : 'Filter'}:
          </Text>
          <Text size={14} ml={4} bold>
            {value.label}
          </Text>
          {type === CONTROL_TYPE.SORT && (
            <Text size={14} ml={4}>
              {directionLabel(value)}
            </Text>
          )}
          <Icon type={IconType.chevronDown} size={14} className={styles.blue} />
        </FlexContainer>
        <ButtonDropdown
          isOpen={isOpen}
          top="-30px"
          right="0px"
          name="list-control"
          links={links}
        />
      </div>
    );
  } else {
    return (
      <ButtonDropdown
        className={styles.buttonDropStyles}
        right="0px"
        name="list-control"
        symbolIcon={
          type === CONTROL_TYPE.SORT ? IconType.sliders : IconType.filter
        }
        links={links}
      >
        {type === CONTROL_TYPE.SORT ? 'Sort' : 'Filter'}: {value.label}{' '}
        {directionLabel(value)}
      </ButtonDropdown>
    );
  }
}

const OptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(values(SORT_TYPE)),
});

ListControl.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(values(CONTROL_TYPE)),
  options: PropTypes.arrayOf(OptionType).isRequired,
  value: OptionType.isRequired,
  onChange: PropTypes.func.isRequired,
  cycleOptions: PropTypes.func,
};

ListControl.defaultProps = {
  className: undefined,
  type: CONTROL_TYPE.SORT,
  asButton: false,
  cycleOptions: () => {},
};
