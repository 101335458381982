import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import LoadingSpinner from 'web/components/primitives/loading.spinner';
import FlexContainer from 'web/components/primitives/flex-container';
import Icon from 'stories/icon';
import Text from 'web/components/primitives/text';

import styles from './styles.scss';

export const EMPTY_SIZE = {
  small: {
    spinnerSize: 30,
    textSize: '18px',
    subTextSize: '12px',
    iconSize: 36,
  },
  medium: {
    spinnerSize: 40,
    textSize: '24px',
    subTextSize: '16px',
    iconSize: 54,
  },
  large: {
    spinnerSize: 50,
    textSize: '30px',
    subTextSize: '20px',
    iconSize: 73,
  },
};

export default function EmptyLoadingPlaceholder({
  size,
  className,
  isLoading,
  loadingText,
  iconName,
  emptyText,
  emptySubText,
  isEmpty,
  children,
  keepHeight,
  ...rest
}) {
  if (!isEmpty && !isLoading) {
    return null;
  }
  let body = (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingSpinner size={size.spinnerSize} />
      {loadingText && (
        <Text
          mt={10}
          meta
          align="center"
          size="22px"
          className={styles.loadingText}
        >
          {loadingText}
        </Text>
      )}
    </FlexContainer>
  );
  if (!isLoading) {
    body = (
      <>
        {iconName && (
          <Icon className={styles.icon} size={size.iconSize} type={iconName} />
        )}
        <Text meta align="center" size={size.textSize}>
          {emptyText}
        </Text>
        {emptySubText && (
          <Text meta align="center" size={size.subTextSize}>
            {emptySubText}
          </Text>
        )}
        {!!children && (
          <div className={styles.childrenContainer}>{children}</div>
        )}
      </>
    );
  }
  return (
    <FlexContainer
      keepHeight={keepHeight}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className={classNames(styles.container, className)}
      {...rest}
    >
      {body}
    </FlexContainer>
  );
}

EmptyLoadingPlaceholder.propTypes = {
  size: PropTypes.oneOf(values(EMPTY_SIZE)),
  emptyText: PropTypes.string,
  emptySubText: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  iconName: PropTypes.string,
  children: PropTypes.node,
  keepHeight: PropTypes.bool,
};

EmptyLoadingPlaceholder.defaultProps = {
  size: EMPTY_SIZE.large,
  emptyText: '',
  emptySubText: null,
  className: null,
  isLoading: false,
  isEmpty: false,
  iconName: null,
  children: undefined,
  keepHeight: true,
};
