import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { useInterval } from 'shared/utils/effects';
import { FlexContainer, Icon, IconType, Text } from 'web/components/primitives';

import styles from './styles.scss';

export default function CollapsibleContainer({
  children,
  title,
  isOpen,
  onToggle,
  ...rest
}) {
  const [openMaxHeight, setOpenMaxHeight] = useState(1000);
  const [isOpenLocal, setIsOpen] = useState(isOpen);
  const contentRef = useRef();

  useInterval(
    250,
    () => {
      if (
        isOpenLocal &&
        contentRef.current &&
        openMaxHeight !== contentRef.current.clientHeight
      ) {
        setOpenMaxHeight(contentRef.current.clientHeight);
      }
    },
    [isOpenLocal],
    true,
  );

  useEffect(() => {
    if (!isUndefined(isOpen)) {
      setIsOpen(isOpen);
    }
  }, [isOpen]);

  const onClick = () => {
    if (onToggle) {
      return onToggle();
    }
    return setIsOpen(!isOpenLocal);
  };

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="stretch"
      className={styles.wrapper}
      {...rest}
    >
      <FlexContainer
        alignItems="center"
        justifyContent="spaceBetween"
        className={styles.header}
        onClick={onClick}
      >
        <Text bold className={styles.title}>
          {title}
        </Text>
        <Icon
          size={18}
          type={IconType.chevronUp}
          className={classNames(styles.icon, {
            [styles['icon--collapsed']]: !isOpenLocal,
          })}
        />
      </FlexContainer>
      <FlexContainer
        flexDirection="column"
        alignItems="stretch"
        className={classNames(
          styles.container,
          isOpenLocal ? styles.openContainer : undefined,
        )}
        style={{
          maxHeight: isOpenLocal ? openMaxHeight : 0,
        }}
      >
        <FlexContainer
          ref={contentRef}
          flexDirection="column"
          alignItems="stretch"
        >
          {children}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}

CollapsibleContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

CollapsibleContainer.defaultProps = {
  title: '',
  isOpen: undefined,
  onToggle: undefined,
};
