import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FlexContainer from '../flex-container';
import styles from './styles.scss';

export const TAB_VARIANTS = {
  standard: 'standard',
  divider: 'divider',
};

export default function SubTabs({ options, onClick, active, variant }) {
  useEffect(() => {
    const selected = options.find(option => option.value === active);
    if (!selected && active) {
      onClick(options[0].value);
    }
  }, [options, active, onClick]);

  if (variant === TAB_VARIANTS.standard) {
    return (
      <FlexContainer py={1} noShrink alignItems="center">
        {options.map(option => (
          <div
            data-qa-subtab-option={option.value}
            key={option.label}
            className={classNames(styles.subtleTab, {
              [styles.selectedSubtleTab]: active === option.value,
            })}
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </div>
        ))}
      </FlexContainer>
    );
  }

  return (
    <FlexContainer className={styles.container} alignItems="flexEnd">
      {options.map(({ value, label }) => (
        <div
          key={value}
          onClick={() => onClick(value)}
          className={classNames(
            styles.tab,
            active === value ? styles.active : null,
          )}
        >
          {label}
        </div>
      ))}
      <FlexContainer flex={1} className={styles.divider} />
    </FlexContainer>
  );
}
SubTabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  active: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};
SubTabs.defaultProps = {
  variant: TAB_VARIANTS.standard,
};
