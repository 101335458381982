import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { max } from 'lodash';

import styles from './styles.scss';

const buildPath = commands =>
  commands.map(({ x, y }, i) => `${i ? 'L' : 'M'} ${x},${y}`).join(' ');

const SkeletonSvg = ({ width, height }) => (
  <div className={styles.wrapper}>
    <svg
      width={width}
      height={height}
      className={styles.skeletonSvg}
      data-skeleton-svg
    >
      <path
        className={styles.skeletonLine}
        d="M 0,21 L 40,12 L 80,17 L 120,18"
      />
      <path
        strokeDasharray="3,3"
        className={styles.skeletonDashed}
        d="M 120,18 L 160,10"
      />
      <path
        className={styles.skeletonIntegral}
        d="M 0,21 L 40,12 L 80,17 L 120,18 L 160,10 L 160,40 L 0,40 Z"
      />
    </svg>
  </div>
);

SkeletonSvg.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default function SparkLine({
  values,
  padding,
  height,
  width,
  maxValue,
  title,
  dashArray,
}) {
  const commands = useMemo(() => {
    const localMaxValue = (maxValue || max(values) || 1) + 2; // + 2 offset for path width
    return (values || []).map((value, i) => ({
      x: i * (width / (values.length - 1)),
      y: padding + (1 - value / localMaxValue) * (height - padding * 2),
    }));
  }, [values, padding, height, width, maxValue]);

  const historicalValues = commands.slice(0, commands.length - 1);
  const currentValues = commands.slice(commands.length - 2);
  if (commands.length === 0) {
    return <SkeletonSvg height={height} width={width} />;
  }
  return (
    <svg width={width} height={height} data-rh={title}>
      <path className={styles.line} d={buildPath(historicalValues)} />
      <path
        strokeDasharray={dashArray}
        className={styles.line}
        d={buildPath(currentValues)}
      />
      <path
        className={styles.integral}
        d={`${buildPath(commands)} L ${width},${height} L 0,${height} Z`}
      />
    </svg>
  );
}

SparkLine.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  padding: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
  maxValue: PropTypes.number,
  dashArray: PropTypes.string,
};
SparkLine.defaultProps = {
  maxValue: undefined,
  title: undefined,
  padding: 0,
  height: 40,
  width: 160,
  dashArray: '3,3',
};
