import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button, { ButtonType } from 'stories/button';
import { IconType } from 'stories/icon';

import styles from './styles.scss';

export default function ButtonInput({
  isError,
  className,
  innerClassName,
  value,
  defaultValue,
  ...rest
}) {
  return (
    <Button
      {...rest}
      innerClassName={classNames(
        { [styles.innerButton]: !!value },
        innerClassName,
      )}
      className={classNames(styles.button, className)}
      type={isError ? ButtonType.permanent : ButtonType.additional}
      actionIcon={value ? IconType.edit : null}
    >
      <div className={styles.innerText}>{value || defaultValue}</div>
    </Button>
  );
}

ButtonInput.propTypes = {
  isError: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};

ButtonInput.defaultProps = {
  isError: false,
  className: undefined,
  value: undefined,
  defaultValue: undefined,
};
