import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon, IconType } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

const CLOSED_WIDTH = 15;
const HEIGHT = 80;

const PATH = `M20,0 C20,${HEIGHT / 6} 16,${HEIGHT / 5} 10,${HEIGHT /
  4} C1,${HEIGHT / 3} 1,${HEIGHT / 2} 1,${HEIGHT / 2} C1,${HEIGHT /
  2} 1,${HEIGHT * (19 / 30)} 10,${HEIGHT * (3 / 4)} C16,${HEIGHT *
  (5 / 6)} 20,${HEIGHT * (4 / 5)} 20,${HEIGHT}`;

export default function CollapsibleSidebar({
  disableAnimate,
  onToggle,
  isOpen,
  isHidden,
  isLeft,
  width,
  children,
}) {
  let margin;
  if (isHidden) {
    margin = -width - 1;
  } else if (!isOpen) {
    margin = CLOSED_WIDTH - width;
  }
  return (
    <FlexContainer
      onClick={() => (!isOpen ? onToggle() : null)}
      style={{
        [isLeft ? 'marginLeft' : 'marginRight']: margin,
        maxWidth: width,
        minWidth: width,
      }}
      className={classNames(styles.sidebar, {
        [styles['sidebar--open']]: isOpen,
        [styles['sidebar--closed']]: !isOpen,
        [styles['sidebar--hidden']]: isHidden,
        [styles['sidebar--left']]: isLeft,
        [styles['sidebar--disableAnimate']]: disableAnimate,
      })}
      keepHeight
    >
      {isOpen && !isHidden && children}
      <svg
        width="20"
        height={HEIGHT}
        className={classNames(styles.arc, {
          [styles['arc--closed']]: !isOpen,
        })}
        onClick={onToggle}
      >
        <path d={PATH} className={styles.arcBackground} />
        <path d={PATH} />
      </svg>
      <Icon
        size={22}
        style={{ bottom: 39 + HEIGHT / 2 }}
        className={classNames(styles.handleIcon, {
          [styles['handleIcon--closed']]: !isOpen,
        })}
        type={isLeft ? IconType.chevronsLeft : IconType.chevronsRight}
      />
    </FlexContainer>
  );
}

CollapsibleSidebar.propTypes = {
  disableAnimate: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool,
  isLeft: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.node,
};

CollapsibleSidebar.defaultProps = {
  disableAnimate: false,
  isHidden: false,
  isLeft: false,
  width: 500,
  children: null,
};
