const moment = require('moment');

const MAP_WEEK_OF_MONTH = {
  first: 0,
  second: 1,
  third: 2,
  fourth: 3,
  last: [3, 4],
};
const INTERVALS = {
  none: 'none',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  annual: 'annual',
};

const MIN_RECURRING_DATE = moment(new Date('01/01/2015'));
const MAX_DATE = moment().add(25, 'years');
const NON_RECURRING_OPTION = { label: 'never', value: INTERVALS.none };
const RECURRING_OPTIONS = [
  { label: 'days', value: INTERVALS.daily },
  { label: 'weeks', value: INTERVALS.weekly },
  { label: 'months', value: INTERVALS.monthly },
];
const RECURRING_MONTHLY_OPTIONS = [
  { label: 'First', value: 'first' },
  { label: 'Second', value: 'second' },
  { label: 'Third', value: 'third' },
  { label: 'Fourth', value: 'fourth' },
  { label: 'Last', value: 'last' },
];
const DAYS_OF_WEEK = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];
const MONTH_YEAR_FORMAT = 'MM-YYYY';

module.exports = {
  MAP_WEEK_OF_MONTH,
  INTERVALS,
  MAX_DATE,
  MIN_RECURRING_DATE,
  NON_RECURRING_OPTION,
  RECURRING_OPTIONS,
  RECURRING_MONTHLY_OPTIONS,
  DAYS_OF_WEEK,
  MONTH_YEAR_FORMAT,
};
