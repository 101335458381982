import { last, includes } from 'lodash';

export const isHeicFile = file =>
  file && includes(['image/heic', 'image/heif'], file.type);

export const getDataUriFromFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false,
    );
    reader.addEventListener(
      'error',
      () => {
        reject(reader.error);
      },
      false,
    );
    reader.readAsDataURL(file);
  });

export const getBase64FromImageUrl = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const checkIfExternalUrlExists = url => fetch(url, { method: 'HEAD' });

// necessary for IE to save cross-origin images
export const getBase64FromExternalUrl = url =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onerror = reject;
    xhr.onload = function onload() {
      const objectUrl = window.URL.createObjectURL(this.response);
      getBase64FromImageUrl(objectUrl)
        .then(dataUrl => {
          window.URL.revokeObjectURL(objectUrl);
          resolve(dataUrl);
        })
        .catch(reject);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send();
  });

export const dataURItoBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    _ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  return new Blob([dataView], { type: mimeString });
};

export const dataUriToFile = (uri, name) => {
  const blob = dataURItoBlob(uri);
  const file = new File(
    [blob],
    name || `lifeloop_image.${last(blob.type.split('/'))}`,
    {
      lastModified: new Date().getTime(),
      size: blob.size,
      type: blob.type,
    },
  );
  return file;
};

const MAX_CANVAS_AREA_IN_PIXELS = 268000000;

export const resizeDataURL = ({ data, width, height, quality = 1.0 }) =>
  new Promise((resolve, reject) => {
    const img = document.createElement('img');
    let [localWidth, localHeight] = [width, height];
    if (width * height > MAX_CANVAS_AREA_IN_PIXELS) {
      const areaScaleFactor =
        MAX_CANVAS_AREA_IN_PIXELS / (localWidth * localHeight);
      localWidth *= areaScaleFactor;
      localHeight *= areaScaleFactor;
    }
    img.onerror = reject;
    img.onload = function onload() {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = localWidth;
        canvas.height = localHeight;
        ctx.drawImage(this, 0, 0, localWidth, localHeight);
        const dataUrl = canvas.toDataURL('image/jpeg', quality);
        resolve(dataUrl);
      } catch (err) {
        reject(err);
      }
    };
    img.src = data;
  });
