"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.truncateMessage = exports.phoneValidation = exports.toE164 = exports.deserializePhone = exports.serializePhone = exports.isPhoneValid = exports.convertPhoneNumberWithParens = exports.removeSpecialChars = void 0;
var lodash_1 = require("lodash");
var TEXT_MESSAGE_LENGTH = 160;
var removeSpecialChars = function (numbers) {
    if (!numbers) {
        return '';
    }
    return (Array.isArray(numbers) ? numbers.join('') : String(numbers)).replace(/\D/g, '');
};
exports.removeSpecialChars = removeSpecialChars;
var convertPhoneNumberWithParens = function (numbers, defaultValue) {
    if (!numbers) {
        return '';
    }
    var numberAsArray = Array.isArray(numbers)
        ? numbers
        : "".concat(numbers).split('');
    var filteredNumbers = (0, lodash_1.filter)((0, exports.removeSpecialChars)(numberAsArray), function (number) { return number !== null && number !== undefined; });
    if (filteredNumbers.length < 1) {
        return defaultValue !== undefined ? defaultValue : 'Not available';
    }
    if (filteredNumbers.length >= 1 && filteredNumbers.length < 4) {
        return "(".concat((0, lodash_1.slice)(filteredNumbers, 0).join(''));
    }
    if (filteredNumbers.length >= 4 && filteredNumbers.length < 7) {
        return "(".concat((0, lodash_1.take)(filteredNumbers, 3).join(''), ") ").concat((0, lodash_1.slice)(filteredNumbers, 3).join(''));
    }
    return "(".concat((0, lodash_1.take)(filteredNumbers, 3).join(''), ") ").concat(filteredNumbers[3]).concat(filteredNumbers[4]).concat(filteredNumbers[5], "-").concat((0, lodash_1.slice)(filteredNumbers, 6).join(''));
};
exports.convertPhoneNumberWithParens = convertPhoneNumberWithParens;
var isPhoneValid = function (value) {
    var data = (0, lodash_1.isArray)(value) ? value.join('') : String(value);
    return (!!data &&
        !!data.trim() &&
        data.length >= 10 &&
        !!data.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,})$/));
};
exports.isPhoneValid = isPhoneValid;
var serializePhone = function (numbers) {
    if (!numbers) {
        return [];
    }
    // remove all non numbers first
    var filteredNumbers = (0, exports.removeSpecialChars)(numbers);
    if (filteredNumbers && !Array.isArray(filteredNumbers)) {
        return filteredNumbers.split('');
    }
    return [];
};
exports.serializePhone = serializePhone;
var deserializePhone = function (phone) {
    var converted = (0, lodash_1.isNumber)(phone) ? (phone || '').toString() : phone;
    if ((converted || '').length > 0) {
        return (0, exports.convertPhoneNumberWithParens)(converted);
    }
    return (0, lodash_1.isArray)(phone) ? '' : converted;
};
exports.deserializePhone = deserializePhone;
var toE164 = function (number) {
    if (!(0, exports.isPhoneValid)(number)) {
        return undefined;
    }
    // Only support numbers from U.S. and Canada
    return "+1".concat((0, exports.removeSpecialChars)(number));
};
exports.toE164 = toE164;
var phoneValidation = function (value) {
    var data = (0, lodash_1.isArray)(value) ? value.join('') : value || '';
    data = (0, lodash_1.isString)(data) ? data : "".concat(data);
    if ((0, lodash_1.isUndefined)(data) || data.trim() === '') {
        return undefined;
    }
    if (data.length < 10) {
        return 'Phone number is not valid';
    }
    var matches = data.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,})$/);
    return matches === null ? 'Phone number is not valid' : undefined;
};
exports.phoneValidation = phoneValidation;
var truncateMessage = function (message) {
    if (message.length <= TEXT_MESSAGE_LENGTH) {
        return message;
    }
    return "".concat((0, lodash_1.take)(message, TEXT_MESSAGE_LENGTH - 3).join(''), "...");
};
exports.truncateMessage = truncateMessage;
