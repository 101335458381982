import React from 'react';
import PropTypes from 'prop-types';

import FlexContainer from 'web/components/primitives/flex-container';
import HeaderBar from 'web/components/primitives/header.bar';
import Sidebar from 'web/components/primitives/sidebar';

import BackTo from './back.to';
import styles from './styles.scss';

export default function Layout({
  header,
  subHeader,
  children,
  backTo,
  onBack,
  title,
  headerActions,
  links,
  actions,
  selectedLink,
  onChangeRoute,
  renderActions,
}) {
  const headerToRender = header || (
    <HeaderBar title={title} onBack={backTo ? null : onBack}>
      {headerActions}
    </HeaderBar>
  );
  return (
    <FlexContainer keepHeight alignItems="stretch">
      {headerToRender}
      <FlexContainer keepHeight>
        {subHeader}
        <FlexContainer
          flex="1"
          keepHeight
          justifyContent="flexStart"
          alignItems="stretch"
          flexDirection="row"
        >
          {!!links.length && (
            <FlexContainer keepHeight flex="0">
              {!!onBack && !!backTo ? (
                <BackTo onBack={onBack} backTo={backTo} />
              ) : (
                <div className={styles.spacer} />
              )}
              <Sidebar
                links={links}
                selected={selectedLink}
                onChange={onChangeRoute}
                actions={actions}
                renderActions={renderActions}
              />
            </FlexContainer>
          )}
          <FlexContainer className={styles.scroller} scroll keepHeight flex="1">
            {children}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}

Layout.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node,
  children: PropTypes.node,
  backTo: PropTypes.string,
  onBack: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedLink: PropTypes.string,
  onChangeRoute: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  renderActions: PropTypes.func,
};

Layout.defaultProps = {
  header: null,
  subHeader: null,
  children: null,
  backTo: '',
  onBack: null,
  links: [],
  actions: [],
  selectedLink: '',
  onChangeRoute: null,
  title: '',
  headerActions: [],
  renderActions: null,
  showSidebar: false,
};
