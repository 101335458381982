const moment = require('moment');
const { some } = require('lodash');
const USER_STATUS = require('./user.status').default;

const CONTACT_TYPES = {
  resident: 'resident',
  community: 'community',
};

const ACCOUNT_TYPES = {
  lifeloop: 'lifeloop',
  pcc: 'pcc',
};

const VIEWS = {
  ...CONTACT_TYPES,
  all: 'all',
};

const SORTBY = {
  firstName: {
    key: 'firstName',
    label: 'First Name',
  },
  lastName: {
    key: 'lastName',
    label: 'Last Name',
  },
};

const notDeactivated = contact =>
  contact.status !== USER_STATUS.inactive &&
  (!contact.residents.length ||
    some(contact.residents, { status: USER_STATUS.active }));

const FILTERS = {
  all: {
    key: 'all',
    name: 'Show All',
    filter: notDeactivated,
  },
  registered: {
    key: 'registered',
    name: 'Registered',
    filter: contact =>
      contact.accountType === ACCOUNT_TYPES.lifeloop && notDeactivated(contact),
  },
  unregistered: {
    key: 'unregistered',
    name: 'Unregistered',
    filter: contact => !contact.accountType && notDeactivated(contact),
  },
  pendingInvites: {
    key: 'pendingInvites',
    name: 'Pending Invites',
    filter: contact =>
      contact.invite &&
      moment(contact.invite.expiresAt).isAfter(moment()) &&
      !contact.disableInvites,
  },
  expiredInvites: {
    key: 'expiredInvites',
    name: 'Expired Invites',
    filter: contact =>
      contact.invite &&
      moment(contact.invite.expiresAt).isBefore(moment()) &&
      !contact.disableInvites,
  },
  bouncedInvites: {
    key: 'bouncedInvites',
    name: 'Bounced Invites',
    filter: contact =>
      contact.invite && contact.invite.bounced && !contact.disableInvites,
  },
  unsubscribed: {
    key: 'unsubscribed',
    name: 'Unsubscribed',
    filter: contact => !!contact.disableInvites,
  },
  inactive: {
    key: 'inactive',
    name: 'Deactivated',
    filter: contact => !notDeactivated(contact),
  },
};

const ERRORS = {
  emailExists: 'emailExists',
  inviteExpired: 'inviteExpired',
  noInviteFound: 'noInviteFound',
  contactValidation: 'contactValidation',
  statusDeleted: 'statusDeleted',
};

const ERROR_MESSAGE_BY_TYPE = {
  [ERRORS.emailExists]:
    'This email already exists as a registered user in LifeLoop.',
  [ERRORS.inviteExpired]:
    'This invite has expired. Please request another one from your community or contact LifeLoop support at support@lifeloop.com',
  [ERRORS.noInviteFound]:
    'No invite found. Please request one from your community or contact LifeLoop support at support@lifeloop.com',
  [ERRORS.statusDeleted]:
    'No invite found. Please request one from your community or contact LifeLoop support at support@lifeloop.com',
  other:
    'Uh oh! Something went wrong validating your invite token. Please contact LifeLoop support at support@lifeloop.com',
};

module.exports = {
  ACCOUNT_TYPES,
  ERRORS,
  ERROR_MESSAGE_BY_TYPE,
  FILTERS,
  SORTBY,
  CONTACT_TYPES,
  VIEWS,
};
