import Moment from 'moment';
import { includes, some, endsWith } from 'lodash';

export const calendarHasFacility = (calendar, facilityId) => {
  if (!calendar.isFacilityCalendar) {
    return false;
  }
  if (calendar.facilities[0]._id) {
    return some(calendar.facilities, { _id: facilityId });
  }
  return includes(calendar.facilities, facilityId);
};

export const calendarHasFacilities = (calendar, facilityIds) =>
  some(facilityIds, facilityId => calendarHasFacility(calendar, facilityId));

export const isDifferentWeek = (newDate, currentDate) =>
  Moment(newDate).week() !== Moment(currentDate).week();

export const isDifferentMonth = (newDate, currentDate) =>
  Moment(newDate).month() !== Moment(currentDate).month();

export const isDifferentDay = (newDate, currentDate) =>
  Moment(newDate).format('L') !== Moment(currentDate).format('L');

export const VIEW_PERIODS = {
  days: {
    key: 'days',
    comparator: isDifferentDay,
    startAndEndForPeriod: date => {
      const dateMoment = Moment(date);
      const start = dateMoment.clone().startOf('day');
      const end = dateMoment.clone().endOf('day');
      return {
        start,
        end,
        calendarStart: start,
        calendarEnd: end,
      };
    },
    title: date => Moment(date).format('MMMM Do'),
  },
  weeks: {
    key: 'weeks',
    comparator: isDifferentWeek,
    startAndEndForPeriod: date => {
      const dateMoment = Moment(date);
      const start = dateMoment
        .clone()
        .startOf('week')
        .startOf('day');
      const end = dateMoment
        .clone()
        .endOf('week')
        .endOf('day');
      return {
        start,
        end,
        calendarStart: start,
        calendarEnd: end,
      };
    },
    title: date => Moment(date).format('MMM Do, YYYY'),
  },
  months: {
    key: 'months',
    comparator: isDifferentMonth,
    startAndEndForPeriod: date => {
      const dateMoment = Moment(date);
      const start = dateMoment
        .clone()
        .startOf('month')
        .startOf('day');
      const end = dateMoment
        .clone()
        .endOf('month')
        .endOf('day');
      return {
        start,
        end,
        calendarStart: start.clone().startOf('week'),
        calendarEnd: end.clone().endOf('week'),
      };
    },
    title: date => Moment(date).format('MMM Do, YYYY'),
  },
};

export const getViewPeriodInfo = (view, date) => {
  const range = VIEW_PERIODS[view].startAndEndForPeriod(date);
  const days = [];
  const indexDay = range.calendarStart.clone().hour(12);
  while (indexDay.isSameOrBefore(range.calendarEnd)) {
    days.push(indexDay.clone());
    indexDay.add(1, 'days');
  }
  return {
    ...range,
    days,
  };
};

export const validDateForAllToView = (
  date,
  releaseDay = 20,
  currentDate = Moment(),
) => {
  const dateMoment = Moment(date);
  return (
    currentDate.isAfter(dateMoment) ||
    currentDate.month() === dateMoment.month() ||
    (currentDate.date() >= releaseDay &&
      currentDate
        .clone()
        .add(1, 'month')
        .endOf('month')
        .isAfter(dateMoment)) ||
    (releaseDay >= 29 &&
      currentDate
        .clone()
        .add(1, 'month')
        .month() === dateMoment.month() &&
      currentDate.date() >=
        currentDate
          .clone()
          .endOf('month')
          .date())
  );
};

export const residentCalendarLabel = firstName =>
  endsWith(firstName.toLowerCase(), 's')
    ? `${firstName}' Calendar`
    : `${firstName}'s Calendar`;
