import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import Button, { ButtonType } from 'stories/button';
import FlexContainer from '../flex-container';
import HeaderBar from '../header.bar';
import FooterBar from '../footer.bar';
import Divider from '../divider';

import { resolveComponentWithProps } from './router.utils';
import DeleteModal from './delete.modal';
import styles from './styles.scss';

const BASE_PATH = '/';

export default function FormRouter({
  objectName,
  objectShortName,
  additionalActions,
  form,
  isValid,
  onBack,
  onUpdateForm,
  onDelete,
  onSubmitDelete,
  onSave,
  onSubmitSave,
  className,
  children,
  deleteModal,
  initialRoute,
  routesDisabledFromTemporaryForm,
  createLabel,
  deletionWarning,
}) {
  // Allows subforms to update main form only if saved
  const [temporaryForm, setTemporaryForm] = useState(form);
  const [activeRoute, setActiveRoute] = useState(initialRoute);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const childrenAsArray = React.Children.toArray(children);
  const [{ match, component }] = childrenAsArray
    .map(child => ({
      component: child,
      match: matchPath(activeRoute, {
        path: child.props.route,
        exact: !!child.props.exact,
        strict: !!child.props.strict,
      }),
    }))
    .filter(child => child.match);

  const selectedComponentProps = component?.props || {};

  const isBasePath = activeRoute === BASE_PATH;
  const goBack = () =>
    setActiveRoute(selectedComponentProps.backRoute || BASE_PATH);
  const useTemporaryForm =
    !isBasePath && !includes(routesDisabledFromTemporaryForm, activeRoute);

  useEffect(() => {
    if (!useTemporaryForm) {
      setTemporaryForm(form);
    }
  }, [useTemporaryForm, form, setTemporaryForm]);

  const routerForm = useTemporaryForm ? temporaryForm : form;
  const isEdit = !!(routerForm || {})._id;
  const shortName = objectShortName || objectName;
  const onRouterUpdateForm = update => {
    if (useTemporaryForm) {
      setTemporaryForm({ ...temporaryForm, ...update });
    } else {
      onUpdateForm(update);
    }
  };

  let footer;
  if (!useTemporaryForm) {
    footer = (
      <>
        {onBack && (
          <Button
            className={styles.footerBtn}
            onClick={onBack}
            type={ButtonType.caution}
          >
            Cancel
          </Button>
        )}
        <Button
          className={styles.footerBtn}
          disabled={!isValid || isLoading}
          type={ButtonType.required}
          loading={isLoading}
          onClick={() => {
            const confirm = onSave ? onSave(form) : true;
            if (confirm) {
              setIsLoading(true);
              onSubmitSave().then(() => setIsLoading(false));
            }
          }}
          data-qa-save
        >
          {isEdit ? 'Update' : createLabel} {shortName}
        </Button>
      </>
    );
  } else {
    footer = (
      <>
        <Button
          className={styles.footerBtn}
          onClick={goBack}
          type={ButtonType.caution}
        >
          Cancel
        </Button>
        <Button
          data-qa-save
          className={styles.footerBtn}
          type={ButtonType.required}
          onClick={() => {
            onUpdateForm(temporaryForm);
            goBack();
          }}
        >
          {selectedComponentProps.getSubmitLabel
            ? selectedComponentProps.getSubmitLabel(temporaryForm)
            : 'Apply Changes'}
        </Button>
      </>
    );
  }

  return (
    <FlexContainer keepHeight className={className}>
      <HeaderBar
        onBack={isBasePath ? null : goBack}
        onClose={onBack}
        title={
          isBasePath || !component.props.title
            ? `${isEdit ? 'Edit' : createLabel} ${objectName}`
            : component.props.title
        }
      />

      <FlexContainer
        keepHeight
        alignItems="center"
        className={styles.scrollContainer}
      >
        <FlexContainer flexDirection="column" className={styles.formContainer}>
          {isEdit && isBasePath && (
            <>
              {React.Children.map(
                additionalActions.filter(child => !!child),
                child =>
                  React.cloneElement(child, {
                    className: classNames(
                      child.props.className,
                      styles.actionBtn,
                    ),
                  }),
              )}
              <Button
                className={styles.actionBtn}
                type={ButtonType.caution}
                onClick={() => {
                  const confirm = onDelete ? onDelete(form) : true;
                  if (confirm) {
                    setIsConfirmingDelete(true);
                  }
                }}
                data-qa-delete
              >
                Delete {shortName}
              </Button>
              <Divider />
            </>
          )}
          {resolveComponentWithProps(component, {
            onUpdateForm: onRouterUpdateForm,
            form: routerForm,
            setActiveRoute,
            activeRoute,
            match,
          })}
        </FlexContainer>
      </FlexContainer>

      <FooterBar justifyContent="flexEnd">{footer}</FooterBar>

      {resolveComponentWithProps(deleteModal || DeleteModal, {
        isOpen: isConfirmingDelete,
        objectName: shortName,
        onSubmitDelete: (...params) => {
          setIsConfirmingDelete(false);
          onSubmitDelete(...params);
        },
        onCancel: () => setIsConfirmingDelete(false),
        warning: deletionWarning,
        form,
      })}
    </FlexContainer>
  );
}

FormRouter.propTypes = {
  additionalActions: PropTypes.arrayOf(PropTypes.node),
  routesDisabledFromTemporaryForm: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  form: PropTypes.shape().isRequired,
  initialRoute: PropTypes.string,
  isValid: PropTypes.bool,
  objectName: PropTypes.string.isRequired,
  objectShortName: PropTypes.string,
  onBack: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onSubmitDelete: PropTypes.func.isRequired,
  onSubmitSave: PropTypes.func.isRequired,
  onUpdateForm: PropTypes.func.isRequired,
  createLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape(),
  ]).isRequired,
  deleteModal: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape(),
  ]),
  deletionWarning: PropTypes.string,
};

FormRouter.defaultProps = {
  routesDisabledFromTemporaryForm: [],
  additionalActions: [],
  className: undefined,
  deleteModal: undefined,
  initialRoute: BASE_PATH,
  isValid: true,
  objectShortName: '',
  onBack: undefined,
  onDelete: undefined,
  onSave: undefined,
  createLabel: 'Create',
  deletionWarning: '',
};
