import fetch from '../utils/fetch.helper';

export const postForgotPassword = (baseUrl, email) =>
  fetch('POST', `${baseUrl}/forgot-password`, undefined, { email });

export const postAcceptTerms = baseUrl =>
  fetch('POST', `${baseUrl}/rest/users/acceptTerms`);

export const postAcceptMasterServiceAgreementTerms = ({
  baseUrl,
  firstName,
  lastName,
  email,
  password,
}) =>
  fetch(
    'POST',
    `${baseUrl}/rest/users/acceptMasterServiceAgreementTerms`,
    undefined,
    { firstName, lastName, email, password },
  );

export const postAcceptIN2LTerms = baseUrl =>
  fetch('POST', `${baseUrl}/rest/users/acceptIN2LTerms`);

export const fetchClientData = baseUrl =>
  fetch('GET', `${baseUrl}/isAuthenticated`);

export const postSsoProfileForEmail = (baseUrl, { email, isMobileApp }) =>
  fetch('POST', `${baseUrl}/sso/profile`, undefined, { email, isMobileApp });
