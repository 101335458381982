import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { values } from 'lodash';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export const MeterType = {
  default: 'default',
  registration: 'registration',
};

export default function MeterBar({ progress, limit, type, who }) {
  let calculatedWidth = '100%';
  if (limit && progress > 0) {
    calculatedWidth = `${Math.round((progress / limit) * 100)}%`;
  }
  const isCompleted = progress / limit === 1;
  const hasError = !!progress && !limit;

  let labelText;
  if (type === MeterType.registration) {
    if (isCompleted) {
      labelText = <div>Registration Full</div>;
    } else if (limit && progress) {
      const left = limit - progress;
      labelText = (
        <div>
          {left} {who} Spots Left
        </div>
      );
    } else if (!limit && !hasError) {
      labelText = <div>Open To All</div>;
    } else {
      labelText = <div>else</div>;
    }
  }

  return (
    <FlexContainer alignItems="center">
      <div className={styles.labelText}>{labelText}</div>
      <div className={styles.progressBar}>
        <div
          className={classNames(styles.progressFill, {
            [styles.completed]: isCompleted,
            [styles.error]: hasError,
          })}
          style={{
            width: calculatedWidth,
          }}
        />
      </div>
    </FlexContainer>
  );
}

MeterBar.propTypes = {
  progress: PropTypes.number,
  limit: PropTypes.number,
  type: PropTypes.oneOf(values(MeterType)),
  who: PropTypes.string,
};

MeterBar.defaultProps = {
  progress: 0,
  limit: undefined,
  type: MeterType.default,
  who: undefined,
};
