import React from 'react';
import { Text, FlexContainer, HeaderBar } from 'web/components/primitives';

import styles from './styles.scss';

export default function UnsubscribeNo() {
  let title;
  if (window.clientConfig.error) {
    title = `We encountered an error accessing settings for ${window.clientConfig.email}.`;
  } else {
    title = `${window.clientConfig.email} will continue to receive these emails.`;
  }
  return (
    <FlexContainer keepHeight>
      <HeaderBar title="Subscribed" />
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        growToContainer
        className={styles.body}
      >
        <FlexContainer flexDirection="column">
          <Text bold size="1.6rem" className={styles.title}>
            {title}
          </Text>

          <Text mt={16}>
            You can adjust your{' '}
            <a
              className={styles.link}
              href="https://lifeloopapp.com/lifeloop/profile"
            >
              notification preferences in My Profile
            </a>{' '}
            any time you’d like.
          </Text>
          <Text mt={40}>
            Need help?{' '}
            <a
              className={styles.link}
              href="https://training.lifeloop.com/support"
            >
              Contact our support team.
            </a>
          </Text>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}
