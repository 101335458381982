import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
  ButtonType,
  FlexContainer,
  Text,
  AnimatedFlex,
} from 'web/components/primitives';
import { getBase64FromImageUrl } from 'web/utils/image.util';

import FormImage from 'assets/images/global/login-image.jpg';

import styles from './styles.scss';

let cachedImage;

export default function FormContainer({
  title,
  additionalLink,
  action,
  actionTitle,
  isValid,
  isLoading,
  onSubmit,
  children,
  hideSubmit,
  submitRowComponent,
  branding,
}) {
  const [loadedImg, setLoadedImg] = useState(cachedImage);
  const [brandingBackgroundImg, setBrandingBackgroundImg] = useState();
  const [brandingLogoImg, setBrandingLogoImg] = useState();

  useEffect(() => {
    if (!branding) {
      getBase64FromImageUrl(FormImage).then(img => {
        setLoadedImg(img);
        cachedImage = img;
      });
    } else {
      setBrandingBackgroundImg(branding.formImageUrl);
      setBrandingLogoImg(branding.logoUrl);
    }
  }, [setLoadedImg, branding]);

  let formActions = { action, method: 'post' };
  if (onSubmit) {
    formActions = {
      onSubmit: e => {
        e.preventDefault();
        onSubmit();
      },
    };
  }

  if (!loadedImg && !brandingBackgroundImg && !brandingLogoImg) {
    return null;
  }

  return (
    <FlexContainer className={styles.container}>
      <div className={styles.scrollable}>
        {branding && (
          <div className={styles.mobileBrandHeader}>
            <img
              src={brandingLogoImg}
              className={styles.mobileBrandedLogoImg}
              alt="Branded Logo Public"
            />
            <Text className={styles.brandedLogoSubtext} size={12}>
              powered by LifeLoop
            </Text>
          </div>
        )}
        <FlexContainer
          flexDirection="column"
          justifyContent="center"
          className={styles.formContainer}
        >
          <AnimatedFlex
            directionIn="left"
            flex={1}
            flexDirection="column"
            justifyContent="center"
          >
            <FlexContainer
              alignItems="center"
              justifyContent="spaceBetween"
              className={styles.formHeader}
            >
              <Text bold size="1.6rem">
                {title}
              </Text>
              {!!additionalLink && (
                <Link className={styles.link} to={additionalLink.link}>
                  {additionalLink.title}
                </Link>
              )}
            </FlexContainer>
            <form {...formActions} className={styles.form}>
              {children}
              <FlexContainer
                justifyContent="flexEnd"
                className={styles.submitBtn}
              >
                {submitRowComponent}
                <AnimatedFlex directionIn="left" isVisible={!hideSubmit}>
                  <Button
                    isSubmit
                    type={ButtonType.required}
                    disabled={!isValid || isLoading}
                    loading={isLoading}
                  >
                    {actionTitle}
                  </Button>
                </AnimatedFlex>
              </FlexContainer>
            </form>
          </AnimatedFlex>
        </FlexContainer>
      </div>
      <div className={styles.imageContainer}>
        {branding ? (
          <div className={styles.brandedImageContainer}>
            <div className={styles.brandedLogoContainer}>
              <img
                src={brandingLogoImg}
                className={styles.brandedLogoImg}
                alt="Branded Logo Public"
              />
              <Text className={styles.brandedLogoSubtext} size={12}>
                powered by LifeLoop
              </Text>
            </div>
            <img
              src={brandingBackgroundImg}
              alt="Branded background Public"
              className={styles.brandedBackgroundImage}
            />
          </div>
        ) : (
          <img src={loadedImg} alt="LifeLoop Public" className={styles.image} />
        )}
      </div>
    </FlexContainer>
  );
}

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  additionalLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  action: PropTypes.string,
  actionTitle: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  hideSubmit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  submitRowComponent: PropTypes.node,
};

FormContainer.defaultProps = {
  action: undefined,
  actionTitle: 'Submit',
  isLoading: false,
  onSubmit: null,
  hideSubmit: false,
  submitRowComponent: null,
};
