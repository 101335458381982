import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexContainer from '../flex-container';
import styles from './styles.scss';

export default function ListItem({
  children,
  onClick,
  className,
  hoverable,
  ...rest
}) {
  return (
    <FlexContainer
      flexDirection="row"
      alignItems="center"
      justifyContent="spaceBetween"
      noShrink
      {...rest}
      onClick={onClick}
      className={classNames(
        styles.item,
        styles.joined,
        { [styles.clickable]: !!onClick || hoverable },
        className,
      )}
    >
      {children}
    </FlexContainer>
  );
}

ListItem.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hoverable: PropTypes.bool,
};

ListItem.defaultProps = {
  onClick: undefined,
  className: undefined,
  hoverable: false,
};
