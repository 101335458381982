"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RED900 = exports.RED800 = exports.RED700 = exports.RED600 = exports.RED500 = exports.RED400 = exports.RED300 = exports.RED200 = exports.RED100 = exports.RED000 = exports.YELLOW000 = exports.YELLOW100 = exports.YELLOW200 = exports.YELLOW300 = exports.YELLOW400 = exports.YELLOW500 = exports.YELLOW600 = exports.YELLOW700 = exports.YELLOW800 = exports.YELLOW900 = exports.GREEN000 = exports.GREEN100 = exports.GREEN200 = exports.GREEN300 = exports.GREEN400 = exports.GREEN500 = exports.GREEN600 = exports.GREEN700 = exports.GREEN800 = exports.GREEN900 = exports.GRAY000 = exports.GRAY100 = exports.GRAY200 = exports.GRAY300 = exports.GRAY400 = exports.GRAY500 = exports.GRAY600 = exports.GRAY700 = exports.GRAY800 = exports.GRAY900 = exports.BLUE000 = exports.BLUE100 = exports.BLUE200 = exports.BLUE300 = exports.BLUE400 = exports.BLUE500 = exports.BLUE600 = exports.BLUE700 = exports.BLUE800 = exports.BLUE900 = void 0;
exports.BLUE900 = '#00273e';
exports.BLUE800 = '#003f6a';
exports.BLUE700 = '#005696';
exports.BLUE600 = '#016ec1';
exports.BLUE500 = '#0186ed';
exports.BLUE400 = '#309df1';
exports.BLUE300 = '#63b6f4';
exports.BLUE200 = '#8ecbf8';
exports.BLUE100 = '#bde2fb';
exports.BLUE000 = '#ecf9ff';
exports.GRAY900 = '#24292d';
exports.GRAY800 = '#2f363d';
exports.GRAY700 = '#434d56';
exports.GRAY600 = '#576069';
exports.GRAY500 = '#69737d';
exports.GRAY400 = '#949da5';
exports.GRAY300 = '#d1d5d9';
exports.GRAY200 = '#e0e4e8';
exports.GRAY100 = '#f6f8fa';
exports.GRAY000 = '#fafbfc';
exports.GREEN900 = '#33691e';
exports.GREEN800 = '#4e8627';
exports.GREEN700 = '#69a430';
exports.GREEN600 = '#83c139';
exports.GREEN500 = '#9ede42';
exports.GREEN400 = '#b0e463';
exports.GREEN300 = '#c2ea84';
exports.GREEN200 = '#d5efa5';
exports.GREEN100 = '#e7f5c6';
exports.GREEN000 = '#f9fbe7';
exports.YELLOW900 = '#b97000';
exports.YELLOW800 = '#c68b17';
exports.YELLOW700 = '#d3a517';
exports.YELLOW600 = '#e0c017';
exports.YELLOW500 = '#edda00';
exports.YELLOW400 = '#f1e12e';
exports.YELLOW300 = '#f4e85c';
exports.YELLOW200 = '#f8ef8b';
exports.YELLOW100 = '#fbf6b9';
exports.YELLOW000 = '#fffde7';
exports.RED000 = '#fff3f5';
exports.RED100 = '#fad2d0';
exports.RED200 = '#f5b0ab';
exports.RED300 = '#f18f86';
exports.RED400 = '#ec6d61';
exports.RED500 = '#e74c3c';
exports.RED600 = '#cf3f34';
exports.RED700 = '#b7322d';
exports.RED800 = '#9e2525';
exports.RED900 = '#86181d';
