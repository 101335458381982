import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';

import FlexContainer from '../flex-container';
import SectionHeader from '../section.header';
import styles from './styles.scss';

export default function DetailView({ header, details, subroutes, redirect }) {
  return (
    <FlexContainer keepHeight>
      {header}
      <FlexContainer keepHeight scroll className={styles.container}>
        <FlexContainer keepHeight className={styles.mainContent}>
          {details}
          {!!subroutes.length && (
            <>
              <SectionHeader>
                <FlexContainer
                  flex={1}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flexStart"
                >
                  {subroutes.map(({ label, id, path, extraProps }) => (
                    <NavLink
                      key={path}
                      to={path}
                      {...extraProps}
                      data-qa-subroute={id}
                      className={styles.tab}
                      activeClassName={styles['tab--active']}
                    >
                      {label}
                    </NavLink>
                  ))}
                </FlexContainer>
              </SectionHeader>
              <Switch>
                {subroutes.map(({ path, extraProps, component, render }) => (
                  <Route
                    key={path}
                    path={path}
                    {...extraProps}
                    component={component}
                    render={render}
                    className={styles.tab}
                    activeClassName={styles['tab--active']}
                  />
                ))}
                {!!redirect && (
                  <Redirect
                    to={redirect.replace(/\*/g, '\\*').replace(/:/g, '\\:')}
                  />
                )}
              </Switch>
            </>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}

DetailView.propTypes = {
  header: PropTypes.node.isRequired,
  details: PropTypes.node.isRequired,
  subroutes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]),
      render: PropTypes.func,
      extraProps: PropTypes.shape(),
    }),
  ),
  redirect: PropTypes.string,
};

DetailView.defaultProps = {
  subroutes: [],
  redirect: undefined,
};
