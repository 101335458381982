import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FlexContainer, Text } from 'web/components/primitives';

import styles from './styles.scss';

export default function BadgeFilterList({
  title,
  selected,
  items,
  onSelect,
  onUnselect,
}) {
  return (
    <FlexContainer
      flexDirection="column"
      alignItems="stretch"
      className={styles.container}
    >
      <Text className={styles.headerText}>{title}</Text>
      {items.map(({ value, label, count }) => {
        const isSelected = value === selected;
        return (
          <FlexContainer
            alignItems="center"
            justifyContent="spaceBetween"
            key={value}
          >
            <div
              className={classNames(styles.badge, {
                [styles['badge--selected']]: isSelected,
              })}
              onClick={() => {
                if (isSelected) {
                  return onUnselect(value);
                }
                return onSelect(value);
              }}
            >
              {label}
            </div>
            <Text
              className={classNames(styles.countText, {
                [styles['countText--selected']]: isSelected,
              })}
              mr={8}
            >
              {count}
            </Text>
          </FlexContainer>
        );
      })}
    </FlexContainer>
  );
}

BadgeFilterList.propTypes = {
  selected: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      count: PropTypes.number,
    }),
  ).isRequired,
};

BadgeFilterList.defaultProps = {
  selected: undefined,
};
