import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './style.scss';

export default function LoadingSpinner({ size, className, light, ...props }) {
  const borderWidth = Math.min(3, Math.ceil(size / 25));
  return (
    <div
      {...props}
      style={{
        width: size,
        height: size,
        borderWidth: `0 ${borderWidth} ${borderWidth} ${borderWidth}`,
      }}
      className={classNames(className, style.spinner, {
        [style.light]: light,
      })}
    />
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  light: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  size: 100,
  className: null,
  light: false,
};
