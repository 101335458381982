import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.scss';

const Slider = forwardRef(({ className, showFocus, ...rest }, ref) => (
  <input
    ref={ref}
    type="range"
    className={classNames(style.slider, className, {
      [style.showFocus]: showFocus,
    })}
    {...rest}
  />
));

export default Slider;

Slider.propTypes = {
  showFocus: PropTypes.bool,
  className: PropTypes.string,
};

Slider.defaultProps = {
  className: null,
  showFocus: false,
};
