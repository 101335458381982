import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from 'web/components/primitives/text';

import style from './style.scss';

export default function Label({
  children,
  className,
  isError,
  isDisabled,
  isRequired,
  noMargin,
  ...props
}) {
  return (
    <label // eslint-disable-line
      {...props}
      className={classNames(style.label, className, {
        [style.error]: isError,
        [style.disabled]: isDisabled,
        [style['label--noMargin']]: noMargin,
      })}
    >
      {children}
      {isRequired && (
        <Text inline isError size="0.8rem" ml={4}>
          (required)
        </Text>
      )}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  noMargin: PropTypes.bool,
};

Label.defaultProps = {
  className: undefined,
  isError: false,
  isDisabled: false,
  isRequired: false,
  noMargin: false,
};
