import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './style.scss';

export default function Divider({ onlyBottomMargin, className, ...rest }) {
  return (
    <div
      {...rest}
      className={classNames(style.divider, className, {
        [style.top]: !onlyBottomMargin,
      })}
    />
  );
}

Divider.propTypes = {
  onlyBottomMargin: PropTypes.bool,
  className: PropTypes.string,
};

Divider.defaultProps = {
  onlyBottomMargin: false,
  className: undefined,
};
