import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from 'shared/utils/effects';

import Input from './input';

export default function DebouncedInput({
  inputKey,
  value,
  debounce,
  onChange,
  ...rest
}) {
  const [localValue, setLocalValue] = useState(value);
  const debouncedValue = useDebounce(localValue, debounce);

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputKey, value]);

  useEffect(() => {
    if (localValue !== value) {
      onChange(localValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Input
      {...rest}
      value={localValue}
      onChange={e => setLocalValue(e.target.value)}
    />
  );
}

DebouncedInput.propTypes = {
  inputKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  debounce: PropTypes.number,
};

DebouncedInput.defaultProps = {
  inputKey: '',
  onChange: () => {},
  debounce: 300,
};
