import { useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isNumber } from 'lodash';

export const usePageClick = functionToRun => {
  const ref = useRef();
  ref.current = functionToRun;

  return useEffect(() => {
    const cb = e => ref.current(e);
    window.addEventListener('mousedown', cb);
    return () => {
      window.removeEventListener('mousedown', cb);
    };
  }, []);
};

export const useDocumentClickHandler = (
  handleClick,
  documentToWatch = document,
) => {
  const ref = useRef();
  ref.current = handleClick;

  useEffect(() => {
    const onClick = e => ref.current(e);
    documentToWatch.addEventListener('click', onClick, true);
    return () => {
      documentToWatch.removeEventListener('click', onClick, true);
    };
  }, [documentToWatch, handleClick]);
};

export const useMouseMoved = funcToReturn => {
  const ref = useRef();
  ref.current = funcToReturn;
  useEffect(() => {
    const cb = e => ref.current(e);
    window.addEventListener('mousemove', cb);
    return () => window.removeEventListener('mousemove', cb);
  }, []);
};

export const useInterval = (
  timeout,
  cb,
  additionalWatch = [],
  runOnMount = false,
) => {
  const savedCallback = useRef();
  savedCallback.current = cb;

  return useEffect(() => {
    const callback = () => {
      savedCallback.current();
    };
    if (runOnMount) {
      callback();
    }
    const id = setInterval(callback, timeout);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, additionalWatch);
};

export const useTimeout = (timeout, cb, additionalWatch = []) => {
  const ref = useRef();
  ref.current = cb;

  useEffect(() => {
    const timeoutCallback = () => ref.current();
    const id = setTimeout(timeoutCallback, timeout);
    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, additionalWatch);
};

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useClipboard = (refOrString = useRef()) => {
  const [isCoppied, setIsCoppied] = useState(false);
  const isRef = typeof refOrString !== 'string';
  const onClick = () => {
    try {
      if (navigator.clipboard) {
        const text = isRef
          ? refOrString.current.innerText || refOrString.current.value
          : refOrString;
        navigator.clipboard.writeText(text).then(() => setIsCoppied(true));
      } else if (isRef) {
        refOrString.current.select();
        const ok = document.execCommand('copy');
        if (ok) {
          setIsCoppied(true);
        }
      } else {
        const el = document.createElement('textarea');
        el.value = refOrString;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setIsCoppied(true);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Copy unsupported by browser');
    }
  };

  return [onClick, isCoppied, refOrString];
};

export const useForm = (initialState = {}) => {
  const [form, setForm] = useState(initialState);
  return [
    form,
    changes => {
      if (!changes) {
        setForm(initialState);
      } else {
        setForm({ ...form, ...changes });
      }
    },
  ];
};

export const useResize = (cb, additionalWatch = []) => {
  const ref = useRef();
  ref.current = cb;

  return useEffect(() => {
    const resizeCb = e => ref.current(e);
    window.addEventListener('resize', resizeCb);
    return () => window.removeEventListener('resize', resizeCb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, additionalWatch);
};

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useResize(
    debounce(
      () =>
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      50,
    ),
  );
  return dimensions;
};

export const useResponsiveBreakpoints = () => {
  const { width } = useDimensions();
  return {
    small: width < 980,
    medium: width > 980 < 1200,
    mediumOrSmaller: width < 1200,
    large: width > 1200,
  };
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (typeof value === 'undefined' || value === null) {
      return undefined;
    }
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SPACE_TO_PIXELS = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '32px',
};

const SPACE_TO_MOBILE = {
  0: 0,
  1: 4,
  2: 8,
  3: 16,
  4: 24,
  5: 32,
};

export const useSpacer = (props, isMobileApp = false) => {
  const { m, mt, mr, mb, ml, mx, my, p, px, py, pt, pr, pb, pl } = props;
  const SPACING = isMobileApp ? SPACE_TO_MOBILE : SPACE_TO_PIXELS;
  return useMemo(() => {
    const spacing = {};
    if (isNumber(m)) {
      spacing.margin = SPACING[m];
    }
    if (isNumber(mx)) {
      spacing.marginLeft = SPACING[mx];
      spacing.marginRight = SPACING[mx];
    }
    if (isNumber(my)) {
      spacing.marginTop = SPACING[my];
      spacing.marginBottom = SPACING[my];
    }
    if (isNumber(mt)) {
      spacing.marginTop = SPACING[mt];
    }
    if (isNumber(mr)) {
      spacing.marginRight = SPACING[mr];
    }
    if (isNumber(mb)) {
      spacing.marginBottom = SPACING[mb];
    }
    if (isNumber(ml)) {
      spacing.marginLeft = SPACING[ml];
    }
    if (isNumber(p)) {
      spacing.padding = SPACING[p];
    }
    if (isNumber(px)) {
      spacing.paddingLeft = SPACING[px];
      spacing.paddingRight = SPACING[px];
    }
    if (isNumber(py)) {
      spacing.paddingTop = SPACING[py];
      spacing.paddingBottom = SPACING[py];
    }
    if (isNumber(pt)) {
      spacing.paddingTop = SPACING[pt];
    }
    if (isNumber(pr)) {
      spacing.paddingRight = SPACING[pr];
    }
    if (isNumber(pb)) {
      spacing.paddingBottom = SPACING[pb];
    }
    if (isNumber(pl)) {
      spacing.paddingLeft = SPACING[pl];
    }
    return spacing;
  }, [m, mt, mr, mb, ml, p, pt, pr, pb, pl, mx, my, px, py, SPACING]);
};
