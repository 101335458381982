import React, { useEffect, useState } from 'react';

import {
  FormRow,
  Input,
  MessageAlert,
  MessageAlertType,
  AnimatedFlex,
  Button,
  FlexContainer,
} from 'web/components/primitives';
import FormContainer from 'web/components/public/form-container';
import { useDebounce } from 'shared/utils/effects';
import { postSsoProfileForEmail } from 'shared/api/session';
import { isValidEmail } from 'shared/utils/misc.util';
import { Link } from 'react-router-dom';
import styles from './styles.scss';

export default function Login() {
  const [form, setForm] = useState({
    email: window.clientConfig.email || '',
    password: '',
    rememberMe: false,
    ssoProfile: undefined,
    loading: false,
  });
  const debouncedEmail = useDebounce(form.email, 300);

  useEffect(() => {
    if (isValidEmail(debouncedEmail)) {
      setForm({ ...form, loading: true });
      postSsoProfileForEmail(window.clientConfig.baseUrl, {
        email: form.email,
        isMobileApp: false,
      })
        .then(ssoProfile => {
          setForm({ ...form, ssoProfile, loading: false });
        })
        .catch(() => {
          setForm({ ...form, ssoProfile: undefined, loading: false });
        });
    } else {
      setForm({ ...form, ssoProfile: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  return (
    <FormContainer
      hideSubmit={!form.email}
      title="Login"
      action="/login"
      actionTitle="Login"
      isValid={!!form.email && !!form.password}
      submitRowComponent={
        <FlexContainer flex={1} justifyContent="flexStart">
          <AnimatedFlex
            directionIn="left"
            isVisible={!!form.ssoProfile?.idpLoginUrl}
          >
            <a href={form.ssoProfile?.idpLoginUrl}>
              <Button>{form.ssoProfile?.btnLabel}</Button>
            </a>
          </AnimatedFlex>
        </FlexContainer>
      }
    >
      {!!window.clientConfig.error && (
        <MessageAlert type={MessageAlertType.error}>
          {window.clientConfig.error}
        </MessageAlert>
      )}
      <FormRow noMargin={!window.clientConfig.error} label="Email/Username">
        <Input
          autoComplete
          id="email"
          name="email"
          value={form.email}
          onChange={e => setForm({ ...form, email: e.target.value })}
        />
      </FormRow>
      <FormRow label="Password">
        <Input
          disabled={!form.email || form.ssoProfile?.disablePasswordLogin}
          autoComplete
          id="password"
          name="password"
          type="password"
          value={form.password}
          onChange={e => setForm({ ...form, password: e.target.value })}
        />
      </FormRow>
      <Link
        className={styles.link}
        style={{ marginTop: '1rem', display: 'block' }}
        to="/forgot-password"
      >
        Forgot Password?
      </Link>
      <FormRow>
        <Input
          value={form.rememberMe}
          type="checkbox"
          name="remember_me"
          description="Remember me"
          onChange={rememberMe => setForm({ ...form, rememberMe })}
        />
      </FormRow>
      <FormRow>
        <span className={styles.label}>Not a customer?</span>
        Visit{' '}
        <a className={styles.link} href="https://lifeloop.com">
          our website
        </a>{' '}
        to learn more.
      </FormRow>
    </FormContainer>
  );
}
