import React, { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { includes, find } from 'lodash';

import { Button, Icon, IconType } from 'stories';
import DropdownSelect from 'web/components/primitives/dropdown.select';
import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

const LABEL_LIMIT = 25;
// eslint-disable-next-line
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className={styles.dropdown}>
    {target}
    {isOpen ? (
      <Fragment>
        <div className={styles.menu}>{children}</div>
        <div className={styles.blanket} onClick={onClose} />
      </Fragment>
    ) : null}
  </div>
);

export default function MultiSelect({
  onChange,
  values,
  options,
  iconType,
  isDisabled,
  placeholder,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const validValues = useMemo(
    () =>
      values.filter(
        value =>
          value &&
          includes(
            options.map(option => option.value),
            value,
          ),
      ),
    [values, options],
  );

  const label = useMemo(() => {
    const selectedOption = find(options, option => option.value === values[0]);
    if (values.length === 1 && selectedOption) {
      const optionLabel = selectedOption.label;
      return optionLabel.length > LABEL_LIMIT
        ? `${optionLabel.substring(0, LABEL_LIMIT)}...`
        : optionLabel;
    }
    if (values.length > 1) {
      return `Selected (${values.length})`;
    }
    return placeholder;
  }, [placeholder, options, values]);

  useEffect(() => {
    if (validValues.length !== values.length) {
      onChange(validValues);
    }
  }, [validValues, values, onChange]);

  return (
    <Dropdown
      isOpen={menuIsOpen}
      onClose={() => setMenuIsOpen(!menuIsOpen)}
      target={
        <Button
          className={styles.dropdownBtn}
          symbolIcon={iconType}
          actionIcon={IconType.chevronDown}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          {label}
        </Button>
      }
    >
      <DropdownSelect
        isMulti
        autoFocus
        menuIsOpen
        isSearchable
        disableSelectAll
        controlShouldRenderValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isClearable={false}
        isDisabled={isDisabled}
        placeholder="Search..."
        onFocus={() => setMenuIsOpen(true)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        options={options}
        value={validValues}
        className={styles.select}
        onChange={(_, { action, option }) => {
          if (action === 'select-option') {
            onChange([...validValues, option.value]);
          } else if (action === 'deselect-option') {
            onChange(validValues.filter(value => value !== option.value));
          }
        }}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: () => {
            const allSelected = options.length === validValues.length;
            return (
              <Button
                mini
                className={styles.selectAllBtn}
                symbolIcon={
                  allSelected ? IconType.checkSquare : IconType.square
                }
                onMouseDown={e => {
                  e.stopPropagation();
                  onChange(
                    allSelected ? [] : options.map(option => option.value),
                  );
                }}
              >
                all
              </Button>
            );
          },
          Option: ({ children, innerProps, innerRef, getStyles, ...rest }) => (
            <FlexContainer
              alignItems="center"
              ref={innerRef}
              data-qa={rest.data.label}
              data-qa-selected={rest.isSelected}
              style={getStyles('option', rest)}
              className={styles.optionContainer}
              {...innerProps}
            >
              <Icon
                type={rest.isSelected ? IconType.checkSquare : IconType.square}
                size={16}
                className={styles.optionIcon}
              />
              <span className={styles.option}>{children}</span>
            </FlexContainer>
          ),
        }}
        styles={{
          control: provided => ({ ...provided, margin: 8 }),
          menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
        }}
      />
    </Dropdown>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  iconType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  isDisabled: false,
};
