const open = 'open';
const inProgress = 'in_progress';
const completed = 'completed';
const cancelled = 'cancelled';

const options = [
  { value: open, label: 'Open' },
  { value: inProgress, label: 'In Progress' },
  { value: completed, label: 'Completed' },
  { value: cancelled, label: 'Cancelled' },
];

module.exports = {
  open,
  inProgress,
  completed,
  cancelled,
  deprecated: {
    open: 'open',
    inProgress: 'in_progress',
    closed: 'closed',
    cancelled: 'canceled',
  },
  options,
  filters: [{ value: 'all', label: 'All' }, ...options],
};
