"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initDatadog = void 0;
require("@datadog/browser-rum/bundle/datadog-rum");
var initDatadog = function (window) {
    return window.DD_RUM.init({
        applicationId: '15fb13f9-cb5f-4759-8206-ee0682c2c9fa',
        clientToken: 'pub604c50596e36ca296ef3758019a94a80',
        site: 'us3.datadoghq.com',
        service: 'lifeloop-web',
        env: window.location.hostname || 'dev',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};
exports.initDatadog = initDatadog;
