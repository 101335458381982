import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import style from './style.scss';

const renderMenu = items => {
  if (!items.length) {
    return <div />;
  }
  return (
    <div style={{ position: 'relative' }}>
      <div data-qa-autocomplete className={style.menu}>
        {items}
      </div>
    </div>
  );
};
const renderItem = itemClassName => (item, isHighlighted) => (
  <div
    key={item.value}
    data-qa={item.label}
    className={classNames(style.item, itemClassName, {
      [style.isHighlighted]: isHighlighted,
    })}
  >
    {item.label}
  </div>
);
const renderInput = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options,
  placeholder,
  dataAttribute,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isError,
  ...rest
}) => (
  <input
    {...rest}
    placeholder={placeholder}
    data-qa={dataAttribute}
    className={style.input}
  />
);

renderInput.propTypes = {
  isError: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  dataAttribute: PropTypes.string.isRequired,
  options: PropTypes.shape({}), // eslint-disable-line react/require-default-props
};

export default function AutocompleteInput({
  filter,
  onChange,
  value,
  className,
  isError,
  options,
  onSelect,
  itemClassName,
  ...props
}) {
  const onAutocompleteChange = e => onChange(e.target.value);
  const onSelectChange = (val, item) =>
    onSelect ? onSelect(val, item) : onChange(val);
  const shouldItemRender = item =>
    !filter ||
    !value ||
    item.value
      .toString()
      .toLowerCase()
      .indexOf(value.toString().toLowerCase()) !== -1;
  return (
    <span
      className={classNames(style.inputWrapper, className, {
        [style.isError]: isError,
      })}
    >
      <Autocomplete
        wrapperStyle={{ width: '100%' }}
        value={value}
        getItemValue={item => item.value}
        items={options}
        onChange={onAutocompleteChange}
        onSelect={onSelectChange}
        renderMenu={renderMenu}
        renderItem={renderItem(itemClassName)}
        autoHighlight={false}
        shouldItemRender={shouldItemRender}
        renderInput={renderInput}
        inputProps={{ ...props }}
        fullWidth
      />
    </span>
  );
}

AutocompleteInput.propTypes = {
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  filter: PropTypes.bool,
  className: PropTypes.string,
  isError: PropTypes.bool,
  itemClassName: PropTypes.string,
};

AutocompleteInput.defaultProps = {
  onSelect: null,
  onBlur: () => null,
  filter: true,
  className: null,
  isError: false,
  itemClassName: '',
};
