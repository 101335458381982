import React from 'react';
import PropTypes from 'prop-types';

export default function PhoneNumber({ number }) {
  return (
    <a href={`tel:${number}`} onClick={e => e.stopPropagation()}>
      {number}
    </a>
  );
}

PhoneNumber.propTypes = {
  number: PropTypes.string.isRequired,
};
